import ErrorHandler from "../errorResponseHandler";
import Instance from "../service/AxiosInstance";

export const axios = Instance.apiInstance();

class AdminBadgeRequestAPI {
    static getBadgeRequests() {
        return axios
            .get(`/badgerequests/list`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateBadgeRequestAssesor(id, assessorId, assessorName) {
        return axios
            .post(`/badgerequests/${id}/updateAssessor`, {
                assessorId,
                assessorName,
            })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static updateBadgeRequestCertificationEntries(id, badgeRequest) {
        return axios
            .post(`/badgerequests/${id}/updatecertificationentries`, badgeRequest)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitBadgeRequestCertification(id) {
        return axios
            .post(`/badgerequests/${id}/submitcertification`)
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitBadgeRequestCertifications(badgeRequestIds) {
        return axios
            .post(`/badgerequests/submitcertifications`, { badgeRequestIds })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AdminBadgeRequestAPI;
