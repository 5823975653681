import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as authActions from "../../store/actions/authAction";
import * as configActions from "../../store/actions/configAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import { SkillsTxTable } from "../common/SkillsTxTable";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-my-people.css";
import { faCheckCircle, faExclamationCircle, faTimesCircle, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Col, Container, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class MyPeople extends React.Component {
    static displayName = MyPeople.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            contentLoading: true,
            myPeoples: [],
            columnDisplaySettings: [],
            alerts: [],
            generateActivityStatementProgress: false,
            showUpdateColumnSettingDialog: false,
            isUpdating: false,
        };

        this.initData = this.initData.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("manageEndorsementOperation", "HIDE");
        
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaManage-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.initData();
            });
        } else {
            this.initData();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columnDisplaySettings !== this.props.columnDisplaySettings) {
            this.setState({ columnDisplaySettings: this.props.columnDisplaySettings });
        }
    }

    initData() {
        this.setState({ loading: true });

        if (!this.props.currAssessment) {
            this.props.onConfig.getCurrAssessment().then(() => {});
        }

        Promise.all([this.props.onPerson.getPersonMyPeoples(), this.props.onPerson.getPersonLatestAccount(), this.props.onPerson.getColumnDisplaySettings("My People")])
            .then(() => {
                if (!this.props.loading && !this.props.columnDisplaySettingsLoading) {
                    const myPeoples = this.props.myPeoples.sort((a, b) => `${a.personB.firstName} ${a.personB.lastName}` > `${b.personB.firstName} ${b.personB.lastName}`);

                    this.setState({
                        myPeoples,
                        account: this.props.account,
                        columnDisplaySettings: this.props.columnDisplaySettings,
                    });
                }

                this.setState({ loading: this.props.loading || this.props.columnDisplaySettingsLoading });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    setManagedPeopleRelationship = (relationship) => {
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "", loadingPanelBody: "Please wait..." });
        Promise.resolve(this.props.onAuth.myPeopleSetManagedRelationship(relationship)).then(() => {
            Promise.resolve(this.props.onPerson.clearPersonAccountState()).then(() => {
                localStorage.setItem("showApprovedProfileOnly", "FALSE");
                this.props.history.push(`Manage?email=${relationship.personB.email}`);
            });
        });
    };

    handleGenerateActivityStatement = () => {
        this.setState({ generateActivityStatementProgress: true, showLoadingPanel: true, loadingPanelHeader: "Generate Activity Statement", loadingPanelBody: "Please wait..." });

        this.props.onPerson.generateActivityStatement().then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.generateAlert("success", "Activity statement sent");
                }
            }

            this.setState({ generateActivityStatementProgress: this.props.updating, showLoadingPanel: this.props.updating });
        });
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    handleShowColumnsSetting = () => {
        this.setState({ showUpdateColumnSettingDialog: true });
    };

    togleColumnsSettingDialog = () => {
        this.setState({ showUpdateColumnSettingDialog: false });
    };

    updateColumnDisplaySettingVisibility = (columnDisplaySetting) => {
        columnDisplaySetting.visible = !columnDisplaySetting.visible;
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "", loadingPanelBody: "Please wait..." });

        this.props.onPerson.updateColumnDisplaySetting(columnDisplaySetting).then(() => {
            this.setState({ showLoadingPanel: this.props.columnDisplaySettingsLoading });
        });
    };

    moveColumnDisplaySettingOrderUp = (columnDisplaySetting) => {
        columnDisplaySetting.visible = !columnDisplaySetting.visible;
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "", loadingPanelBody: "Please wait..." });

        this.props.onPerson.updateColumnDisplaySettingOrderUp(columnDisplaySetting.id).then(() => {
            this.setState({ showLoadingPanel: this.props.columnDisplaySettingsLoading });
        });
    };

    moveColumnDisplaySettingOrderDown = (columnDisplaySetting) => {
        columnDisplaySetting.visible = !columnDisplaySetting.visible;
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "", loadingPanelBody: "Please wait..." });

        this.props.onPerson.updateColumnDisplaySettingOrderDown(columnDisplaySetting.id).then(() => {
            this.setState({ showLoadingPanel: this.props.columnDisplaySettingsLoading });
        });
    };

    renderColumnsSettingDialog = () => {
        const { columnDisplaySettings } = this.state;
        const updateColumnDisplaySettingVisibility = this.updateColumnDisplaySettingVisibility;
        const moveColumnDisplaySettingOrderUp = this.moveColumnDisplaySettingOrderUp;
        const moveColumnDisplaySettingOrderDown = this.moveColumnDisplaySettingOrderDown;

        const columns = [
            {
                id: "visible",
                name: "Visible",
                selector: (row) => row.visible,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <ButtonGroup>
                                <Button color="success" outline={row.visible ? false : true} onClick={() => updateColumnDisplaySettingVisibility(row)} style={{ color: "transparent", fontSize: "10px" }}>
                                    A
                                </Button>
                                <Button color="danger" outline={!row.visible ? false : true} onClick={() => updateColumnDisplaySettingVisibility(row)} style={{ color: "transparent", fontSize: "10px" }}>
                                    A
                                </Button>
                            </ButtonGroup>
                        </div>
                    );
                },
            },
            {
                id: "caption",
                name: "Caption",
                selector: (row) => row.caption,
                sortable: false,
                width: "65%",
                center: false,
                format: function (row, index) {
                    switch (row.columnName) {
                        case "name":
                            return <ContentTranslator page="SfiaManage" name="Name" contentText="Name" />;
                            break;
                        case "relationshipType":
                            return <ContentTranslator page="SfiaManage" name="Relationship" contentText="Relationship" />;
                            break;
                        case "activityStatus":
                            return <ContentTranslator page="SfiaManage" name="ActivityStatus" contentText="Activity Status" />;
                            break;
                        case "isApprovalRequested":
                            return <ContentTranslator page="SfiaManage" name="ApprovedProfile" contentText="Approved Profile" />;
                            break;
                        case "currentJobTitle":
                            return <ContentTranslator page="SfiaAll" name="CurrentJob" contentText="Current" />;
                            break;
                        case "currentJobMatchPercentSelf":
                            return <ContentTranslator page="SfiaAll" name="CurrentSelfMatch" contentText="Current Self Match" />;
                            break;
                        case "currentJobMatchPercentValidated":
                            return <ContentTranslator page="SfiaAll" name="CurrentEndorsedMatch" contentText="Current Endorsed Match" />;
                            break;
                        case "futureJobTitle":
                            return <ContentTranslator page="SfiaAll" name="FutureJob" contentText="Future" />;
                            break;
                        case "futureJobMatchPercentSelf":
                            return <ContentTranslator page="SfiaAll" name="FutureSelfMatch" contentText="Future Self Match" />;
                            break;
                        case "futureJobMatchPercentValidated":
                            return <ContentTranslator page="SfiaAll" name="FutureEndorsedMatch" contentText="Future Endorsed Match" />;
                            break;
                        default:
                            return "";
                    }
                },
            },
            {
                id: "order",
                name: "Order",
                selector: (row) => row.order,
                sortable: false,
                width: "15%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <FontAwesomeIcon id={`iconArrowUp${row.id}`} icon={faArrowUp} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveColumnDisplaySettingOrderUp(row)} />
                            <UncontrolledTooltip target={`iconArrowUp${row.id}`} placement="bottom">
                                Move Up
                            </UncontrolledTooltip>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon id={`iconArrowDown${row.id}`} icon={faArrowDown} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => moveColumnDisplaySettingOrderDown(row)} />
                            <UncontrolledTooltip target={`iconArrowDown${row.id}`} placement="bottom">
                                Move Down
                            </UncontrolledTooltip>
                        </div>
                    );
                },
            },
        ];

        return (
            <Modal isOpen={this.state.showUpdateColumnSettingDialog} size="lg" toggle={!this.state.isUpdating ? this.togleColumnsSettingDialog : null}>
                <ModalHeader toggle={!this.state.isUpdating ? this.togleColumnsSettingDialog : null}>
                    <ContentTranslator page="SfiaManage" name="SfiaManageColumnsSettingHeader" contentText="Columns Setting" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <SkillsTxTable columns={columns} data={columnDisplaySettings ? columnDisplaySettings.sort((a, b) => a.order - b.order) : []} pagination={false} progressPending={this.state.loading}></SkillsTxTable>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleColumnsSettingDialog} disabled={this.state.isUpdating}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { myPeoples, columnDisplaySettings } = this.state;

        let showRequestApproval = false;
        if (this.props.currAssessment) {
            showRequestApproval = this.props.currAssessment.enableApprovalRequest;
        }

        let columns = [];
        const setManagedPeopleRelationship = this.setManagedPeopleRelationship;
        const totalWidth = 913;
        let hiddenWidth = 0;
        let visibleColumn = 0;
        let separateColumnWidth = 0;

        if (columnDisplaySettings) {
            columnDisplaySettings.map((columnDisplaySetting) => {
                if (columnDisplaySetting.visible == false) {
                    switch (columnDisplaySetting.columnName) {
                        case "name":
                            hiddenWidth += showRequestApproval ? 135 : 155;
                            break;
                        case "relationshipType":
                            hiddenWidth += showRequestApproval ? 95 : 150;
                            break;
                        case "activityStatus":
                            hiddenWidth += 70;
                            break;
                        case "isApprovalRequested":
                            hiddenWidth += 75;
                            break;
                        case "currentJobTitle":
                            hiddenWidth += 129;
                            break;
                        case "currentJobMatchPercentSelf":
                            hiddenWidth += 69;
                            break;
                        case "currentJobMatchPercentValidated":
                            hiddenWidth += 71;
                            break;
                        case "futureJobTitle":
                            hiddenWidth += 129;
                            break;
                        case "futureJobMatchPercentSelf":
                            hiddenWidth += 69;
                            break;
                        case "futureJobMatchPercentValidated":
                            hiddenWidth += 71;
                            break;
                        default:
                    }
                } else {
                    visibleColumn += 1;
                }
            });

            if (hiddenWidth > 0 && visibleColumn > 0) {
                separateColumnWidth = hiddenWidth / visibleColumn;
            }

            columnDisplaySettings
                .filter((it) => it.visible)
                .sort((a, b) => a.order - b.order)
                .map((columnDisplaySetting) => {
                    switch (columnDisplaySetting.columnName) {
                        case "name":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaManage" name="Name" contentText="Name" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${(showRequestApproval ? 135 : 155) + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return (
                                        <Link to="#" onClick={() => setManagedPeopleRelationship(row)}>
                                            {row[columnDisplaySetting.columnName]}
                                        </Link>
                                    );
                                },
                                wrap: true,
                            });
                            break;
                        case "relationshipType":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaManage" name="Relationship" contentText="I'm their" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${(showRequestApproval ? 95 : 150) + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return row[columnDisplaySetting.columnName];
                                },
                                wrap: true,
                            });
                            break;
                        case "activityStatus":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaManage" name="ActivityStatus" contentText="Activity Status" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${70 + separateColumnWidth}px`,
                                center: true,
                                format: function (row, index) {
                                    return (
                                        <React.Fragment>
                                            <div id={`div${row.personBId}NewActivityStatus`} style={{ width: "100%", textAlign: "center" }}>
                                                {row[columnDisplaySetting.columnName] == 1 ? (
                                                    <FontAwesomeIcon icon={faTimesCircle} size="lg" style={{ color: "#FF0000" }} />
                                                ) : row[columnDisplaySetting.columnName] == 2 ? (
                                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" style={{ color: "#ffc000" }} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: "#32c671" }} />
                                                )}
                                            </div>
                                            <UncontrolledTooltip target={`div${row.personBId}NewActivityStatus`} placement="bottom">
                                                {row[columnDisplaySetting.columnName] == 1 ? (
                                                    <ContentTranslator page="SfiaManage" name="SfiaManageActivityStatus1" contentText="No activity in past 90 days (could indicate lack of engagement)" />
                                                ) : row[columnDisplaySetting.columnName] == 2 ? (
                                                    <ContentTranslator page="SfiaManage" name="SfiaManageActivityStatus2" contentText="No activity in past 30 days and/or overdue action(s) (could indicate lack of support or over commitment)" />
                                                ) : (
                                                    <ContentTranslator page="SfiaManage" name="SfiaManageActivityStatus3" contentText="Updates made in past 30 days (indicating good engagement)" />
                                                )}
                                            </UncontrolledTooltip>
                                        </React.Fragment>
                                    );
                                },
                            });
                            break;
                        case "isApprovalRequested":
                            columns.push({
                                id: columnDisplaySetting.isApprovalRequested,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaManage" name="ApprovedProfile" contentText="Approved Profile" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.isApprovalRequested],
                                sortable: false,
                                width: `${75 + separateColumnWidth}px`,
                                center: true,
                                format: function (row, index) {
                                    return (
                                        <React.Fragment>
                                            <div id={`div${row.personBId}NewApprovalStatus`} style={{ width: "100%", textAlign: "center" }}>
                                                {row.isApprovalRequested || (row.latestProfileVersion && row.approvedVersion && row.latestProfileVersion == row.approvedVersion) ? (
                                                    row.latestProfileVersion && row.approvedVersion && row.latestProfileVersion == row.approvedVersion ? (
                                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: "#32c671" }} />
                                                    ) : (
                                                        <React.Fragment>
                                                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" style={{ color: "#ffc000" }} />
                                                            <br />
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "2-digit",
                                                                month: "short",
                                                                day: "numeric",
                                                            }).format(new Date(row.approvalRequestSentOn))}
                                                        </React.Fragment>
                                                    )
                                                ) : (
                                                    <FontAwesomeIcon icon={faTimesCircle} size="lg" style={{ color: "#FF0000" }} />
                                                )}
                                            </div>
                                            <UncontrolledTooltip target={`div${row.personBId}NewApprovalStatus`} placement="bottom">
                                                {row.isApprovalRequested || (row.latestProfileVersion && row.approvedVersion && row.latestProfileVersion == row.approvedVersion) ? (
                                                    row.latestProfileVersion && row.approvedVersion && row.latestProfileVersion == row.approvedVersion ? (
                                                        <ContentTranslator page="SfiaManage" name="SfiaManageApprovalStatus1" contentText="The skill profile has been approved" />
                                                    ) : (
                                                        <ContentTranslator page="SfiaManage" name="SfiaManageApprovalStatus2" contentText="Approval has been requested but the skill profile is not yet approved" />
                                                    )
                                                ) : (
                                                    <ContentTranslator page="SfiaManage" name="SfiaManageApprovalStatus3" contentText="The skill profile is not approved and approval not yet requested" />
                                                )}
                                            </UncontrolledTooltip>
                                        </React.Fragment>
                                    );
                                },
                                omit: !showRequestApproval,
                            });
                            break;
                        case "currentJobTitle":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="CurrentJob" contentText="Current" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${129 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row[columnDisplaySetting.columnName]}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        case "currentJobMatchPercentSelf":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="CurrentSelfMatch" contentText="Current Self Match" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${69 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row.currentJobMatchPercentSelf && row.currentJobMatchPercentSelf > 0 ? `${row.currentJobMatchPercentSelf}%` : ""}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        case "currentJobMatchPercentValidated":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="CurrentEndorsedMatch" contentText="Current Endorsed Match" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${71 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row.currentJobMatchPercentValidated && row.currentJobMatchPercentValidated > 0 ? `${row.currentJobMatchPercentValidated}%` : ""}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        case "futureJobTitle":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="FutureJob" contentText="Future" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${129 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row[columnDisplaySetting.columnName]}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        case "futureJobMatchPercentSelf":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="FutureSelfMatch" contentText="Future Self Match" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${69 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row.futureJobMatchPercentSelf && row.futureJobMatchPercentSelf > 0 ? `${row.futureJobMatchPercentSelf}%` : ""}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        case "futureJobMatchPercentValidated":
                            columns.push({
                                id: columnDisplaySetting.columnName,
                                name: (
                                    <div style={{ textAlign: "left", width: "100%" }}>
                                        <ContentTranslator page="SfiaAll" name="FutureEndorsedMatch" contentText="Future Endorsed Match" />
                                    </div>
                                ),
                                selector: (row) => row[columnDisplaySetting.columnName],
                                sortable: false,
                                width: `${71 + separateColumnWidth}px`,
                                format: function (row, index) {
                                    return <div style={{ width: "100%" }}>{row.futureJobMatchPercentValidated && row.futureJobMatchPercentValidated > 0 ? `${row.futureJobMatchPercentValidated}%` : ""}</div>;
                                },
                                wrap: true,
                            });
                            break;
                        default:
                    }
                });
        }

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                {this.renderColumnsSettingDialog()}
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <ContentWrapperLg>
                        <p className={"page-title"} style={headerStyle}>
                            {this.props.hasPeoples && this.props.mode == "MyPeoples" ? <ContentTranslator page="SfiaAll" name="Manage" contentText="My People" /> : <ContentTranslator page="SfiaAll" name="Endorsements" contentText="Endorsements" />}
                            {this.props.hasPeoples && this.props.mode == "MyPeoples" && (
                                <React.Fragment>
                                    <button id="btnGenerateActivityStatement" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "50px" }} onClick={this.handleGenerateActivityStatement}>
                                        <ContentTranslator page="SfiaManage" name="SfiaManageGenerateActivityStatementButton" contentText="Generate Activity Statement" />
                                    </button>
                                    <UncontrolledTooltip target={`btnGenerateActivityStatement`} placement="bottom" autohide={false}>
                                        <ContentTranslator page="SfiaManage" name="SfiaManageGenerateActivityStatementButtonTooltip" contentText="Generate and email yourself an Activity statement for your Team" />
                                    </UncontrolledTooltip>
                                    <button id="btnColumnDisplaySetting" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "5px" }} onClick={this.handleShowColumnsSetting}>
                                        <ContentTranslator page="SfiaManage" name="SfiaManageColumnsSetting" contentText="Columns Setting" />
                                    </button>
                                </React.Fragment>
                            )}
                        </p>
                        {this.props.hasPeoples && this.props.mode == "MyPeoples" && (
                            <React.Fragment>
                                <SkillsTxTable
                                    columns={columns}
                                    data={myPeoples.filter((it) => it.relationshipType == "Line Manager" || it.relationshipType == "Team Leader" || it.relationshipType == "Mentor")}
                                    pagination={false}
                                    progressPending={this.state.loading}
                                    cellsStyle={{
                                        style: {
                                            backgroundColor: "#e9ebf5",
                                            padding: "5px 10px",
                                            fontSize: "12px",
                                            border: "1px solid #dee2e6",
                                        },
                                    }}
                                    headCellsStyle={{
                                        style: {
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            border: "1px solid #dee2e6",
                                            whiteSpace: "wrap",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                            paddingRight: "8px",
                                            paddingLeft: "8px",
                                            backgroundColor: "#333f50",
                                            color: "#FFFFFF",
                                        },
                                    }}
                                ></SkillsTxTable>
                            </React.Fragment>
                        )}
                        {this.props.isEndorser && this.props.mode == "Endorsements" && (
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="tblHeaderStyle" style={{ width: "70%" }}>
                                            <ContentTranslator page="SfiaManage" name="Name" contentText="Name" />
                                        </th>
                                        <th className="tblHeaderStyle" style={{ width: "30%" }}>
                                            <ContentTranslator page="SfiaManage" name="EndorsedProfile" contentText="Endorsed Profile" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.loading && (
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: "center" }}>
                                                <Spinner size="lg" animation="border" role="status"></Spinner>
                                            </td>
                                        </tr>
                                    )}
                                    {myPeoples
                                        .filter((it) => it.relationshipType == "Endorser")
                                        .map((myPeople, index) => {
                                            let activityStatus = 1;
                                            const today = new Date();

                                            if (myPeople.personB.lastUpdatedOn) {
                                                // To calculate the time difference of two dates
                                                var differenceInTime = today.getTime() - new Date(myPeople.personB.lastUpdatedOn).getTime();

                                                // To calculate the no. of days between two dates
                                                var differenceInDays = differenceInTime / (1000 * 3600 * 24);

                                                if (differenceInDays > 365) {
                                                    activityStatus = 2;
                                                } else {
                                                    activityStatus = 3;
                                                }
                                            }

                                            return (
                                                <tr key={index}>
                                                    <td className="tblRowStyle">
                                                        <Link to="#" onClick={() => this.setManagedPeopleRelationship(myPeople)}>
                                                            {myPeople.personB.firstName} {myPeople.personB.lastName}
                                                        </Link>
                                                    </td>
                                                    <td className="tblRowStyle" style={{ textAlign: "center" }}>
                                                        <div id={`div${myPeople.personB.id}ActivityStatus`}>
                                                            {activityStatus == 1 ? (
                                                                <FontAwesomeIcon icon={faTimesCircle} size="lg" style={{ color: "#FF0000" }} />
                                                            ) : activityStatus == 2 ? (
                                                                <Fragment>
                                                                    <FontAwesomeIcon icon={faExclamationCircle} size="lg" style={{ color: "#ffc000" }} />
                                                                    <br />
                                                                    {`v${myPeople.personB.lastUpdatedVersion}-${new Intl.DateTimeFormat("en-GB", {
                                                                        year: "numeric",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }).format(new Date(myPeople.personB.lastUpdatedOn))}`}
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <FontAwesomeIcon icon={faCheckCircle} size="lg" style={{ color: "#32c671" }} />
                                                                    <br />
                                                                    {`v${myPeople.personB.lastUpdatedVersion}-${new Intl.DateTimeFormat("en-GB", {
                                                                        year: "numeric",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }).format(new Date(myPeople.personB.lastUpdatedOn))}`}
                                                                </Fragment>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        )}
                    </ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.person.loading,
        error: state.person.error,
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        myPeoples: state.person.myPeoples,
        account: state.person.account,
        updating: state.person.updating,

        columnDisplaySettingsLoading: state.person.columnDisplaySettingsLoading,
        columnDisplaySettings: state.person.columnDisplaySettings,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        currAssessment: state.config.currAssessment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPeople);
