import * as countryActions from "../../../store/actions/countryAction";
import * as jobActions from "../../../store/actions/jobAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import changeInput from "../../../utils/changeInput";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

class UserAddNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDialog: false,
            isUpdating: false,
            countries: [],
            jobs: [],
            optionYesOrNo: [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ],
            form: {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                usePreferredName: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                preferredName: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                position: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                team: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                country: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                location: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                other: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                skipSelfAssessmentSurvey: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurveyId: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurvey: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
        };
    }

    componentDidMount() {
        Promise.all([this.props.onJob.getJobs(), this.props.onCountry.getCountries()]).then(() => {
            let countries = [
                {
                    label: "Select Country",
                    value: "",
                },
            ];
            if (this.props.countries) {
                countries.push(...this.props.countries.map((country) => ({ label: country.name, value: country.name })));
            }

            let jobs = [
                {
                    label: "Select Role",
                    value: "",
                },
            ];
            if (this.props.jobs) {
                jobs.push(
                    ...this.props.jobs
                        .filter((it) => it.inActive != true && it.reference && !it.isSample)
                        .sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
                        .map((job) => ({ label: job.name, value: job.id }))
                );
            }
            this.setState({ countries, jobs });
            this.setState({ loading: this.props.countryLoading || this.props.jobsLoading });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
            form: {
                firstName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                lastName: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                email: {
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                usePreferredName: {
                    validation: {
                        required: false,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                preferredName: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                position: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                team: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                country: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                location: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                other: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                skipSelfAssessmentSurvey: {
                    validation: {
                        required: false,
                        isBool: true,
                    },
                    value: false,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurveyId: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                roleBasedSurvey: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
        });
    };

    handleChange = (event) => {
        const { jobs } = this.state;
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "roleBasedSurveyId") {
            const job = jobs.find((it) => it.value == value);
            if (job) {
                updateState = changeInput(updateState.form, "roleBasedSurvey", job.label);
            } else {
                updateState = changeInput(updateState.form, "roleBasedSurvey", "");
            }
        } else if (name == "skipSelfAssessmentSurvey" && value == "true") {
            updateState = changeInput(updateState.form, "roleBasedSurvey", null);
            updateState = changeInput(updateState.form, "roleBasedSurveyId", "");
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeCheckBox = (event) => {
        const { name, checked } = event.target;

        const updateState = changeInput(this.state.form, name, checked);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const person = {
            firstName: form.firstName.value,
            lastName: form.lastName.value,
            email: form.email.value,
            usePreferredName: form.usePreferredName.value,
            salutation: form.preferredName.value,
            position: form.position.value,
            team: form.team.value,
            country: form.country.value,
            countryCode: form.country.value,
            location: form.location.value,
            other: form.other.value,
            skipSelfAssessmentSurvey: form.skipSelfAssessmentSurvey.value,
            roleBasedSurveyId: form.roleBasedSurveyId.value && form.roleBasedSurveyId.value != "" ? form.roleBasedSurveyId.value : null,
            roleBasedSurvey: form.roleBasedSurvey.value && form.roleBasedSurvey.value != "" ? form.roleBasedSurvey.value : null,
        };

        this.setState({ isUpdating: true });

        this.props.onPerson.addPerson(person).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.personsError) {
                    this.props.generateAlert("success", "Add person success.");
                    this.togleDialogModal();
                } else {
                    this.props.generateAlert("danger", this.props.personsError.errData.Message);
                }
            }

            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    render() {
        const { form, countries, jobs, optionYesOrNo } = this.state;

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal} size="xl">
                    <ModalHeader toggle={this.togleDialogModal}>Add New Person</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Email</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="email" id="email" value={form.email.value} invalid={form.email.touched && !form.email.isValidFormat} onChange={this.handleChange} />
                                            {form.email.value != "" && !form.email.isValidFormat && <FormFeedback>Incorrect email format</FormFeedback>}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>First Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="firstName" id="inputFirstName" value={form.firstName.value} invalid={form.firstName.touched && !form.firstName.valid} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Last Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="lastName" id="lastName" value={form.lastName.value} invalid={form.lastName.touched && !form.lastName.valid} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={4}></Col>
                                        <Col sm={8} style={{ height: "38px", paddingTop: "10px" }}>
                                            <Input type="checkbox" name="usePreferredName" id="usePreferredName" checked={form.usePreferredName.value} invalid={form.usePreferredName.touched && !form.usePreferredName.valid} style={{ marginRight: "10px" }} onChange={this.handleChangeCheckBox} />
                                            <Label for="usePreferredName" check>
                                                Use Preferred Name
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Preferred</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="preferredName" id="preferredName" value={form.preferredName.value} invalid={form.preferredName.touched && !form.preferredName.valid} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    {this.props.config && this.props.config.allowSkipSelfAssessmentSurvey && (
                                        <FormGroup row>
                                            <Label sm={4}>Skip survey</Label>
                                            <Col sm={8}>
                                                <Input type="select" name="skipSelfAssessmentSurvey" id="inputSkipSelfAssessmentSurvey" value={form.skipSelfAssessmentSurvey.value} invalid={form.skipSelfAssessmentSurvey.touched && !form.skipSelfAssessmentSurvey.valid} onChange={this.handleChange}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Position</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="position" id="position" value={form.position.value} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Team</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="team" id="team" value={form.team.value} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Location</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="location" id="location" value={form.location.value} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Country</Label>
                                        <Col sm={8}>
                                            {this.state.loading ? (
                                                <Spinner type="grow" size="md" color="black" />
                                            ) : (
                                                <Input type="select" name="country" id="inputCountry" value={form.country.value} invalid={form.country.touched && !form.country.valid} onChange={this.handleChange}>
                                                    {countries.map((country, key) => (
                                                        <option key={key} value={country.value}>
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Other</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="other" id="other" value={form.other.value} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Role Based Survey</Label>
                                        <Col sm={8}>
                                            {this.state.loading ? (
                                                <Spinner type="grow" size="md" color="black" />
                                            ) : (
                                                <Input type="select" name="roleBasedSurveyId" id="inputRoleBasedSurvey" value={form.roleBasedSurveyId.value} invalid={form.roleBasedSurveyId.touched && !form.roleBasedSurveyId.valid} onChange={this.handleChange} disabled={form && (form.skipSelfAssessmentSurvey.value == "true" || form.skipSelfAssessmentSurvey.value == true)}>
                                                    {jobs.map((job, key) => (
                                                        <option key={key} value={job.value}>
                                                            {job.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!this.state.isFormValid || this.state.loading}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isImporting}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        personsError: state.adminPerson.error,
        isUpdating: state.adminPerson.isUpdating,
        countries: state.country.countries,
        countryLoading: state.country.loading,
        jobsLoading: state.job.loading,
        jobs: state.job.jobs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onCountry: bindActionCreators(countryActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddNew);
