import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";

export default class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const props = this.props;

        return (
            <thead className="tableHeader">
                {props.showTitle ? (
                    <tr>
                        <th className={"th-start"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.title}
                        </th>
                        <th style={{ backgroundColor: this.props.backgroundColor }}></th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "1" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "2" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "3" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "4" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "5" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "6" : ""}
                        </th>
                        <th colSpan={props.highlightGap ? 2 : 1} className={"th-fixwidth" + (props.showEdit || props.highlightGap ? "" : " th-end")} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.showTitle ? "7" : ""}
                        </th>
                        {props.showEdit ? (
                            props.highlightGap ? (
                                <React.Fragment>
                                    <th className={"th-fixwidth"} style={{ backgroundColor: this.props.backgroundColor }}></th>
                                    <th className={"th-fixwidth-50" + " th-end"} style={{ backgroundColor: this.props.backgroundColor }}></th>
                                </React.Fragment>
                            ) : (
                                <th className={"th-fixwidth" + " th-end"} style={{ backgroundColor: this.props.backgroundColor }}></th>
                            )
                        ) : (
                            props.highlightGap && <th className={"th-fixwidth-50" + " th-end"} style={{ backgroundColor: this.props.backgroundColor }}></th>
                        )}
                    </tr>
                ) : (
                    <tr>
                        <th id={"group" + props.title.replace(/ /g, "").replace(/&/g, "")} colSpan="9" className={"th-start th-end"} style={{ backgroundColor: this.props.backgroundColor }}>
                            {props.title}
                        </th>
                        <UncontrolledTooltip target={"group" + props.title.replace(/ /g, "").replace(/&/g, "")} placement="bottom">
                            {props.title + ` - Containing ${props.itemCount} sub-categories`}
                        </UncontrolledTooltip>
                    </tr>
                )}
            </thead>
        );
    }
}
