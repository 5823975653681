import changeInput from "../../../utils/changeInput";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import React, { Component } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from "reactstrap";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "28px",
    fontFamily: "IBMPlexSans-Bold",
};

const buttonStyle = {
    marginLeft: "10px",
};

const labelCaptionStyle = {
    fontWeight: "bold",
};

const textareaStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
};

const textareaStyleDisable = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    background: "#e9ecef",
};

const largeContainer = {
    width: "100%",
    maxWidth: "100%",
    fontSize: "14px",
};

const commonContainer = {
    fontSize: "14px",
};

class ManageJobDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            optionJobRole: [
                {
                    label: "Job",
                    value: "Job",
                },
                {
                    label: "Role",
                    value: "Role",
                },
            ],
            optionSeniority: [
                { label: "Select Seniority", value: "" },
                { label: "Senior or Executive Manager", value: "Senior or Executive Manager" },
                { label: "Senior Advisor", value: "Senior Advisor" },
                { label: "Line Manager", value: "Line Manager" },
                { label: "Team Leader", value: "Team Leader" },
                { label: "Senior Employee", value: "Senior Employee" },
                { label: "Experienced Employee", value: "Experienced Employee" },
                { label: "Junior Employee", value: "Junior Employee" },
                { label: "None of the above (please comment)", value: "None of the above (please comment)" },
                { label: "Not Applicable", value: "Not Applicable" },
            ],
            optionEmploymentStatus: [
                { label: "Select Status", value: "" },
                { label: "Permanent Full-time", value: "Permanent Full-time" },
                { label: "Permanent Part-time", value: "Permanent Part-time" },
                { label: "1-2 year contract", value: "1-2 year contract" },
                { label: "2-5 year contract", value: "2-5 year contract" },
                { label: "Consultant/Contractor", value: "Consultant/Contractor" },
                { label: "Casual", value: "Casual" },
                { label: "Other (Please comment)", value: "Other (Please comment)" },
                { label: "Not Applicable", value: "Not Applicable" },
            ],
            form: this.prepareFormState(),
            isFormValid: this.props.job && this.props.job.reference && this.props.job.reference != "" ? true : false,
            editMode: this.props.createNew ? true : false,
            isUpdating: false,
            createNewMode: this.props.createNew ? true : false,
            selectedCopyJobId: [],
            optionLanguages: [],
        };
    }

    componentDidMount() {
        this.prepareFormState();

        let optionLanguages = [];

        if (this.props.languages) {
            optionLanguages = [
                ...this.props.languages.map((language) => {
                    return {
                        value: language.name,
                        label: language.name,
                    };
                }),
            ];

            this.setState({ optionLanguages });
        }
    }

    prepareFormState = () => {
        return {
            jobRole: {
                validation: {
                    required: true,
                },
                value: this.props.job ? (this.props.job.isRole ? "Role" : "Job") : "Role",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            name: {
                validation: {
                    required: true,
                },
                value: this.props.job && this.props.job.name ? this.props.job.name : "",
                valid: this.props.job ? true : false,
                isValidFormat: this.props.job ? true : false,
                touched: false,
            },
            reference: {
                validation: {
                    required: true,
                },
                value: this.props.job && this.props.job.reference ? this.props.job.reference : "",
                valid: this.props.job && this.props.job.reference ? true : false,
                isValidFormat: this.props.job && this.props.job.reference ? true : false,
                touched: false,
            },
            reportingTo: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.reportingTo ? this.props.job.reportingTo : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            seniority: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.seniority ? this.props.job.seniority : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            employmentStatus: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.employmentStatus ? this.props.job.employmentStatus : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            department: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.department ? this.props.job.department : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            location: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.location ? this.props.job.location : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            fte: {
                validation: {
                    isFte: true,
                    required: true,
                },
                value: this.props.job && this.props.job.fte ? this.props.job.fte.toFixed(1) : "1.0",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            purpose: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.purpose ? this.props.job.purpose : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            keyAccountibilities: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.keyAccountibilities ? this.props.job.keyAccountibilities : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            keyProcesses: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.keyProcesses ? this.props.job.keyProcesses : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            educationRequirements: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.educationRequirements ? this.props.job.educationRequirements : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            qualificationRequirements: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.qualificationRequirements ? this.props.job.qualificationRequirements : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            otherRequirements: {
                validation: {
                    required: false,
                },
                value: this.props.job && this.props.job.otherRequirements ? this.props.job.otherRequirements : "",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            language: {
                validation: {
                    required: true,
                },
                value: this.props.job && this.props.job.language ? this.props.job.language : "English",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            languageId: {
                validation: {
                    required: true,
                },
                value: this.props.job && this.props.job.languageId ? this.props.job.languageId : this.props.languages && this.props.languages.find((it) => it.name == "English") ? this.props.languages.find((it) => it.name == "English").id : null,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.job !== this.props.job) {
            this.setState({ job: this.props.job });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }

        if (prevProps.updateSuccess !== this.props.updateSuccess) {
            if (this.props.updateSuccess) {
                this.setState({ editMode: false });
            }
        }
    }

    handleChange = (event) => {
        const persons = this.state.persons;
        const languages = this.props.languages;
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "language" && languages) {
            const language = languages.find((it) => it.name == value);
            updateState = changeInput(updateState.form, "languageId", language ? language.id : null);
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeShowEdit = () => {
        const editMode = this.state.editMode;

        if (editMode) {
            this.setState({ form: this.prepareFormState(), isFormValid: this.props.job && this.props.job.reference && this.props.job.reference != "" ? true : false });
        }

        this.setState({ editMode: !editMode });
    };

    handleSave = () => {
        const { form, selectedCopyJobId } = this.state;

        let job = this.state.job;

        if (job != null) {
            job.isRole = form.jobRole.value == "Role" ? true : false;
            job.reference = form.reference.value;

            job.reportingTo = form.reportingTo.value;
            job.seniority = form.seniority.value;
            job.employmentStatus = form.employmentStatus.value;
            job.department = form.department.value;
            job.location = form.location.value;
            job.fte = form.fte.value;
            job.purpose = form.purpose.value;
            job.keyAccountibilities = form.keyAccountibilities.value;
            job.keyProcesses = form.keyProcesses.value;
            job.educationRequirements = form.educationRequirements.value;
            job.qualificationRequirements = form.qualificationRequirements.value;
            job.otherRequirements = form.otherRequirements.value;
            job.language = form.language.value;
            job.languageId = form.languageId.value;

            this.props.handleSaveJobDetailUpdate(job);
        } else {
            job = {
                name: form.name.value,
                description: form.name.value,
                isRole: form.jobRole.value == "Role" ? true : false,
                reference: form.reference.value,
                reportingTo: form.reportingTo.value,
                seniority: form.seniority.value,
                employmentStatus: form.employmentStatus.value,
                department: form.department.value,
                location: form.location.value,
                fte: form.fte.value,
                purpose: form.purpose.value,
                keyAccountibilities: form.keyAccountibilities.value,
                keyProcesses: form.keyProcesses.value,
                educationRequirements: form.educationRequirements.value,
                qualificationRequirements: form.qualificationRequirements.value,
                otherRequirements: form.otherRequirements.value,
                isHidden: true,
                isCandidateJob: false,
                inActive: false,
                copiedJobIds: selectedCopyJobId != null && selectedCopyJobId.length > 0 ? [...selectedCopyJobId] : [],
                language: form.language.value,
                languageId: form.languageId.value,
            };

            this.props.handleSaveNewJobDetail(job);
        }
    };

    handleCopySkillCheckedChange = (jobId) => {
        const { selectedCopyJobId } = this.state;

        if (selectedCopyJobId.includes(jobId)) {
            const index = selectedCopyJobId.indexOf(jobId);
            selectedCopyJobId.splice(index, 1);
        } else {
            selectedCopyJobId.push(jobId);
        }

        this.setState({ selectedCopyJobId });
    };

    render() {
        const pathName = window.location.pathname;
        const isAdmin = pathName.includes("/admin");
        const { form, editMode, createNewMode, optionJobRole, optionSeniority, optionEmploymentStatus, selectedCopyJobId, optionLanguages } = this.state;

        return (
            <React.Fragment>
                <ContentWrapperLg style={isAdmin ? largeContainer : commonContainer} className={isAdmin && "contentWrapperLgAdmin-Style"}>
                    {createNewMode && (
                        <Row style={{ paddingBottom: "10px" }}>
                            <Col md={12}>
                                <p className="mb-0" style={headerStyle}>
                                    Create Requirement
                                </p>
                            </Col>
                        </Row>
                    )}
                    <Row style={{ paddingBottom: "10px" }}>
                        <Col md={3}>
                            <p
                                className="mb-0"
                                style={{
                                    color: "#2B2B2B",
                                    fontSize: "16px",
                                    fontFamily: "IBMPlexSans-Bold",
                                    paddingBottom: "10px",
                                }}
                            >
                                Requirement Detail
                            </p>
                        </Col>
                        <Col md={9}>
                            {!editMode && this.props.allowEdit && (
                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit}>
                                    Edit
                                </button>
                            )}
                            {editMode && !createNewMode && (
                                <React.Fragment>
                                    <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleSave} disabled={!this.state.isFormValid || this.state.isUpdating || (form && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}>
                                        Save
                                    </button>
                                    <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit} disabled={this.state.isUpdating}>
                                        Cancel
                                    </button>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                {!editMode ? (
                                    <React.Fragment>
                                        {/* <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Job/Role
                                            </Label>
                                            <Label sm={9}>{form.jobRole.value}</Label>
                                        </Row> */}
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Unique reference
                                            </Label>
                                            <Label sm={9}>{form.reference.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Reporting to
                                            </Label>
                                            <Label sm={9}>{form.reportingTo.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Seniority
                                            </Label>
                                            <Label sm={9}>{form.seniority.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Employment Status
                                            </Label>
                                            <Label sm={9}>{form.employmentStatus.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Department
                                            </Label>
                                            <Label sm={9}>{form.department.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Location
                                            </Label>
                                            <Label sm={9}>{form.location.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                FTE Count
                                            </Label>
                                            <Label sm={9}>{form.fte.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Language
                                            </Label>
                                            <Label sm={9}>{form.language.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Purpose
                                            </Label>
                                            <Label sm={9}>{form.purpose.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Key Accountibilities
                                            </Label>
                                            <Label sm={9}>{form.keyAccountibilities.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Key Processes
                                            </Label>
                                            <Label sm={9}>{form.keyProcesses.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Education Requirements
                                            </Label>
                                            <Label sm={9}>{form.educationRequirements.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Qualification Requirements
                                            </Label>
                                            <Label sm={9}>{form.qualificationRequirements.value}</Label>
                                        </Row>
                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Other Requirements
                                            </Label>
                                            <Label sm={9}>{form.otherRequirements.value}</Label>
                                        </Row>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {this.state.createNewMode && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    Job/Role
                                                </Label>
                                                <Col sm={9}>
                                                    <Input type="select" name="jobRole" id="jobRole" value={form.jobRole.value} invalid={form.jobRole.touched && !form.jobRole.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                        {optionJobRole.map((it, key) => (
                                                            <option key={key} value={it.value}>
                                                                {it.label}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {this.state.createNewMode && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    Title
                                                </Label>
                                                <Col sm={9}>
                                                    <Input id="name" name="name" type="text" value={form.name.value ? form.name.value : ""} invalid={form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating} placeholder="Title" />
                                                </Col>
                                            </FormGroup>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Unique reference
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    id="reference"
                                                    name="reference"
                                                    type="text"
                                                    value={form.reference.value ? form.reference.value : ""}
                                                    invalid={form.reference.touched && !form.reference.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    placeholder="Unique reference"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Reporting to
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    id="reportingTo"
                                                    name="reportingTo"
                                                    type="text"
                                                    value={form.reportingTo.value ? form.reportingTo.value : ""}
                                                    invalid={form.reportingTo.touched && !form.reportingTo.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    placeholder="Reporting to"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Seniority
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="select" name="seniority" id="seniority" value={form.seniority.value} invalid={form.seniority.touched && !form.seniority.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                    {optionSeniority.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Employment Status
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="select" name="employmentStatus" id="employmentStatus" value={form.employmentStatus.value} invalid={form.employmentStatus.touched && !form.employmentStatus.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                    {optionEmploymentStatus.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Department
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    id="department"
                                                    name="department"
                                                    type="text"
                                                    value={form.department.value ? form.department.value : ""}
                                                    invalid={form.department.touched && !form.department.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    placeholder="Department"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Location
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    id="location"
                                                    name="location"
                                                    type="text"
                                                    value={form.location.value ? form.location.value : ""}
                                                    invalid={form.location.touched && !form.location.valid}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    placeholder="Location"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                FTE Count
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    id="fte"
                                                    name="fte"
                                                    type="text"
                                                    value={form.fte.value ? form.fte.value : ""}
                                                    invalid={(form.fte.touched && !(form.fte.valid && form.fte.isValidFormat)) || (form.fte.touched && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    placeholder="FTE Count"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Default language
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="select" name="language" id="language" value={form.language.value} invalid={form.language.touched && !form.language.valid} onChange={this.handleChange} disabled={!editMode || this.state.isUpdating}>
                                                    {optionLanguages.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Purpose
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.purpose.value ? form.purpose.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="purpose"
                                                    id="purpose"
                                                    placeholder="Purpose"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Key Accountibilities
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.keyAccountibilities.value ? form.keyAccountibilities.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="keyAccountibilities"
                                                    id="keyAccountibilities"
                                                    placeholder="Key Accountibilities"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Key Processes
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.keyProcesses.value ? form.keyProcesses.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="keyProcesses"
                                                    id="keyProcesses"
                                                    placeholder="Key Processes"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Education Requirements
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.educationRequirements.value ? form.educationRequirements.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="educationRequirements"
                                                    id="educationRequirements"
                                                    placeholder="Education Requirements"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Qualification Requirements
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.qualificationRequirements.value ? form.qualificationRequirements.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="qualificationRequirements"
                                                    id="qualificationRequirements"
                                                    placeholder="Qualification Requirements"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={3} style={labelCaptionStyle}>
                                                Other Requirements
                                            </Label>
                                            <Col sm={9}>
                                                <ReactTextareaAutosize
                                                    minRows={5}
                                                    style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                    aria-label="maximum height"
                                                    value={form.otherRequirements.value ? form.otherRequirements.value : ""}
                                                    onChange={this.handleChange}
                                                    disabled={!editMode || this.state.isUpdating}
                                                    name="otherRequirements"
                                                    id="otherRequirements"
                                                    placeholder="Other Requirements"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    {createNewMode && (
                        <React.Fragment>
                            <br />
                            <Row style={{ borderTop: "1px solid #ced4da" }}>
                                <Col md={12}>&nbsp;</Col>
                            </Row>
                            <Row style={{ paddingBottom: "10px" }}>
                                <Col md={12}>
                                    <p
                                        className="mb-0"
                                        style={{
                                            color: "#2B2B2B",
                                            fontSize: "16px",
                                            fontFamily: "IBMPlexSans-Bold",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        Copy skills from
                                    </p>
                                </Col>
                            </Row>
                            <Form>
                                <Row>
                                    {this.props.jobs
                                        .sort((a, b) => (`${a.name}` > `${b.name}` ? 1 : -1))
                                        .map((job, index) => {
                                            return (
                                                <Col md={6} key={index}>
                                                    <FormGroup>
                                                        <CustomInput
                                                            key={index}
                                                            type="checkbox"
                                                            name={`chkCopySkillJob${job.id}`}
                                                            id={`chkCopySkillJob${job.id}`}
                                                            checked={selectedCopyJobId.includes(job.id) ? true : false}
                                                            label={job.name}
                                                            onChange={() => this.handleCopySkillCheckedChange(job.id)}
                                                            disabled={!editMode || this.state.isUpdating}
                                                            inline
                                                            className="copy-skill-checkbox"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </Form>
                            <Row style={{ borderTop: "1px solid #ced4da" }}>
                                <Col md={12}>&nbsp;</Col>
                            </Row>
                            <Row style={{ paddingBottom: "10px", textAlign: "right" }}>
                                <Col md={12}>
                                    <button className="btn btn-light btn-primary" style={buttonStyle} onClick={this.handleSave} disabled={this.state.isUpdating || !this.state.isFormValid || (form && form.fte && (form.fte.value == "0" || form.fte.value == "0.0" || form.fte.value == 0))}>
                                        Create Requirement
                                    </button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </ContentWrapperLg>
            </React.Fragment>
        );
    }
}

export default ManageJobDetail;
