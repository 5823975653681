import { contentTranslator } from "../../../utils/translatorUtils";
import ContentTranslator from "../../translator/ContentTranslator";
import { faCheckCircle, faComment, faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, UncontrolledTooltip } from "reactstrap";

const tableBodyStyle = {
    fontSize: 11,
    fontFamily: "IBMPlexSans",
};

const tdEditStyle = {
    padding: "2px 10px",
};

const lorOrders = [
    { lor: "Autonomy", order: 1 },
    { lor: "Influence", order: 2 },
    { lor: "Complexity", order: 3 },
    { lor: "Business Skills", order: 4 },
    { lor: "Knowledge", order: 5 },
    { lor: "Responsibility and Autonomy", order: 1 },
    { lor: "Complexity and Scope", order: 2 },
    { lor: "Skills", order: 3 },
];

class ManageJobLorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editLorProfile: false,
            editedLor: null,
            editedLorProfile: null,
            lorProfiles: this.props.lorProfiles,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lorProfiles !== this.props.lorProfiles) {
            this.setState({ lorProfiles: this.props.lorProfiles });
        }
    }

    renderLorProfileList = (category, categoryColor, lorColor) => {
        const { lors } = this.props;
        const { lorProfiles } = this.state;

        if (lors) {
            lors.forEach((lor) => {
                const lorOrder = lorOrders.find((it) => it.lor == lor.name);
                if (lorOrder) {
                    lor.order = lorOrder.order;
                } else {
                    lor.order = 999;
                }
            });
        }

        const rows = lors
            .filter((it) => (it.category ? it.category : "Generic Attributes") == (category ? category : "Generic Attributes"))
            .sort((a, b) => (`${a.order}${a.name}` > `${b.order}${b.name}` ? 1 : -1))
            .map((lor, lorindex) => {
                const row = lorProfiles.find((x) => x.lor === lor.name);
                const levels = [1, 2, 3, 4, 5, 6, 7];

                if (row) {
                    const numericVal = row.editedNumericVal ? row.editedNumericVal : row.numericVal;
                    return (
                        <tr key={lorindex}>
                            <td id={"Item" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/&/g, ""))} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle" }}>
                                <ContentTranslator page="Lors" name={row.lor} contentText={row.lor} />
                            </td>
                            <UncontrolledTooltip target={"Item" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/&/g, ""))} placement="bottom">
                                {lor && <ContentTranslator page="Lors" name={`${row.lor} Description`} contentText={lor.description} />}
                            </UncontrolledTooltip>
                            <td className={"pt-1 pb-1 td-item"}></td>
                            {levels.map((level, levelindex) => {
                                const lorLevel = lor.levels.find((x) => x.levelNumber == level);
                                return (
                                    <React.Fragment key={levelindex}>
                                        <td
                                            id={`lv${level}${lorindex}${levelindex}`}
                                            className={"pt-1 pb-1 td-value td-fixwidth"}
                                            style={{
                                                borderRight: "1px solid #FFFFFF",
                                                backgroundColor: numericVal === level ? categoryColor : lorColor,
                                            }}
                                        >
                                            {numericVal === level && row.comment && <FontAwesomeIcon icon={faComment} style={{ color: "#ffa500" }} />}
                                        </td>
                                        <UncontrolledTooltip target={`lv${level}${lorindex}${levelindex}`} placement="bottom">
                                            {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                        </UncontrolledTooltip>
                                    </React.Fragment>
                                );
                            })}
                            {this.props.showEditSkill && (
                                <td align="center" id={"lvEdit" + (row.id ? row.id : row.lor.replace(/ /g, "").replace(/&/g, ""))} style={tdEditStyle}>
                                    <button className={`btn btn-light ${row.edited ? " btn-warning-edit" : " btnEdit-Style"}`} onClick={() => this.openEditLorProfile(lor, { id: row.id, value: numericVal })}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            )}
                        </tr>
                    );
                } else {
                    return (
                        <tr key={lorindex}>
                            <td id={"Item" + lor.name.replace(/ /g, "").replace(/&/g, "").replace(/\//g, "")} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle" }}>
                                <ContentTranslator page="Lors" name={lor.name} contentText={lor.name} />
                            </td>
                            <td className={"pt-1 pb-1 td-item"}></td>
                            {levels.map((level, levelindex) => {
                                return (
                                    <React.Fragment key={levelindex}>
                                        <td id={`lv${level}${lorindex}${levelindex}`} className={"pt-1 pb-1 td-value td-fixwidth"} style={{ backgroundColor: lorColor }}></td>
                                    </React.Fragment>
                                );
                            })}
                            {this.props.showEditSkill && (
                                <td align="center" id={"lvEdit" + lor.name} style={tdEditStyle}>
                                    <button className="btn btn-light btnEdit-Style" onClick={() => this.openEditLorProfile(lor, null)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            )}
                        </tr>
                    );
                }
            });

        return <tbody style={tableBodyStyle}>{rows}</tbody>;
    };

    openEditLorProfile = (editedLor, editedLorProfile) => {
        this.setState({ editLorProfile: true, editedLor, editedLorProfile });
    };

    togleEditLorProfile = () => {
        const { editLorProfile } = this.state;
        this.setState({ editLorProfile: !editLorProfile });
    };

    renderModalEditProfile() {
        const { editedLor, editedLorProfile } = this.state;

        let lorProfileElement = null;

        if (editedLorProfile) {
            lorProfileElement = {
                id: editedLorProfile.id,
                value: editedLorProfile.value,
            };
        }
        return (
            <Modal isOpen={this.state.editLorProfile} toggle={this.togleEditLorProfile}>
                <ModalHeader toggle={this.togleEditLorProfile}>Edit Responsibility</ModalHeader>
                <ModalBody>
                    <Form>
                        <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor && editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            {editedLor && <ContentTranslator page="Lors" name={editedLor.name} contentText={editedLor.name} />}
                                        </td>
                                    </tr>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor && editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            {editedLor && <ContentTranslator page="Lors" name={`${editedLor.name} Description`} contentText={editedLor.description} />}
                                        </td>
                                    </tr>
                                    {editedLor &&
                                        editedLor.levels &&
                                        editedLor.levels.map((level, index) => {
                                            return (
                                                <tr key={index} valign="top">
                                                    <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                        {level.levelNumber}
                                                    </td>
                                                    <td style={{ width: "87%" }} className="td-edit-profile-skill">
                                                        <ContentTranslator page="LorLevels" name={`${editedLor.name} ${level.levelName} Description`} contentText={level.description} />
                                                    </td>
                                                    <td style={{ width: "8%" }} className="td-edit-profile-skill">
                                                        <FormGroup>
                                                            <Input
                                                                type="radio"
                                                                value={level.levelNumber}
                                                                name={`rad${editedLor.id}`}
                                                                checked={lorProfileElement ? (lorProfileElement.value === level.levelNumber ? true : false) : false}
                                                                onChange={() => null}
                                                                onClick={() => this.handleRadioClick(level.levelNumber)}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleEditLorProfile}>
                        Close
                    </Button>{" "}
                    <Button color="primary" onClick={this.handleSaveEditProfileClick}>
                        Save changes
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleRadioClick = (value) => {
        let editedLorProfile = this.state.editedLorProfile;

        if (editedLorProfile) {
            editedLorProfile.value = value;
        } else {
            editedLorProfile = {
                value,
            };
        }

        this.setState({ editedLorProfile });
    };

    handleSaveEditProfileClick = () => {
        const { lors } = this.props;
        const { lorProfiles, editedLor, editedLorProfile } = this.state;
        const level = lors.find((it) => it.name == editedLor.name).levels.find((level) => level.levelNumber == editedLorProfile.value);

        let lorProfile = null;
        if (lorProfiles) {
            lorProfile = lorProfiles.find((it) => (editedLorProfile.id && it.id == editedLorProfile.id) || (!it.id && it.lor === editedLor.name));

            if (lorProfile) {
                if ((lorProfile.editedNumericVal && lorProfile.editedNumericVal != editedLorProfile.value) || (!lorProfile.editedNumericVal && lorProfile.numericVal != editedLorProfile.value)) {
                    lorProfile.editedLevel = level.levelNumber;
                    lorProfile.editedNumericVal = editedLorProfile.value;
                    lorProfile.editedStringVal = level && `${level.levelNumber}- ${level.levelName}`;
                    lorProfile.editedLabelVal = level && level.description;
                    lorProfile.edited = true;

                    lorProfile.source = "lorProfiles";

                    this.props.handleEditProfileSkill(lorProfile);
                    this.setState({ lorProfiles, editLorProfile: false });
                } else {
                    this.setState({ editLorProfile: false });
                }
            }
        }

        if (!lorProfile) {
            lorProfile = {
                lor: editedLor.name,
                lorCode: editedLor.lorCode,
                level: level.levelNumber,
                numericVal: editedLorProfile.value,
                stringVal: level && `${level.levelNumber}- ${level.levelName}`,
                labelVal: level && level.description,
                editedLevel: level.levelNumber,
                editedNumericVal: editedLorProfile.value,
                editedStringVal: level && `${level.levelNumber}- ${level.levelName}`,
                editedLabelVal: level && level.description,
                edited: true,
                source: "lorProfiles",
            };

            this.props.handleEditProfileSkill(lorProfile);
            this.setState({ lorProfiles: [...lorProfiles, lorProfile], editLorProfile: false });
        }
    };

    render() {
        const props = this.props;
        const { lors } = this.props;

        const lorCategories = [];

        if (lors && lors.find((it) => !it.category)) {
            lorCategories.push({
                category: "Generic Attributes",
                categoryColor: "#69849c",
                lorColor: "#ecf0f5",
            });
        }

        if (lors) {
            lors.filter((it) => it.category).forEach((lor) => {
                if (!lorCategories.find((it) => it.category == lor.category)) {
                    lorCategories.push({
                        category: lor.category,
                        categoryColor: lor.categoryColor,
                        lorColor: lor.lorColor,
                    });
                }
            });
        }

        return (
            <React.Fragment>
                {this.renderModalEditProfile()}
                {lorCategories.map((lorCategory, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Table>
                                <thead className="tableHeader">
                                    <tr>
                                        <th className={"th-start"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            {lorCategory.category == "Generic Attributes" ? <ContentTranslator page="SfiaProfile" name="LorProfileTitle" contentText="Generic Attributes" /> : lorCategory.category}
                                        </th>
                                        <th style={{ backgroundColor: lorCategory.categoryColor }}></th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            1
                                        </th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            2
                                        </th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            3
                                        </th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            4
                                        </th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            5
                                        </th>
                                        <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            6
                                        </th>
                                        <th className={"th-fixwidth" + (props.showEdit ? "" : " th-end")} style={{ backgroundColor: lorCategory.categoryColor }}>
                                            7
                                        </th>
                                        {props.showEdit && <th className={"th-fixwidth th-end"} style={{ backgroundColor: lorCategory.categoryColor }}></th>}
                                    </tr>
                                </thead>
                                {this.renderLorProfileList(lorCategory.category, lorCategory.categoryColor, lorCategory.lorColor)}
                            </Table>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobLorProfile);
