import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    person: {},
    account: null,
    error: null,
    loading: false,
    updating: false,
    myPeoples: [],
    managedPeople: null,
    mentorPreference: [],
    attributes: [],
    myPeopleTeamAnalytic: null,

    profilePicture: null,
    skillCoins: [],

    loadingExport: false,
    exportData: null,

    columnDisplaySettings: null,
    columnDisplaySettingsLoading: false,
};

const getPersonMyProfileStart = (state) => {
    return updateState(state, { error: null, person: null, loading: true });
};

const getPersonMyProfileSuccess = (state, action) => {
    return updateState(state, {
        person: action.person,
        error: null,
        loading: false,
    });
};

const getPersonMyProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updatePersonMyProfileStart = (state) => {
    return updateState(state, { error: null, person: null, updating: true });
};

const updatePersonMyProfileSuccess = (state, action) => {
    return updateState(state, {
        person: action.person,
        error: null,
        updating: false,
    });
};

const updatePersonMyProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const getLatestAccountStart = (state) => {
    return updateState(state, { error: null, loading: true });
};

const getLatestAccountSuccess = (state, action) => {
    return updateState(state, {
        account: action.account,
        error: null,
        loading: false,
    });
};

const getLatestAccountFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updatePersonAccountStart = (state) => {
    return updateState(state, { error: null, attributes: [], updating: true });
};

const updatePersonAccountSuccess = (state, action) => {
    return updateState(state, {
        attributes: action.attributes,
        error: null,
        updating: false,
    });
};

const updatePersonAccountFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const updatePersonAccountNoteStart = (state) => {
    return updateState(state, { error: null, account: null, updating: true });
};

const updatePersonAccountNoteSuccess = (state, action) => {
    return updateState(state, {
        account: action.account,
        error: null,
        updating: false,
    });
};

const updatePersonAccountNoteFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const personAccountRequestApprovalStart = (state) => {
    return updateState(state, { error: null, account: null, updating: true });
};

const personAccountRequestApprovalSuccess = (state, action) => {
    return updateState(state, {
        account: action.account,
        error: null,
        updating: false,
    });
};

const personAccountRequestApprovalFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const getPersonMyPeoplesStart = (state) => {
    return updateState(state, { error: null, myPeoples: [], loading: true });
};

const getPersonMyPeoplesSuccess = (state, action) => {
    return updateState(state, {
        myPeoples: action.myPeoples,
        error: null,
        loading: false,
    });
};

const getPersonMyPeoplesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getPersonMyPeopleByEmailStart = (state) => {
    return updateState(state, {
        error: null,
        managedPeople: null,
        loading: true,
    });
};

const getPersonMyPeopleByEmailSuccess = (state, action) => {
    return updateState(state, {
        managedPeople: action.managedPeople,
        error: null,
        loading: false,
    });
};

const getPersonMyPeopleByEmailFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const approveMyPeopleProfileStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const approveMyPeopleProfileSuccess = (state) => {
    return updateState(state, {
        error: null,
        updating: false,
    });
};

const approveMyPeopleProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const postponeApproveMyPeopleProfileStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const postponeApproveMyPeopleProfileSuccess = (state) => {
    return updateState(state, {
        error: null,
        updating: false,
    });
};

const postponeApproveMyPeopleProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const forgetMeStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const forgetMeSuccess = (state) => {
    return updateState(state, {
        error: null,
        updating: false,
    });
};

const forgetMeFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const clearAccountData = (state, action) => {
    return updateState(state, {
        account: null,
    });
};

const downloadPersonProfilePictureStart = (state) => {
    return updateState(state, { error: null, profilePicture: null });
};

const downloadPersonProfilePictureSuccess = (state, action) => {
    return updateState(state, {
        profilePicture: action.profilePicture,
        error: null,
    });
};

const downloadPersonProfilePictureFail = (state, action) => {
    return updateState(state, {
        error: action.error,
    });
};

const uploadPersonProfilePictureStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const uploadPersonProfilePictureSuccess = (state, action) => {
    let curPerson = state.person;

    if (curPerson) {
        curPerson.profilePictureUrl = action.profilePictureUrl;
    }

    return updateState(state, {
        person: curPerson,
        error: null,
        updating: false,
    });
};

const uploadPersonProfilePictureFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const updatePersonActivityStatementIntervalStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const updatePersonActivityStatementIntervalSuccess = (state, action) => {
    const person = state.person;

    if (person) {
        person.activityStatementInterval = action.activityStatementInterval;
    }

    return updateState(state, {
        error: null,
        updating: false,
        person,
    });
};

const updatePersonActivityStatementIntervalFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const generateActivityStatementStart = (state) => {
    return updateState(state, { error: null, updating: true });
};

const generateActivityStatementSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        updating: false,
    });
};

const generateActivityStatementFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        updating: false,
    });
};

const getPersonMyPeoplesTeamAnalyticStart = (state) => {
    return updateState(state, {
        error: null,
        myPeopleTeamAnalytic: null,
        loading: true,
    });
};

const getPersonMyPeoplesTeamAnalyticSuccess = (state, action) => {
    return updateState(state, {
        myPeopleTeamAnalytic: action.myPeopleTeamAnalytic,
        error: null,
        loading: false,
    });
};

const getPersonMyPeoplesTeamAnalyticFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const clearManagePeopleEndorsedStatus = (state) => {
    let managedPeople = state.managedPeople;
    if (managedPeople) {
        managedPeople.isEndorser = false;
        managedPeople.endorsementStatus = "";
    }

    return updateState(state, {
        managedPeople,
    });
};

const updateManagePeopleEndorsedStatus = (state, action) => {
    let managedPeople = state.managedPeople;
    if (managedPeople) {
        managedPeople.endorsementStatus = action.status;
    }

    return updateState(state, {
        managedPeople,
    });
};

const getPersonSkillCoinsStart = (state) => {
    return updateState(state, { error: null, loading: true, skillCoins: [] });
};

const getPersonSkillCoinsSuccess = (state, action) => {
    return updateState(state, {
        skillCoins: [...action.skillCoins],
        error: null,
        loading: false,
    });
};

const getPersonSkillCoinsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const downloadMyProfileStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const downloadMyProfileSuccess = (state, action) => {
    console.log(action.exportData);
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const downloadMyProfileFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const getColumnDisplaySettingsStart = (state) => {
    return updateState(state, {
        columnDisplaySettings: null,
        error: null,
        columnDisplaySettingsLoading: true,
    });
};

const getColumnDisplaySettingsSuccess = (state, action) => {
    return updateState(state, {
        columnDisplaySettings: [...action.columnDisplaySettings],
        error: null,
        columnDisplaySettingsLoading: false,
    });
};

const getColumnDisplaySettingsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        columnDisplaySettingsLoading: false,
    });
};

const updateColumnDisplaySettingStart = (state, action) => {
    return updateState(state, {
        error: null,
        columnDisplaySettingsLoading: true,
    });
};

const updateColumnDisplaySettingSuccess = (state, action) => {
    const updatedColumnDisplaySetting = action.updatedColumnDisplaySetting;
    let columnDisplaySettings = state.columnDisplaySettings;
    let oriColumnDisplaySetting = columnDisplaySettings.find((it) => it.id == updatedColumnDisplaySetting.id);
    if (oriColumnDisplaySetting) {
        oriColumnDisplaySetting.visible = updatedColumnDisplaySetting.visible;
    } else {
        columnDisplaySettings.push(updatedColumnDisplaySetting);
    }

    return updateState(state, {
        columnDisplaySettings: [...action.columnDisplaySettings],
        error: null,
        columnDisplaySettingsLoading: false,
    });
};

const updateColumnDisplaySettingFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        columnDisplaySettingsLoading: false,
    });
};

const updateColumnDisplaySettingOrderStart = (state, action) => {
    return updateState(state, {
        error: null,
        columnDisplaySettingsLoading: true,
    });
};

const updateColumnDisplaySettingOrderSuccess = (state, action) => {
    const updatedColumnDisplaySettings = action.updatedColumnDisplaySettings;

    let columnDisplaySettings = state.columnDisplaySettings;
    updatedColumnDisplaySettings.map((updatedColumnDisplaySetting) => {
        let oriColumnDisplaySetting = columnDisplaySettings.find((it) => it.id == updatedColumnDisplaySetting.id);
        if (oriColumnDisplaySetting) {
            oriColumnDisplaySetting.visible = updatedColumnDisplaySetting.visible;
            oriColumnDisplaySetting.order = updatedColumnDisplaySetting.order;
        } else {
            columnDisplaySettings.push(updatedColumnDisplaySetting);
        }
    });

    return updateState(state, {
        columnDisplaySettings: [...columnDisplaySettings],
        error: null,
        columnDisplaySettingsLoading: false,
    });
};

const updateColumnDisplaySettingOrderFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        columnDisplaySettingsLoading: false,
    });
};

const getPersonMentorPreferenceStart = (state) => {
    return updateState(state, { mentorPreference: null });
};

const getPersonMentorPreferenceSuccess = (state, action) => {
    return updateState(state, {
        mentorPreference: action.mentorPreference,
    });
};

const getPersonMentorPreferenceFail = (state, action) => {
    // return updateState(state, {
    //   error: action.error,
    //   loading: false,
    // });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PERSON_MYPROFILE_START:
            return getPersonMyProfileStart(state);
        case actionTypes.GET_PERSON_MYPROFILE_SUCCESS:
            return getPersonMyProfileSuccess(state, action);
        case actionTypes.GET_PERSON_MYPROFILE_FAIL:
            return getPersonMyProfileFail(state, action);
        case actionTypes.DOWNLOAD_PERSON_MYPROFILE_START:
            return downloadMyProfileStart(state);
        case actionTypes.DOWNLOAD_PERSON_MYPROFILE_SUCCESS:
            return downloadMyProfileSuccess(state, action);
        case actionTypes.DOWNLOAD_PERSON_MYPROFILE_FAIL:
            return downloadMyProfileFail(state, action);
        case actionTypes.UPDATE_PERSON_MYPROFILE_START:
            return updatePersonMyProfileStart(state);
        case actionTypes.UPDATE_PERSON_MYPROFILE_SUCCESS:
            return updatePersonMyProfileSuccess(state, action);
        case actionTypes.UPDATE_PERSON_MYPROFILE_FAIL:
            return updatePersonMyProfileFail(state, action);

        case actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_START:
            return downloadPersonProfilePictureStart(state);
        case actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_SUCCESS:
            return downloadPersonProfilePictureSuccess(state, action);
        case actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_FAIL:
            return downloadPersonProfilePictureFail(state, action);

        case actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_START:
            return uploadPersonProfilePictureStart(state);
        case actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_SUCCESS:
            return uploadPersonProfilePictureSuccess(state, action);
        case actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_FAIL:
            return uploadPersonProfilePictureFail(state, action);

        case actionTypes.GET_PERSON_LATESTACCOUNT_START:
        case actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_START:
            return getLatestAccountStart(state);
        case actionTypes.GET_PERSON_LATESTACCOUNT_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_SUCCESS:
            return getLatestAccountSuccess(state, action);
        case actionTypes.GET_PERSON_LATESTACCOUNT_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_FAIL:
            return getLatestAccountFail(state, action);

        case actionTypes.UPDATE_PERSON_ACCOUNT_START:
            return updatePersonAccountStart(state);
        case actionTypes.UPDATE_PERSON_ACCOUNT_SUCCESS:
            return updatePersonAccountSuccess(state, action);
        case actionTypes.UPDATE_PERSON_ACCOUNT_FAIL:
            return updatePersonAccountFail(state, action);

        case actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_START:
            return updatePersonAccountNoteStart(state);
        case actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_SUCCESS:
            return updatePersonAccountNoteSuccess(state, action);
        case actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_FAIL:
            return updatePersonAccountNoteFail(state, action);

        case actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_START:
            return personAccountRequestApprovalStart(state);
        case actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_SUCCESS:
            return personAccountRequestApprovalSuccess(state, action);
        case actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_FAIL:
            return personAccountRequestApprovalFail(state, action);
        case actionTypes.GET_PERSON_MYPEOPLES_START:
            return getPersonMyPeoplesStart(state);
        case actionTypes.GET_PERSON_MYPEOPLES_SUCCESS:
            return getPersonMyPeoplesSuccess(state, action);
        case actionTypes.GET_PERSON_MYPEOPLES_FAIL:
            return getPersonMyPeoplesFail(state, action);
        case actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_START:
            return getPersonMyPeopleByEmailStart(state);
        case actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_SUCCESS:
            return getPersonMyPeopleByEmailSuccess(state, action);
        case actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_FAIL:
            return getPersonMyPeopleByEmailFail(state, action);
        case actionTypes.APPROVE_MYPEOPLE_PROFILE_START:
            return approveMyPeopleProfileStart(state);
        case actionTypes.APPROVE_MYPEOPLE_PROFILE_SUCCESS:
            return approveMyPeopleProfileSuccess(state);
        case actionTypes.APPROVE_MYPEOPLE_PROFILE_FAIL:
            return approveMyPeopleProfileFail(state, action);
        case actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_START:
            return postponeApproveMyPeopleProfileStart(state);
        case actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_SUCCESS:
            return postponeApproveMyPeopleProfileSuccess(state);
        case actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_FAIL:
            return postponeApproveMyPeopleProfileFail(state, action);
        case actionTypes.PERSON_FORGET_ME_START:
            return forgetMeStart(state);
        case actionTypes.PERSON_FORGET_ME_SUCCESS:
            return forgetMeSuccess(state);
        case actionTypes.PERSON_FORGET_ME_FAIL:
            return forgetMeFail(state, action);
        case actionTypes.CLEAR_PERSON_ACCOUNT_STATE:
            return clearAccountData(state, action);

        case actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_START:
            return updatePersonActivityStatementIntervalStart(state);
        case actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_SUCCESS:
            return updatePersonActivityStatementIntervalSuccess(state, action);
        case actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_FAIL:
            return updatePersonActivityStatementIntervalFail(state, action);

        case actionTypes.GENERATE_ACTIVITYSTATEMENT_START:
            return generateActivityStatementStart(state);
        case actionTypes.GENERATE_ACTIVITYSTATEMENT_SUCCESS:
            return generateActivityStatementSuccess(state, action);
        case actionTypes.GENERATE_ACTIVITYSTATEMENT_FAIL:
            return generateActivityStatementFail(state, action);

        case actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_START:
            return getPersonMyPeoplesTeamAnalyticStart(state);
        case actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_SUCCESS:
            return getPersonMyPeoplesTeamAnalyticSuccess(state, action);
        case actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_FAIL:
            return getPersonMyPeoplesTeamAnalyticFail(state, action);
        case actionTypes.CLEAR_MANAGE_PEOPLE_ENDORSED_STATUS:
            return clearManagePeopleEndorsedStatus(state);
        case actionTypes.UPDATE_MANAGE_PEOPLE_ENDORSED_STATUS:
            return updateManagePeopleEndorsedStatus(state, action);

        case actionTypes.GET_PERSON_SKILL_COINS_START:
            return getPersonSkillCoinsStart(state);
        case actionTypes.GET_PERSON_SKILL_COINS_SUCCESS:
            return getPersonSkillCoinsSuccess(state, action);
        case actionTypes.GET_PERSON_SKILL_COINS_FAIL:
            return getPersonSkillCoinsFail(state, action);

        case actionTypes.GET_COLUMN_DISPLAY_SETTINGS_START:
            return getColumnDisplaySettingsStart(state);
        case actionTypes.GET_COLUMN_DISPLAY_SETTINGS_SUCCESS:
            return getColumnDisplaySettingsSuccess(state, action);
        case actionTypes.GET_COLUMN_DISPLAY_SETTINGS_FAIL:
            return getColumnDisplaySettingsFail(state, action);

        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_START:
            return updateColumnDisplaySettingStart(state, action);
        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_SUCCESS:
            return updateColumnDisplaySettingSuccess(state, action);
        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_FAIL:
            return updateColumnDisplaySettingFail(state, action);

        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_START:
            return updateColumnDisplaySettingOrderStart(state, action);
        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_SUCCESS:
            return updateColumnDisplaySettingOrderSuccess(state, action);
        case actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_FAIL:
            return updateColumnDisplaySettingOrderFail(state, action);

        case actionTypes.GET_PERSON_MENTOR_PREFERENCE_START:
            return getPersonMentorPreferenceStart(state, action);
        case actionTypes.GET_PERSON_MENTOR_PREFERENCE_SUCCESS:
            return getPersonMentorPreferenceSuccess(state, action);
        case actionTypes.GET_PERSON_MENTOR_PREFERENCE_FAIL:
            return getPersonMentorPreferenceFail(state, action);

        default:
            return state;
    }
};

export default reducer;
