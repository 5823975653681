import ActionPlan from "../components/actionplan/ActionPlan";
import ManageJobProfile from "../components/admin/jobs/ManageJobProfile";
import NavSecondary from "../components/common/NavSecondary";
import DigitalWallet from "../components/digitalwallet/DigitalWallet";
import Evidence from "../components/evidence/Evidence";
import EvidenceDownload from "../components/evidence/EvidenceDownload";
import MyPeople from "../components/mypeople/MyPeople";
import TeamAnalytics from "../components/mypeople/TeamAnalytics";
import Note from "../components/note/Note";
import JobsProfile from "../components/profile/JobsProfile";
import SkillsProfile from "../components/profile/SkillsProfile";
import RequirementProfile from "../components/requirements/RequirementProfile";
import Requirements from "../components/requirements/Requirements";
import React from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Container, Modal, ModalBody } from "reactstrap";

class ProfilePage extends React.Component {
    static displayName = ProfilePage.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            showRotate: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.checkDeviceOrientation);
        this.checkDeviceOrientation();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    checkDeviceOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches && isMobile) {
            this.setState({ showRotate: true });
        } else if (window.matchMedia("(orientation: landscape)").matches) {
            this.setState({ showRotate: false });
        }
    };

    closeRotateModal = () => {
        this.setState({ showRotate: false });
    };

    renderRotateNotification = () => {
        return (
            <Modal isOpen={this.state.showRotate} centered={true} onClick={() => this.closeRotateModal()}>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/rotate.gif"} style={{ height: "100px" }} />
                        <br />
                        <span style={{ fontSize: "16px" }}>PLEASE ROTATE YOUR DEVICE</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>(OR CLICK TO CONTINUE)</span>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.renderRotateNotification()}
                <Container className={window.location.pathname.includes("TeamAnalytics") || window.location.pathname.includes("Diff") ? "contentMainMax" : "contentMain"}>
                    <NavSecondary />
                </Container>
                <Switch>
                    <Route exact path="/SfiaProfile/" component={SkillsProfile} />
                    <Route exact path="/SfiaProfile/Manage" component={SkillsProfile} />
                    <Route exact path="/SfiaProfile/Certify" render={(props) => <SkillsProfile {...props} badgeCertification={true} />} />
                    <Route exact path="/SfiaProfile/ActionPlan" component={ActionPlan} />
                    <Route exact path="/SfiaProfile/ActionPlan/Manage" component={ActionPlan} />
                    <Route exact path="/SfiaProfile/Evidence" component={Evidence} />
                    <Route exact path="/SfiaProfile/Evidence/Download" render={(props) => <EvidenceDownload {...props} managed={false} />} />
                    <Route exact path="/SfiaProfile/Evidence/Manage/Download" render={(props) => <EvidenceDownload {...props} managed={true} />} />
                    <Route exact path="/SfiaProfile/Evidence/Manage" component={Evidence} />
                    <Route exact path="/SfiaProfile/Notes" component={Note} />
                    <Route exact path="/SfiaProfile/Notes/Manage" component={Note} />
                    <Route exact path="/SfiaProfile/MyPeoples" render={(props) => <MyPeople {...props} mode="MyPeoples" />} />
                    <Route exact path="/SfiaProfile/Endorsements" render={(props) => <MyPeople {...props} mode="Endorsements" />} />
                    <Route path="/SfiaProfile/TeamAnalytics" component={TeamAnalytics} />
                    <Route exact path="/SfiaProfile/JobProfile/:jobId" component={JobsProfile} />
                    <Route exact path="/SfiaProfile/Requirements" component={Requirements} />
                    <Route exact path="/SfiaProfile/Requirements/:id/profile" render={(props) => <ManageJobProfile isManagedByOwner={true} />} />
                    <Route exact path="/SfiaProfile/DigitalWallet" component={DigitalWallet} />
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
