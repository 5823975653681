import * as actions from "../../src/store/actions/authAction";
import FormRegister from "../components/FormRegister";
import { Layout } from "../components/Layout";
import LoggedInRoute from "../components/routes/LoggedInRoute";
import asyncComponent from "./../hoc/AsyncComponent/AsyncComponent";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

export const asyncFormRegister = asyncComponent(() => {
    return import("./../components/FormRegister");
});

class WelcomePage extends Component {
    static displayName = WelcomePage.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const authMode = localStorage.getItem("authMode");
        return (
            <Layout language={this.props.language} setCurrentLanguage={this.props.setCurrentLanguage}>
                <LoggedInRoute
                    isAuthenticated={this.props.isAuthenticated}
                    authMode={authMode ? authMode : "Planner"}
                    containerConfig={this.props.containerConfig}
                    exact
                    path="/"
                    isLogout={this.props.isLogout}
                    redirectUrl={this.props.location && this.props.location.state && this.props.location.state.redirectUrl ? this.props.location.state.redirectUrl : null}
                    component={() => <FormRegister connectSignalR={this.props.connectSignalR} />}
                />
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.token !== null,
        //stateRoles: state.auth.roles,
        loading: state.auth.loading,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authAction: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomePage));
