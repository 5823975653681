import React, { Component } from "react";
import { Container, Table } from "reactstrap";

class ManageJobDetailMatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job: this.props.job,
            isUpdating: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.job !== this.props.job) {
            this.setState({ job: this.props.job });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }
    }

    handleChange = (event) => {
        const job = this.state.job;
        const { value } = event.target;
        job.comment = value;

        this.setState({ job });
    };

    // handleSave = () => {
    //     let job = this.state.job;
    //     this.props.handleSaveJobNoteUpdate(job);
    // };

    render() {
        const job = this.props.job;
        const jobMatches = job && job.jobMatches && job.jobMatches.length > 0 ? job.jobMatches.filter((it) => it.jobType == this.props.jobType) : [];

        return (
            <React.Fragment>
                <Container style={{ backgroundColor: "#ffffff", borderRadius: "20px", marginBottom: "10px", padding: "30px" }}>
                    <p
                        className="mb-0"
                        style={{
                            color: "#2B2B2B",
                            fontSize: "16px",
                            fontFamily: "IBMPlexSans-Bold",
                            paddingBottom: "10px",
                        }}
                    >
                        {this.props.title}
                    </p>
                    <Table style={{ fontSize: "12px" }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        {jobMatches.length > 0 ? (
                            <tbody>
                                {jobMatches.map((jobMatch, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{`${jobMatch.firstName} ${jobMatch.lastName}`}</td>
                                            <td>{jobMatch.email}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={2}>No persons have assigned this as their {this.props.jobType} Requirement.</td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                </Container>
            </React.Fragment>
        );
    }
}

export default ManageJobDetailMatches;
