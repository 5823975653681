import JobAPI from "./../../common/api/service/JobService";
import * as actionTypes from "./actionTypes";

export const getJobsStart = () => {
    return {
        type: actionTypes.GET_JOBS_START,
    };
};

export const getJobsSuccess = (jobs) => {
    return {
        type: actionTypes.GET_JOBS_SUCCESS,
        jobs,
    };
};

export const getJobsFail = (error) => {
    return {
        type: actionTypes.GET_JOBS_FAIL,
        error: error,
    };
};

export const getJobs = () => {
    return (dispatch) => {
        dispatch(getJobsStart());

        return JobAPI.getJobs()
            .then((res) => {
                if (res) {
                    dispatch(getJobsSuccess(res));
                } else {
                    dispatch(getJobsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobsFail(err));
            });
    };
};

export const getJobsWithAction = () => {
    return (dispatch) => {
        dispatch(getJobsStart());

        return JobAPI.getJobsWithAction()
            .then((res) => {
                if (res) {
                    dispatch(getJobsSuccess(res));
                } else {
                    dispatch(getJobsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobsFail(err));
            });
    };
};

export const getSampleJobsStart = () => {
    return {
        type: actionTypes.GET_SAMPLE_JOBS_START,
    };
};

export const getSampleJobsSuccess = (sampleJobs) => {
    return {
        type: actionTypes.GET_SAMPLE_JOBS_SUCCESS,
        sampleJobs,
    };
};

export const getSampleJobsFail = (error) => {
    return {
        type: actionTypes.GET_SAMPLE_JOBS_FAIL,
        error: error,
    };
};

export const getSampleJobs = () => {
    return (dispatch) => {
        dispatch(getSampleJobsStart());

        return JobAPI.getJobs(true)
            .then((res) => {
                if (res) {
                    dispatch(getSampleJobsSuccess(res));
                } else {
                    dispatch(getSampleJobsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getSampleJobsFail(err));
            });
    };
};

export const getOwnedJobs = () => {
    return (dispatch) => {
        dispatch(getJobsStart());

        return JobAPI.getOwnedJobs()
            .then((res) => {
                if (res) {
                    dispatch(getJobsSuccess(res));
                } else {
                    dispatch(getJobsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobsFail(err));
            });
    };
};

export const getJobStart = () => {
    return {
        type: actionTypes.GET_JOB_START,
    };
};

export const getJobSuccess = (job) => {
    return {
        type: actionTypes.GET_JOB_SUCCESS,
        job,
    };
};

export const getJobFail = (error) => {
    return {
        type: actionTypes.GET_JOB_FAIL,
        error: error,
    };
};

export const getJob = (id) => {
    return (dispatch) => {
        dispatch(getJobStart());

        return JobAPI.getJob(id)
            .then((res) => {
                if (res) {
                    dispatch(getJobSuccess(res));
                } else {
                    dispatch(getJobFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobFail(err));
            });
    };
};

export const printJobReportStart = () => {
    return {
        type: actionTypes.PRINT_JOB_REPORT_START,
    };
};

export const printJobReportSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_JOB_REPORT_SUCCESS,
        printData,
    };
};

export const printJobReportFail = (error) => {
    return {
        type: actionTypes.PRINT_JOB_REPORT_FAIL,
        error: error,
    };
};

export const printJobReport = (id) => {
    return (dispatch) => {
        dispatch(printJobReportStart());

        return JobAPI.printJobProfile(id)
            .then((res) => {
                if (res) {
                    dispatch(printJobReportSuccess(res));
                } else {
                    dispatch(printJobReportFail(res));
                }
            })
            .catch((err) => {
                dispatch(printJobReportFail(err));
            });
    };
};

export const printJobReportsStart = () => {
    return {
        type: actionTypes.PRINT_JOB_REPORTS_START,
    };
};

export const printJobReportsSuccess = (printData) => {
    return {
        type: actionTypes.PRINT_JOB_REPORTS_SUCCESS,
        printData,
    };
};

export const printJobReportsFail = (error) => {
    return {
        type: actionTypes.PRINT_JOB_REPORTS_FAIL,
        error: error,
    };
};

export const printJobReports = (jobIds) => {
    return (dispatch) => {
        dispatch(printJobReportsStart());

        return JobAPI.printJobProfiles(jobIds)
            .then((res) => {
                if (res) {
                    dispatch(printJobReportsSuccess(res));
                } else {
                    dispatch(printJobReportsFail(res));
                }
            })
            .catch((err) => {
                dispatch(printJobReportsFail(err));
            });
    };
};

export const createJobStart = () => {
    return {
        type: actionTypes.CREATE_JOB_START,
    };
};

export const createJobSuccess = (addedJob) => {
    return {
        type: actionTypes.CREATE_JOB_SUCCESS,
        addedJob,
    };
};

export const createJobFail = (error) => {
    return {
        type: actionTypes.CREATE_JOB_FAIL,
        error: error,
    };
};

export const createJob = (job) => {
    return (dispatch) => {
        dispatch(createJobStart());

        return JobAPI.createJob(job)
            .then((res) => {
                if (res) {
                    dispatch(createJobSuccess(res));
                } else {
                    dispatch(createJobFail(res));
                }
            })
            .catch((err) => {
                dispatch(createJobFail(err));
            });
    };
};

export const updateJobStart = () => {
    return {
        type: actionTypes.UPDATE_JOB_START,
    };
};

export const updateJobSuccess = (updatedJob) => {
    return {
        type: actionTypes.UPDATE_JOB_SUCCESS,
        updatedJob,
    };
};

export const updateJobByNotification = (updatedJob) => {
    return {
        type: actionTypes.UPDATE_JOB_BY_NOTIFICATION,
        updatedJob,
    };
};

export const updateJobFail = (error) => {
    return {
        type: actionTypes.UPDATE_JOB_FAIL,
        error: error,
    };
};

export const updateJob = (id, job) => {
    return (dispatch) => {
        dispatch(updateJobStart());

        return JobAPI.updateJob(id, job)
            .then((res) => {
                if (res) {
                    dispatch(updateJobSuccess(res));
                } else {
                    dispatch(updateJobFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateJobFail(err));
            });
    };
};

export const updateJobNoteStart = () => {
    return {
        type: actionTypes.UPDATE_JOB_NOTE_START,
    };
};

export const updateJobNoteSuccess = (updatedJob) => {
    return {
        type: actionTypes.UPDATE_JOB_NOTE_SUCCESS,
        updatedJob,
    };
};

export const updateJobNoteFail = (error) => {
    return {
        type: actionTypes.UPDATE_JOB_NOTE_FAIL,
        error: error,
    };
};

export const updateJobNote = (id, note) => {
    return (dispatch) => {
        dispatch(updateJobNoteStart());

        return JobAPI.updateJobNote(id, note)
            .then((res) => {
                if (res) {
                    dispatch(updateJobNoteSuccess(res));
                } else {
                    dispatch(updateJobNoteFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateJobNoteFail(err));
            });
    };
};

export const deleteJobStart = () => {
    return {
        type: actionTypes.DELETE_JOB_START,
    };
};

export const deleteJobSuccess = (id) => {
    return {
        type: actionTypes.DELETE_JOB_SUCCESS,
        id,
    };
};

export const deleteJobFail = (error) => {
    return {
        type: actionTypes.DELETE_JOB_FAIL,
        error: error,
    };
};

export const deleteJob = (id) => {
    return (dispatch) => {
        dispatch(deleteJobStart());

        return JobAPI.deleteJob(id)
            .then((res) => {
                dispatch(deleteJobSuccess(id));
            })
            .catch((err) => {
                dispatch(deleteJobFail(err));
            });
    };
};

export const copyJobStart = () => {
    return {
        type: actionTypes.COPY_JOB_START,
    };
};

export const copyJobSuccess = (addedJob) => {
    return {
        type: actionTypes.COPY_JOB_SUCCESS,
        addedJob,
    };
};

export const copyJobFail = (error) => {
    return {
        type: actionTypes.COPY_JOB_FAIL,
        error: error,
    };
};

export const copyJob = (request) => {
    return (dispatch) => {
        dispatch(copyJobStart());

        return JobAPI.copyJob(request)
            .then((res) => {
                if (res) {
                    dispatch(copyJobSuccess(res));
                } else {
                    dispatch(copyJobFail(res));
                }
            })
            .catch((err) => {
                dispatch(copyJobFail(err));
            });
    };
};

export const copyJobsStart = () => {
    return {
        type: actionTypes.COPY_JOBS_START,
    };
};

export const copyJobsSuccess = (addedJobs) => {
    return {
        type: actionTypes.COPY_JOBS_SUCCESS,
        addedJobs,
    };
};

export const copyJobsFail = (error) => {
    return {
        type: actionTypes.COPY_JOBS_FAIL,
        error: error,
    };
};

export const copyJobs = (request) => {
    return (dispatch) => {
        dispatch(copyJobsStart());

        return JobAPI.copyJobs(request)
            .then((res) => {
                if (res) {
                    dispatch(copyJobsSuccess(res));
                } else {
                    dispatch(copyJobsFail(res));
                }
            })
            .catch((err) => {
                dispatch(copyJobsFail(err));
            });
    };
};

export const updateJobSkillAttributesStart = () => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_START,
    };
};

export const updateJobSkillAttributesSuccess = (jobSkillAttributes) => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_SUCCESS,
        jobSkillAttributes,
    };
};

export const updateJobSkillAttributesFail = (error) => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_FAIL,
        error: error,
    };
};

export const updateJobSkillAttributes = (id, attributes) => {
    return (dispatch) => {
        dispatch(updateJobSkillAttributesStart());

        return JobAPI.updateJobSkillAttributes(id, attributes)
            .then((res) => {
                if (res) {
                    dispatch(updateJobSkillAttributesSuccess(res));
                } else {
                    dispatch(updateJobSkillAttributesFail(res));
                }
            })
            .catch((err) => {
                dispatch(updateJobSkillAttributesFail(err));
            });
    };
};

export const getMyJobMatchTypesStart = () => {
    return {
        type: actionTypes.GET_MY_JOB_MATCH_TYPES_START,
    };
};

export const getMyJobMatchTypesSuccess = (jobMatchTypes) => {
    return {
        type: actionTypes.GET_MY_JOB_MATCH_TYPES_SUCCESS,
        jobMatchTypes,
    };
};

export const getMyJobMatchTypesFail = (error) => {
    return {
        type: actionTypes.GET_MY_JOB_MATCH_TYPES_FAIL,
        error: error,
    };
};

export const getMyJobMatchTypes = () => {
    return (dispatch) => {
        dispatch(getMyJobMatchTypesStart());

        return JobAPI.getMyJobMatchTypes()
            .then((res) => {
                if (res) {
                    dispatch(getMyJobMatchTypesSuccess(res));
                }
            })
            .catch((err) => {
                dispatch(getMyJobMatchTypesFail(err));
            });
    };
};

export const getMyJobMatchesStart = () => {
    return {
        type: actionTypes.GET_MY_JOB_MATCHES_START,
    };
};

export const getMyJobMatchesSuccess = (jobMatches) => {
    return {
        type: actionTypes.GET_MY_JOB_MATCHES_SUCCESS,
        jobMatches,
    };
};

export const getMyJobMatchesFail = (error) => {
    return {
        type: actionTypes.GET_MY_JOB_MATCHES_FAIL,
        error: error,
    };
};

export const getMyJobMatches = () => {
    return (dispatch) => {
        dispatch(getMyJobMatchesStart());

        return JobAPI.getMyJobMatches()
            .then((res) => {
                dispatch(getMyJobMatchesSuccess(res));
            })
            .catch((err) => {
                dispatch(getMyJobMatchesFail(err));
            });
    };
};

export const getManagedPeopleJobMatchesStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_START,
    };
};

export const getManagedPeopleJobMatchesSuccess = (jobMatches) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS,
        jobMatches,
    };
};

export const getManagedPeopleJobMatchesFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_FAIL,
        error: error,
    };
};

export const getManagedPeopleJobMatches = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleJobMatchesStart());

        return JobAPI.getManagedPeopleJobMatches(email)
            .then((res) => {
                dispatch(getManagedPeopleJobMatchesSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleJobMatchesFail(err));
            });
    };
};

export const updateMyJobMatchesStart = () => {
    return {
        type: actionTypes.UPDATE_MY_JOB_MATCHES_START,
    };
};

export const updateMyJobMatchesSuccess = (jobMatches) => {
    return {
        type: actionTypes.UPDATE_MY_JOB_MATCHES_SUCCESS,
        jobMatchesUpdated: true,
        jobMatches,
    };
};

export const updateMyJobMatchesFail = (error) => {
    return {
        type: actionTypes.UPDATE_MY_JOB_MATCHES_FAIL,
        jobMatchesUpdated: false,
        error: error,
    };
};

export const updateMyJobMatches = (data) => {
    return (dispatch) => {
        dispatch(updateMyJobMatchesStart());

        return JobAPI.updateMyJobMatches(data)
            .then((res) => {
                dispatch(updateMyJobMatchesSuccess(res));
            })
            .catch((err) => {
                dispatch(updateMyJobMatchesFail(err));
            });
    };
};

export const updateManagedPeopleJobMatchesStart = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_START,
    };
};

export const updateManagedPeopleJobMatchesSuccess = (jobMatches) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS,
        jobMatchesUpdated: true,
        jobMatches,
    };
};

export const updateManagedPeopleJobMatchesFail = (error) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_FAIL,
        jobMatchesUpdated: false,
        error: error,
    };
};

export const updateManagedPeopleJobMatches = (data) => {
    return (dispatch) => {
        dispatch(updateManagedPeopleJobMatchesStart());

        return JobAPI.updateManagedPeopleJobMatches(data)
            .then((res) => {
                dispatch(updateManagedPeopleJobMatchesSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeopleJobMatchesFail(err));
            });
    };
};

export const getJobProfileVersionsStart = () => {
    return {
        type: actionTypes.GET_JOB_PROFILES_VERSIONS_START,
    };
};

export const getJobProfileVersionsSuccess = (profileVersions) => {
    return {
        type: actionTypes.GET_JOB_PROFILES_VERSIONS_SUCCESS,
        profileVersions,
    };
};

export const getJobProfileVersionsFail = (error) => {
    return {
        type: actionTypes.GET_JOB_PROFILES_VERSIONS_FAIL,
        error: error,
    };
};

export const getJobProfileVersions = (jobId) => {
    return (dispatch) => {
        dispatch(getJobProfileVersionsStart());

        return JobAPI.getJobProfileVersions(jobId)
            .then((res) => {
                dispatch(getJobProfileVersionsSuccess(res));
            })
            .catch((err) => {
                dispatch(getJobProfileVersionsFail(err));
            });
    };
};

export const getJobLorProfilesStart = () => {
    return {
        type: actionTypes.GET_JOB_LOR_PROFILES_START,
    };
};

export const getJobLorProfilesSuccess = (jobLorProfiles) => {
    return {
        type: actionTypes.GET_JOB_LOR_PROFILES_SUCCESS,
        jobLorProfiles,
    };
};

export const getJobLorProfilesFail = (error) => {
    return {
        type: actionTypes.GET_JOB_LOR_PROFILES_FAIL,
        error: error,
    };
};

export const getJobLorProfiles = (jobId, sfiaVersion, version) => {
    return (dispatch) => {
        dispatch(getJobLorProfilesStart());

        return JobAPI.getJobLorProfiles(jobId, sfiaVersion, version)
            .then((res) => {
                dispatch(getJobLorProfilesSuccess(res));
            })
            .catch((err) => {
                dispatch(getJobLorProfilesFail(err));
            });
    };
};

export const getJobSkillProfilesStart = () => {
    return {
        type: actionTypes.GET_JOB_SKILL_PROFILES_START,
    };
};

export const getJobSkillProfilesSuccess = (jobSkillProfiles) => {
    return {
        type: actionTypes.GET_JOB_SKILL_PROFILES_SUCCESS,
        jobSkillProfiles,
    };
};

export const getJobSkillProfilesFail = (error) => {
    return {
        type: actionTypes.GET_JOB_SKILL_PROFILES_FAIL,
        error: error,
    };
};

export const getJobSkillProfiles = (jobId, sfiaVersion, version) => {
    return (dispatch) => {
        dispatch(getJobSkillProfilesStart());

        return JobAPI.getJobSkillProfiles(jobId, sfiaVersion, version)
            .then((res) => {
                dispatch(getJobSkillProfilesSuccess(res));
            })
            .catch((err) => {
                dispatch(getJobSkillProfilesFail(err));
            });
    };
};

export const updateJobSkillProfilesStart = () => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_PROFILES_START,
    };
};

export const updateJobSkillProfilesSuccess = (udpatedSkills) => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_PROFILES_SUCCESS,
        udpatedSkills,
    };
};

export const updateJobSkillProfilesFail = (error) => {
    return {
        type: actionTypes.UPDATE_JOB_SKILL_PROFILES_FAIL,
        error: error,
    };
};

export const clearUpdateJobSkillProfiles = () => {
    return {
        type: actionTypes.CLEAR_UPDATE_JOB_SKILL_PROFILES,
    };
};

export const updateJobSkillProfiles = (jobId, updatedSkills) => {
    return (dispatch) => {
        dispatch(updateJobSkillProfilesStart());
        JobAPI.updateJobProfiles(jobId, updatedSkills);
    };
};

export const getJobSkillAttributesStart = () => {
    return {
        type: actionTypes.GET_JOB_SKILL_ATTRIBUTES_START,
    };
};

export const getJobSkillAttributesSuccess = (jobSkillAttributes) => {
    return {
        type: actionTypes.GET_JOB_SKILL_ATTRIBUTES_SUCCESS,
        jobSkillAttributes,
    };
};

export const getJobSkillAttributesFail = (error) => {
    return {
        type: actionTypes.GET_JOB_SKILL_ATTRIBUTES_FAIL,
        error: error,
    };
};

export const getJobSkillAttributes = (jobId) => {
    return (dispatch) => {
        dispatch(getJobSkillAttributesStart());

        return JobAPI.getJobSkillAttributes(jobId)
            .then((res) => {
                dispatch(getJobSkillAttributesSuccess(res));
            })
            .catch((err) => {
                dispatch(getJobSkillAttributesFail(err));
            });
    };
};

export const getManagedPeopleJobLorProfilesStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_START,
    };
};

export const getManagedPeopleJobLorProfilesSuccess = (jobLorProfiles) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_SUCCESS,
        jobLorProfiles,
    };
};

export const getManagedPeopleJobLorProfilesFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_FAIL,
        error: error,
    };
};

export const getManagedPeopleJobLorProfiles = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleJobLorProfilesStart());

        return JobAPI.getManagedPeopleJobLorProfiles(email)
            .then((res) => {
                dispatch(getManagedPeopleJobLorProfilesSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleJobLorProfilesFail(err));
            });
    };
};

export const getManagedPeopleJobSkillProfilesStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_START,
    };
};

export const getManagedPeopleJobSkillProfilesSuccess = (jobSkillProfiles) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_SUCCESS,
        jobSkillProfiles,
    };
};

export const getManagedPeopleJobSkillProfilesFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_FAIL,
        error: error,
    };
};

export const getManagedPeopleJobSkillProfiles = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleJobSkillProfilesStart());

        return JobAPI.getManagedPeopleJobSkillProfiles(email)
            .then((res) => {
                dispatch(getManagedPeopleJobSkillProfilesSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleJobSkillProfilesFail(err));
            });
    };
};

export const setJobImportSessionId = (jobImportSessionId) => {
    return {
        type: actionTypes.SET_JOBIMPORT_SESSIONID,
        jobImportSessionId,
    };
};

export const exportJobsDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_JOBS_DATA_START,
    };
};

export const exportJobsDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_JOBS_DATA_SUCCESS,
        exportData,
    };
};

export const exportJobsDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_JOBS_DATA_FAIL,
        error: error,
    };
};

export const exportJobsDataExcel = () => {
    return (dispatch) => {
        dispatch(exportJobsDataExcelStart());

        return JobAPI.exportJobsDataExcel()
            .then((res) => {
                dispatch(exportJobsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportJobsDataExcelFail(err));
            });
    };
};

export const exportJobsDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportJobsDataExcelStart());

        return JobAPI.exportJobsDataExcelTemplate()
            .then((res) => {
                dispatch(exportJobsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportJobsDataExcelFail(err));
            });
    };
};

export const downloadJobImportExcel = (id) => {
    return (dispatch) => {
        dispatch(exportJobsDataExcelStart());

        return JobAPI.downloadJobImportExcel(id)
            .then((res) => {
                dispatch(exportJobsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportJobsDataExcelFail(err));
            });
    };
};

export const importJobsDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_JOBS_DATA_START,
    };
};

export const importJobsDataExcelSuccess = (addedJobImportQueue) => {
    return {
        type: actionTypes.IMPORT_JOBS_DATA_SUCCESS,
        addedJobImportQueue,
    };
};

export const importJobsDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_JOBS_DATA_FAIL,
        error: error,
    };
};

export const importJobsDataExcel = (sessionId, operation, jobsDataFiles) => {
    return (dispatch) => {
        dispatch(importJobsDataExcelStart());

        return JobAPI.importJobsDataExcel(sessionId, operation, jobsDataFiles)
            .then((res) => {
                dispatch(importJobsDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(importJobsDataExcelFail(err));
            });
    };
};

export const processJobImportQueue = (jobImportIds) => {
    return (dispatch) => {
        return JobAPI.processJobImportQueue(jobImportIds)
            .then((res) => {})
            .catch((err) => {});
    };
};

export const updateImportJobStatus = (updatedImportJobRequest, updatedJobs) => {
    return {
        type: actionTypes.UPDATE_IMPORT_JOB_STATUS,
        updatedImportJobRequest,
        updatedJobs,
    };
};

export const getJobImportQueueStart = () => {
    return {
        type: actionTypes.GET_JOB_IMPORT_QUEUE_START,
    };
};

export const getJobImportQueueSuccess = (jobImportQueue) => {
    return {
        type: actionTypes.GET_JOB_IMPORT_QUEUE_SUCCESS,
        jobImportQueue,
    };
};

export const getJobImportQueueFail = (error) => {
    return {
        type: actionTypes.GET_JOB_IMPORT_QUEUE_FAIL,
        error: error,
    };
};

export const getJobImportQueue = () => {
    return (dispatch) => {
        dispatch(getJobImportQueueStart());

        return JobAPI.getJobImportQueue()
            .then((res) => {
                if (res) {
                    dispatch(getJobImportQueueSuccess(res));
                } else {
                    dispatch(getJobImportQueueFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobImportQueueFail(err));
            });
    };
};

export const getJobMatchPersonsStart = () => {
    return {
        type: actionTypes.GET_JOB_MATCH_PERSONS_START,
    };
};

export const getJobMatchPersonsSuccess = (jobMatchPersons) => {
    return {
        type: actionTypes.GET_JOB_MATCH_PERSONS_SUCCESS,
        jobMatchPersons,
    };
};

export const getJobMatchPersonsFail = (error) => {
    return {
        type: actionTypes.GET_JOB_MATCH_PERSONS_FAIL,
        error: error,
    };
};

export const getJobMatchPersons = () => {
    return (dispatch) => {
        dispatch(getJobMatchPersonsStart());

        return JobAPI.getJobMatchPersons()
            .then((res) => {
                if (res) {
                    dispatch(getJobMatchPersonsSuccess(res));
                } else {
                    dispatch(getJobMatchPersonsFail(res));
                }
            })
            .catch((err) => {
                dispatch(getJobMatchPersonsFail(err));
            });
    };
};

export const assignJobMatchPersonStart = () => {
    return {
        type: actionTypes.ASSIGN_JOB_MATCH_PERSON_START,
    };
};

export const assignJobMatchPersonSuccess = (updatedJobMatch) => {
    return {
        type: actionTypes.ASSIGN_JOB_MATCH_PERSON_SUCCESS,
        updatedJobMatch,
    };
};

export const assignJobMatchPersonFail = (error) => {
    return {
        type: actionTypes.ASSIGN_JOB_MATCH_PERSON_FAIL,
        error: error,
    };
};

export const assignJobMatchPerson = (personId, jobId, jobMatchType) => {
    return (dispatch) => {
        dispatch(assignJobMatchPersonStart());

        return JobAPI.assignjobmatchperson(personId, jobId, jobMatchType)
            .then((res) => {
                if (res) {
                    dispatch(assignJobMatchPersonSuccess(res));
                } else {
                    dispatch(assignJobMatchPersonFail(res));
                }
            })
            .catch((err) => {
                dispatch(assignJobMatchPersonFail(err));
            });
    };
};

export const exportJobMatchesDataExcelStart = () => {
    return {
        type: actionTypes.EXPORT_JOB_MATCHES_DATA_START,
    };
};

export const exportJobMatchesDataExcelSuccess = (exportData) => {
    return {
        type: actionTypes.EXPORT_JOB_MATCHES_DATA_SUCCESS,
        exportData,
    };
};

export const exportJobMatchesDataExcelFail = (error) => {
    return {
        type: actionTypes.EXPORT_JOB_MATCHES_DATA_FAIL,
        error: error,
    };
};

export const exportJobMatchesDataExcel = () => {
    return (dispatch) => {
        dispatch(exportJobMatchesDataExcelStart());

        return JobAPI.exportJobMatchesDataExcel()
            .then((res) => {
                dispatch(exportJobMatchesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportJobMatchesDataExcelFail(err));
            });
    };
};

export const exportJobMatchesDataExcelTemplate = () => {
    return (dispatch) => {
        dispatch(exportJobMatchesDataExcelStart());

        return JobAPI.exportJobMatchesDataExcelTemplate()
            .then((res) => {
                dispatch(exportJobMatchesDataExcelSuccess(res));
            })
            .catch((err) => {
                dispatch(exportJobMatchesDataExcelFail(err));
            });
    };
};

export const importJobMatchesDataExcelStart = () => {
    return {
        type: actionTypes.IMPORT_JOB_MATCHES_DATA_START,
    };
};

export const importJobMatchesDataExcelSuccess = () => {
    return {
        type: actionTypes.IMPORT_JOB_MATCHES_DATA_SUCCESS,
    };
};

export const importJobMatchesDataExcelFail = (error) => {
    return {
        type: actionTypes.IMPORT_JOB_MATCHES_DATA_FAIL,
        error: error,
    };
};

export const importJobMatchesDataExcel = (operation, jobMatchesData) => {
    return (dispatch) => {
        dispatch(importJobMatchesDataExcelStart());

        return JobAPI.importJobmatchessDataExcel(operation, jobMatchesData)
            .then((res) => {
                dispatch(importJobMatchesDataExcelSuccess(res.message, res.personResponses));
            })
            .catch((err) => {
                dispatch(importJobMatchesDataExcelFail(err));
            });
    };
};

export const getJobFamiliesStart = () => {
    return {
        type: actionTypes.GET_JOB_FAMILIES_START,
    };
};

export const getJobFamiliesSuccess = (jobFamilies) => {
    return {
        type: actionTypes.GET_JOB_FAMILIES_SUCCESS,
        jobFamilies,
    };
};

export const getJobFamiliesFail = (error) => {
    return {
        type: actionTypes.GET_JOB_FAMILIES_FAIL,
        error: error,
    };
};

export const getJobFamilies = () => {
    return (dispatch) => {
        dispatch(getJobFamiliesStart());

        return JobAPI.getJobFamilies()
            .then((res) => {
                dispatch(getJobFamiliesSuccess(res));
            })
            .catch((err) => {
                dispatch(getJobFamiliesFail(err));
            });
    };
};

export const updateJobFamilyStart = () => {
    return {
        type: actionTypes.UPDATE_JOB_FAMILY_START,
    };
};

export const updateJobFamilySuccess = (jobFamily, deletedJobIds) => {
    return {
        type: actionTypes.UPDATE_JOB_FAMILY_SUCCESS,
        jobFamily,
        deletedJobIds,
    };
};

export const updateJobFamilyFail = (error) => {
    return {
        type: actionTypes.UPDATE_JOB_FAMILY_FAIL,
        error: error,
    };
};

export const createJobFamily = (jobFamily) => {
    return (dispatch) => {
        dispatch(updateJobFamilyStart());

        return JobAPI.createJobFamily(jobFamily)
            .then((res) => {
                dispatch(updateJobFamilySuccess(res));
            })
            .catch((err) => {
                dispatch(updateJobFamilyFail(err));
            });
    };
};

export const updateJobFamily = (id, jobFamily) => {
    return (dispatch) => {
        dispatch(updateJobFamilyStart());

        return JobAPI.updateJobFamily(id, jobFamily)
            .then((res) => {
                dispatch(updateJobFamilySuccess(res));
            })
            .catch((err) => {
                dispatch(updateJobFamilyFail(err));
            });
    };
};

export const updateJobFamilyAddEntries = (id, addedJobIds) => {
    return (dispatch) => {
        dispatch(updateJobFamilyStart());

        return JobAPI.updateJobFamilyAddEntries(id, addedJobIds)
            .then((res) => {
                dispatch(updateJobFamilySuccess(res));
            })
            .catch((err) => {
                dispatch(updateJobFamilyFail(err));
            });
    };
};

export const updateJobFamilyDeleteEntries = (id, deletedJobIds) => {
    return (dispatch) => {
        dispatch(updateJobFamilyStart());

        return JobAPI.updateJobFamilyDeleteEntries(id, deletedJobIds)
            .then((res) => {
                dispatch(updateJobFamilySuccess(res, deletedJobIds));
            })
            .catch((err) => {
                dispatch(updateJobFamilyFail(err));
            });
    };
};

export const deleteJobFamilyStart = () => {
    return {
        type: actionTypes.DELETE_JOB_FAMILY_START,
    };
};

export const deleteJobFamilySuccess = (deletedJobFamilyId) => {
    return {
        type: actionTypes.DELETE_JOB_FAMILY_SUCCESS,
        deletedJobFamilyId,
    };
};

export const deleteJobFamilyFail = (error) => {
    return {
        type: actionTypes.DELETE_JOB_FAMILY_FAIL,
        error: error,
    };
};

export const deleteJobFamily = (id) => {
    return (dispatch) => {
        dispatch(deleteJobFamilyStart());

        return JobAPI.deleteJobFamily(id)
            .then((res) => {
                dispatch(deleteJobFamilySuccess(id));
            })
            .catch((err) => {
                dispatch(deleteJobFamilyFail(err));
            });
    };
};
