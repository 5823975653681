import PersonAPI from "./../../common/api/service/PersonService";
import * as actionTypes from "./actionTypes";

export const getPersonMyProfileStart = () => {
    return {
        type: actionTypes.GET_PERSON_MYPROFILE_START,
    };
};

export const getPersonMyProfileSuccess = (person) => {
    return {
        type: actionTypes.GET_PERSON_MYPROFILE_SUCCESS,
        person,
    };
};

export const getPersonMyProfileFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_MYPROFILE_FAIL,
        error: error,
    };
};

export const getPersonMyProfile = () => {
    return (dispatch) => {
        dispatch(getPersonMyProfileStart());

        return PersonAPI.getPersonMyProfile()
            .then((res) => {
                if (res.id) {
                    dispatch(getPersonMyProfileSuccess(res));
                } else {
                    dispatch(getPersonMyProfileFail(res));
                }
            })
            .catch((err) => {
                dispatch(getPersonMyProfileFail(err));
            });
    };
};

export const getActionMentorPreferenceStart = () => {
    return {
        type: actionTypes.GET_PERSON_MENTOR_PREFERENCE_START,
    };
};

export const getPersonMentorPreferenceSuccess = (mentorPreference) => {
    return {
        type: actionTypes.GET_PERSON_MENTOR_PREFERENCE_SUCCESS,
        mentorPreference,
    };
};

export const getPersonMentorPreferenceFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_MENTOR_PREFERENCE_FAIL,
        error: error,
    };
};

export const getPersonMentorPreference = () => {
    return (dispatch) => {
        dispatch(getActionMentorPreferenceStart());

        return PersonAPI.getPersonMyMentorPreferences()
            .then((res) => {
                if (res) {
                    dispatch(getPersonMentorPreferenceSuccess(res));
                } else {
                    dispatch(getPersonMentorPreferenceFail(res));
                }
            })
            .catch((err) => {
                dispatch(getPersonMentorPreferenceFail(err));
            });
    };
};

export const downloadPersonMyProfileStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_MYPROFILE_START,
    };
};

export const downloadPersonMyProfileSuccess = (exportData) => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_MYPROFILE_SUCCESS,
        exportData,
    };
};

export const downloadPersonMyProfileFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_MYPROFILE_FAIL,
        error: error,
    };
};

export const downloadPersonMyProfile = () => {
    return (dispatch) => {
        dispatch(downloadPersonMyProfileStart());

        return PersonAPI.downloadPersonMyProfile()
            .then((res) => {
                if (res) {
                    dispatch(downloadPersonMyProfileSuccess(res));
                } else {
                    dispatch(downloadPersonMyProfileFail(res));
                }
            })
            .catch((err) => {
                dispatch(downloadPersonMyProfileFail(err));
            });
    };
};

export const updatePersonMyProfileStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_MYPROFILE_START,
    };
};

export const updatePersonMyProfileSuccess = (person) => {
    return {
        type: actionTypes.UPDATE_PERSON_MYPROFILE_SUCCESS,
        person,
    };
};

export const updatePersonMyProfileFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_MYPROFILE_FAIL,
        error: error,
    };
};

export const updatePersonMyProfile = (person) => {
    return (dispatch) => {
        dispatch(updatePersonMyProfileStart());

        return PersonAPI.updatePersonMyProfile(person)
            .then((res) => {
                if (res.id) {
                    dispatch(updatePersonMyProfileSuccess(res));
                } else {
                    dispatch(updatePersonMyProfileFail(res));
                }
            })
            .catch((err) => {
                dispatch(updatePersonMyProfileFail(err));
            });
    };
};

export const downloadPersonProfilePictureStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_START,
    };
};

export const downloadPersonProfilePictureSuccess = (profilePicture) => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_SUCCESS,
        profilePicture: profilePicture,
    };
};

export const downloadPersonProfilePictureFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PERSON_PROFILE_PICTURE_FAIL,
        error: error,
    };
};

export const downloadPersonProfilePicture = () => {
    return (dispatch) => {
        dispatch(downloadPersonProfilePictureStart());

        return PersonAPI.downloadPersonProfilePicture()
            .then((res) => {
                if (res) {
                    dispatch(downloadPersonProfilePictureSuccess(res));
                } else {
                    dispatch(downloadPersonProfilePictureFail(res));
                }
            })
            .catch((err) => {
                dispatch(downloadPersonProfilePictureFail(err));
            });
    };
};

export const downloadManagedPersonProfilePicture = (email) => {
    return (dispatch) => {
        dispatch(downloadPersonProfilePictureStart());

        return PersonAPI.downloadManagedPersonProfilePicture(email)
            .then((res) => {
                if (res) {
                    dispatch(downloadPersonProfilePictureSuccess(res));
                } else {
                    dispatch(downloadPersonProfilePictureFail(res));
                }
            })
            .catch((err) => {
                dispatch(downloadPersonProfilePictureFail(err));
            });
    };
};

export const uploadPersonProfilePictureStart = () => {
    return {
        type: actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_START,
    };
};

export const uploadPersonProfilePictureSuccess = (profilePictureUrl) => {
    return {
        type: actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_SUCCESS,
        profilePictureUrl: profilePictureUrl.data,
    };
};

export const uploadPersonProfilePictureFail = (error) => {
    return {
        type: actionTypes.UPLOAD_PERSON_PROFILE_PICTURE_FAIL,
        error: error,
    };
};

export const uploadPersonProfilePicture = (profilePicture) => {
    return (dispatch) => {
        dispatch(uploadPersonProfilePictureStart());

        return PersonAPI.uploadPersonProfilePicture(profilePicture)
            .then((res) => {
                if (res) {
                    dispatch(uploadPersonProfilePictureSuccess(res));
                } else {
                    dispatch(uploadPersonProfilePictureFail(res));
                }
            })
            .catch((err) => {
                dispatch(uploadPersonProfilePictureFail(err));
            });
    };
};

export const getPersonLatestAccountStart = () => {
    return {
        type: actionTypes.GET_PERSON_LATESTACCOUNT_START,
    };
};

export const getPersonLatestAccountSuccess = (account) => {
    return {
        type: actionTypes.GET_PERSON_LATESTACCOUNT_SUCCESS,
        account,
    };
};

export const getPersonLatestAccountFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_LATESTACCOUNT_FAIL,
        error: error,
    };
};

export const getPersonLatestAccount = () => {
    return (dispatch) => {
        dispatch(getPersonLatestAccountStart());

        return PersonAPI.getPersonLatestAccount()
            .then((res) => {
                dispatch(getPersonLatestAccountSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonLatestAccountFail(err));
            });
    };
};

export const getManagedPeopleLatestAccountStart = () => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_START,
    };
};

export const getManagedPeopleLatestAccountSuccess = (account) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_SUCCESS,
        account,
    };
};

export const getManagedPeopleLatestAccountFail = (error) => {
    return {
        type: actionTypes.GET_MANAGED_PEOPLE_LATESTACCOUNT_FAIL,
        error: error,
    };
};

export const getManagedPeopleLatestAccount = (email) => {
    return (dispatch) => {
        dispatch(getManagedPeopleLatestAccountStart());

        return PersonAPI.getManagedPeopleLatestAccount(email)
            .then((res) => {
                dispatch(getManagedPeopleLatestAccountSuccess(res));
            })
            .catch((err) => {
                dispatch(getManagedPeopleLatestAccountFail(err));
            });
    };
};

export const clearPersonAccountState = () => {
    return {
        type: actionTypes.CLEAR_PERSON_ACCOUNT_STATE,
    };
};

export const updatePersonAccountStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_START,
    };
};

export const updatePersonAccountSuccess = (attributes) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_SUCCESS,
        attributes,
    };
};

export const updatePersonAccountFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_FAIL,
        error: error,
    };
};

export const updatePersonAccount = (accountId, accountRequest) => {
    return (dispatch) => {
        dispatch(updatePersonAccountStart());

        return PersonAPI.updatePersonAccount(accountId, accountRequest)
            .then((res) => {
                dispatch(updatePersonAccountSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonAccountFail(err));
            });
    };
};

export const updatePersonAccountNoteStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_START,
    };
};

export const updatePersonAccountNoteSuccess = (account) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_SUCCESS,
        account,
    };
};

export const updatePersonAccountNoteFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACCOUNT_NOTE_FAIL,
        error: error,
    };
};

export const updatePersonAccountNote = (accountId, note) => {
    return (dispatch) => {
        dispatch(updatePersonAccountNoteStart());

        return PersonAPI.updatePersonAccountNote(accountId, note)
            .then((res) => {
                dispatch(updatePersonAccountNoteSuccess(res));
            })
            .catch((err) => {
                dispatch(updatePersonAccountNoteFail(err));
            });
    };
};

export const updateManagedPeoplePersonAccountNoteStart = () => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_START,
    };
};

export const updateManagedPeoplePersonAccountNoteSuccess = (account) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_SUCCESS,
        account,
    };
};

export const updateManagedPeoplePersonAccountNoteFail = (error) => {
    return {
        type: actionTypes.UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_FAIL,
        error: error,
    };
};

export const updateManagedPeoplePersonAccountNote = (accountId, note, email) => {
    return (dispatch) => {
        dispatch(updateManagedPeoplePersonAccountNoteStart());

        return PersonAPI.updateManagedPeoplePersonAccountNote(accountId, note, email)
            .then((res) => {
                dispatch(updateManagedPeoplePersonAccountNoteSuccess(res));
            })
            .catch((err) => {
                dispatch(updateManagedPeoplePersonAccountNoteFail(err));
            });
    };
};

export const personAccountRequestApprovalStart = () => {
    return {
        type: actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_START,
    };
};

export const personAccountRequestApprovalSuccess = (account) => {
    return {
        type: actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_SUCCESS,
        account,
    };
};

export const personAccountRequestApprovalFail = (error) => {
    return {
        type: actionTypes.PERSON_ACCOUNT_REQUEST_APPROVAL_FAIL,
        error: error,
    };
};

export const personAccountRequestApproval = (accountId, comments) => {
    return (dispatch) => {
        dispatch(personAccountRequestApprovalStart());

        return PersonAPI.personAccountRequestApproval(accountId, comments)
            .then((res) => {
                dispatch(personAccountRequestApprovalSuccess(res));
            })
            .catch((err) => {
                dispatch(personAccountRequestApprovalFail(err));
            });
    };
};

export const personAccountShowPostponeComment = (accountId) => {
    return () => {
        PersonAPI.personAccountShowPostponeComment(accountId);
    };
};

export const getPersonMyPeoplesStart = () => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_START,
    };
};

export const getPersonMyPeoplesSuccess = (myPeoples) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_SUCCESS,
        myPeoples,
    };
};

export const getPersonMyPeoplesFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_FAIL,
        error: error,
    };
};

export const getPersonMyPeoples = () => {
    return (dispatch) => {
        dispatch(getPersonMyPeoplesStart());

        return PersonAPI.getPersonMyPeoples()
            .then((res) => {
                dispatch(getPersonMyPeoplesSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonMyPeoplesFail(err));
            });
    };
};

export const getPersonMyPeopleByEmailStart = () => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_START,
    };
};

export const getPersonMyPeopleByEmailSuccess = (managedPeople) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_SUCCESS,
        managedPeople,
    };
};

export const getPersonMyPeopleByEmailFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLE_BYEMAIL_FAIL,
        error: error,
    };
};

export const getPersonMyPeopleByEmail = (email) => {
    return (dispatch) => {
        dispatch(getPersonMyPeopleByEmailStart());

        return PersonAPI.getPersonMyPeopleByEmail(email)
            .then((res) => {
                dispatch(getPersonMyPeopleByEmailSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonMyPeopleByEmailFail(err));
            });
    };
};

export const approveMyPeopleProfileStart = () => {
    return {
        type: actionTypes.APPROVE_MYPEOPLE_PROFILE_START,
    };
};

export const approveMyPeopleProfileSuccess = () => {
    return {
        type: actionTypes.APPROVE_MYPEOPLE_PROFILE_SUCCESS,
    };
};

export const approveMyPeopleProfileFail = (error) => {
    return {
        type: actionTypes.APPROVE_MYPEOPLE_PROFILE_FAIL,
        error: error,
    };
};

export const approveMyPeopleProfile = (approval) => {
    return (dispatch) => {
        dispatch(approveMyPeopleProfileStart());

        return PersonAPI.approveMyPeopleProfile(approval)
            .then((res) => {
                dispatch(approveMyPeopleProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(approveMyPeopleProfileFail(err));
            });
    };
};

export const postponeApproveMyPeopleProfileStart = () => {
    return {
        type: actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_START,
    };
};

export const postponeApproveMyPeopleProfileSuccess = () => {
    return {
        type: actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_SUCCESS,
    };
};

export const postponeApproveMyPeopleProfileFail = (error) => {
    return {
        type: actionTypes.POSTPONE_APPROVE_MYPEOPLE_PROFILE_FAIL,
        error: error,
    };
};

export const postponeApproveMyPeopleProfile = (approval) => {
    return (dispatch) => {
        dispatch(postponeApproveMyPeopleProfileStart());

        return PersonAPI.postponeApproveMyPeopleProfile(approval)
            .then((res) => {
                dispatch(postponeApproveMyPeopleProfileSuccess(res));
            })
            .catch((err) => {
                dispatch(postponeApproveMyPeopleProfileFail(err));
            });
    };
};

export const forgetMeStart = () => {
    return {
        type: actionTypes.PERSON_FORGET_ME_START,
    };
};

export const forgetMeSuccess = () => {
    return {
        type: actionTypes.PERSON_FORGET_ME_SUCCESS,
    };
};

export const forgetMeFail = (error) => {
    return {
        type: actionTypes.PERSON_FORGET_ME_FAIL,
        error: error,
    };
};

export const forgetMe = () => {
    return (dispatch) => {
        dispatch(forgetMeStart());

        return PersonAPI.forgetMe()
            .then((res) => {
                dispatch(forgetMeSuccess(res));
            })
            .catch((err) => {
                dispatch(forgetMeFail(err));
            });
    };
};

export const updatePersonActivityStatementIntervalStart = () => {
    return {
        type: actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_START,
    };
};

export const updatePersonActivityStatementIntervalSuccess = (activityStatementInterval) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_SUCCESS,
        activityStatementInterval,
    };
};

export const updatePersonActivityStatementIntervalFail = (error) => {
    return {
        type: actionTypes.UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_FAIL,
        error: error,
    };
};

export const updatePersonActivityStatementInterval = (activityStatementInterval) => {
    return (dispatch) => {
        dispatch(updatePersonActivityStatementIntervalStart());

        return PersonAPI.updatePersonActivityStatementInterval(activityStatementInterval)
            .then((res) => {
                dispatch(updatePersonActivityStatementIntervalSuccess(activityStatementInterval));
            })
            .catch((err) => {
                dispatch(updatePersonActivityStatementIntervalFail(err));
            });
    };
};

export const generateActivityStatementStart = () => {
    return {
        type: actionTypes.GENERATE_ACTIVITYSTATEMENT_START,
    };
};

export const generateActivityStatementSuccess = () => {
    return {
        type: actionTypes.GENERATE_ACTIVITYSTATEMENT_SUCCESS,
    };
};

export const generateActivityStatementFail = (error) => {
    return {
        type: actionTypes.GENERATE_ACTIVITYSTATEMENT_FAIL,
        error: error,
    };
};

export const generateActivityStatement = () => {
    return (dispatch) => {
        dispatch(generateActivityStatementStart());

        return PersonAPI.generateActivityStatement()
            .then((res) => {
                dispatch(generateActivityStatementSuccess());
            })
            .catch((err) => {
                dispatch(generateActivityStatementFail(err));
            });
    };
};

export const getPersonMyPeoplesTeamAnalitycStart = () => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_START,
    };
};

export const getPersonMyPeoplesTeamAnalitycSuccess = (myPeopleTeamAnalytic) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_SUCCESS,
        myPeopleTeamAnalytic,
    };
};

export const getPersonMyPeoplesTeamAnalitycFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_MYPEOPLES_TEAMANALYTIC_FAIL,
        error: error,
    };
};

export const getPersonMyPeoplesTeamAnalytic = () => {
    return (dispatch) => {
        dispatch(getPersonMyPeoplesTeamAnalitycStart());

        return PersonAPI.getPersonMyPeoplesTeamAnalytic()
            .then((res) => {
                dispatch(getPersonMyPeoplesTeamAnalitycSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonMyPeoplesTeamAnalitycFail(err));
            });
    };
};

export const clearManagePeopleEndorsedStatus = () => {
    return {
        type: actionTypes.CLEAR_MANAGE_PEOPLE_ENDORSED_STATUS,
    };
};

export const updateManagePeopleEndorsedStatus = (status) => {
    return {
        type: actionTypes.UPDATE_MANAGE_PEOPLE_ENDORSED_STATUS,
        status,
    };
};

export const getPersonSkillCoinsStart = () => {
    return {
        type: actionTypes.GET_PERSON_SKILL_COINS_START,
    };
};

export const getPersonSkillCoinsSuccess = (skillCoins) => {
    return {
        type: actionTypes.GET_PERSON_SKILL_COINS_SUCCESS,
        skillCoins,
    };
};

export const getPersonSkillCoinsFail = (error) => {
    return {
        type: actionTypes.GET_PERSON_SKILL_COINS_FAIL,
        error: error,
    };
};

export const getPersonSkillCoins = () => {
    return (dispatch) => {
        dispatch(getPersonSkillCoinsStart());

        return PersonAPI.getPersonSkillCoins()
            .then((res) => {
                dispatch(getPersonSkillCoinsSuccess(res));
            })
            .catch((err) => {
                dispatch(getPersonSkillCoinsFail(err));
            });
    };
};

export const getColumnDisplaySettingsStart = () => {
    return {
        type: actionTypes.GET_COLUMN_DISPLAY_SETTINGS_START,
    };
};

export const getColumnDisplaySettingsSuccess = (columnDisplaySettings) => {
    return {
        type: actionTypes.GET_COLUMN_DISPLAY_SETTINGS_SUCCESS,
        columnDisplaySettings,
    };
};

export const getColumnDisplaySettingsFail = (error) => {
    return {
        type: actionTypes.GET_COLUMN_DISPLAY_SETTINGS_FAIL,
        error: error,
    };
};

export const getColumnDisplaySettings = (pageName) => {
    return (dispatch) => {
        dispatch(getColumnDisplaySettingsStart());

        return PersonAPI.getColumnDisplaySettings(pageName)
            .then((res) => {
                dispatch(getColumnDisplaySettingsSuccess(res));
            })
            .catch((err) => {
                dispatch(getColumnDisplaySettingsFail(err));
            });
    };
};

export const updateColumnDisplaySettingStart = () => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_START,
    };
};

export const updateColumnDisplaySettingSuccess = (updatedColumnDisplaySetting) => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_SUCCESS,
        updatedColumnDisplaySetting,
    };
};

export const updateColumnDisplaySettingFail = (id, error) => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_FAIL,
        error: error,
    };
};

export const updateColumnDisplaySetting = (columnDisplaySetting) => {
    return (dispatch) => {
        dispatch(updateColumnDisplaySettingStart());

        return PersonAPI.updateColumnDisplaySetting(columnDisplaySetting)
            .then((res) => {
                dispatch(updateColumnDisplaySettingSuccess(res));
            })
            .catch((err) => {
                dispatch(updateColumnDisplaySettingFail(columnDisplaySetting.id, err));
            });
    };
};

export const updateColumnDisplaySettingOrderStart = () => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_START,
    };
};

export const updateColumnDisplaySettingOrderSuccess = (updatedColumnDisplaySettings) => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_SUCCESS,
        updatedColumnDisplaySettings,
    };
};

export const updateColumnDisplaySettingOrderFail = (error) => {
    return {
        type: actionTypes.UPDATE_COLUMN_DISPLAY_SETTING_MOVE_FAIL,
        error: error,
    };
};

export const updateColumnDisplaySettingOrderUp = (id) => {
    return (dispatch) => {
        dispatch(updateColumnDisplaySettingOrderStart());

        return PersonAPI.updateColumnDisplaySettingMoveUp(id)
            .then((res) => {
                dispatch(updateColumnDisplaySettingOrderSuccess(res));
            })
            .catch((err) => {
                dispatch(updateColumnDisplaySettingOrderFail(err));
            });
    };
};

export const updateColumnDisplaySettingOrderDown = (id) => {
    return (dispatch) => {
        dispatch(updateColumnDisplaySettingOrderStart());

        return PersonAPI.updateColumnDisplaySettingMoveDown(id)
            .then((res) => {
                dispatch(updateColumnDisplaySettingOrderSuccess(res));
            })
            .catch((err) => {
                dispatch(updateColumnDisplaySettingOrderFail(err));
            });
    };
};
