import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    job: null,
    jobs: [],
    jobFamilies: [],
    sampleJobs: [],
    jobMatchTypes: [],
    jobMatchesLoading: false,
    jobMatches: [],
    jobMatchesUpdated: false,
    error: null,
    loading: false,
    jobLorProfiles: [],
    jobSkillProfiles: [],
    jobSkillAttributes: [],
    profileVersions: [],

    profileUpdating: false,
    profileUpdatingSuccess: false,
    profileUpdatingError: null,
    udpatedSkills: null,

    isUpdating: false,

    jobMatchPersons: [],
    jobImportSessionId: null,

    exportData: null,
    loadingExport: false,
    isImporting: false,

    jobImportQueue: [],
    addedJobImports: [],

    loadingPrint: false,
    printData: null,
};

const getJobsStart = (state) => {
    return updateState(state, { error: null, jobs: [], loading: true });
};

const getJobsSuccess = (state, action) => {
    return updateState(state, {
        jobs: action.jobs,
        error: null,
        loading: false,
    });
};

const getJobsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getSampleJobsStart = (state) => {
    return updateState(state, { error: null, sampleJobs: [], loading: true });
};

const getSampleJobsSuccess = (state, action) => {
    return updateState(state, {
        sampleJobs: action.sampleJobs,
        error: null,
        loading: false,
    });
};

const getSampleJobsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getJobStart = (state) => {
    return updateState(state, { error: null, job: null, loading: true });
};

const getJobSuccess = (state, action) => {
    return updateState(state, {
        job: action.job,
        error: null,
        loading: false,
    });
};

const getJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const printJobReportStart = (state) => {
    return updateState(state, { error: null, printData: null, loadingPrint: true });
};

const printJobReportSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const printJobReportFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const printJobReportsStart = (state) => {
    return updateState(state, { error: null, printData: null, loadingPrint: true });
};

const printJobReportsSuccess = (state, action) => {
    return updateState(state, {
        printData: action.printData,
        error: null,
        loadingPrint: false,
    });
};

const printJobReportsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const createJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const createJobSuccess = (state, action) => {
    const addedJob = action.addedJob;

    return updateState(state, {
        error: null,
        isUpdating: false,
        job: addedJob,
    });
};

const createJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateJobSuccess = (state, action) => {
    let jobs = state.jobs;
    const updatedJob = jobs.find((x) => x.id == action.updatedJob.id);
    if (updatedJob) {
        updatedJob.name = action.updatedJob.name;
        updatedJob.description = action.updatedJob.description;
        updatedJob.reference = action.updatedJob.reference;
        updatedJob.sequence = action.updatedJob.sequence;
        updatedJob.isRole = action.updatedJob.isRole;
        updatedJob.createdOn = action.updatedJob.createdOn;
        updatedJob.defineById = action.updatedJob.defineById;
        updatedJob.defineBy = action.updatedJob.defineBy;
        updatedJob.reportingTo = action.updatedJob.reportingTo;
        updatedJob.seniority = action.updatedJob.seniority;
        updatedJob.employmentStatus = action.updatedJob.employmentStatus;
        updatedJob.department = action.updatedJob.department;
        updatedJob.location = action.updatedJob.location;
        updatedJob.purpose = action.updatedJob.purpose;
        updatedJob.keyAccountibilities = action.updatedJob.keyAccountibilities;
        updatedJob.keyProcesses = action.updatedJob.keyProcesses;
        updatedJob.educationRequirements = action.updatedJob.educationRequirements;
        updatedJob.qualificationRequirements = action.updatedJob.qualificationRequirements;
        updatedJob.otherRequirements = action.updatedJob.otherRequirements;
        updatedJob.comment = action.updatedJob.comment;
        updatedJob.isHidden = action.updatedJob.isHidden;
        updatedJob.isCandidateJob = action.updatedJob.isCandidateJob;
        updatedJob.inActive = action.updatedJob.inActive;
        updatedJob.isSample = action.updatedJob.isSample;
        updatedJob.ownerId = action.updatedJob.ownerId;
        updatedJob.owner = action.updatedJob.owner;
        updatedJob.fte = action.updatedJob.fte;
        updatedJob.language = action.updatedJob.language;
        updatedJob.languageId = action.updatedJob.languageId;
        updatedJob.updatedOn = action.updatedJob.updatedOn;
    }

    return updateState(state, {
        jobs: [...jobs],
        error: null,
        isUpdating: false,
    });
};

const updateJobByNotification = (state, action) => {
    let jobs = state.jobs;
    const updatedJob = jobs.find((x) => x.id == action.updatedJob.id);
    if (updatedJob) {
        updatedJob.name = action.updatedJob.name;
        updatedJob.description = action.updatedJob.description;
        updatedJob.reference = action.updatedJob.reference;
        updatedJob.sequence = action.updatedJob.sequence;
        updatedJob.isRole = action.updatedJob.isRole;
        updatedJob.createdOn = action.updatedJob.createdOn;
        updatedJob.defineById = action.updatedJob.defineById;
        updatedJob.defineBy = action.updatedJob.defineBy;
        updatedJob.reportingTo = action.updatedJob.reportingTo;
        updatedJob.seniority = action.updatedJob.seniority;
        updatedJob.employmentStatus = action.updatedJob.employmentStatus;
        updatedJob.department = action.updatedJob.department;
        updatedJob.location = action.updatedJob.location;
        updatedJob.purpose = action.updatedJob.purpose;
        updatedJob.keyAccountibilities = action.updatedJob.keyAccountibilities;
        updatedJob.keyProcesses = action.updatedJob.keyProcesses;
        updatedJob.educationRequirements = action.updatedJob.educationRequirements;
        updatedJob.qualificationRequirements = action.updatedJob.qualificationRequirements;
        updatedJob.otherRequirements = action.updatedJob.otherRequirements;
        updatedJob.comment = action.updatedJob.comment;
        updatedJob.isHidden = action.updatedJob.isHidden;
        updatedJob.isCandidateJob = action.updatedJob.isCandidateJob;
        updatedJob.inActive = action.updatedJob.inActive;
        updatedJob.isSample = action.updatedJob.isSample;
        updatedJob.ownerId = action.updatedJob.ownerId;
        updatedJob.owner = action.updatedJob.owner;
        updatedJob.fte = action.updatedJob.fte;
        updatedJob.language = action.updatedJob.language;
        updatedJob.languageId = action.updatedJob.languageId;
        updatedJob.updatedOn = action.updatedJob.updatedOn;
    }

    return updateState(state, {
        jobs: [...jobs],
    });
};

const updateJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateJobNoteStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateJobNoteSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
    });
};

const updateJobNoteFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteJobSuccess = (state, action) => {
    let jobs = state.jobs;
    const deletedJob = jobs.find((it) => it.id == action.id);

    if (deletedJob) {
        const index = jobs.indexOf(deletedJob);
        if (index !== -1) {
            jobs.splice(index, 1);
        }
    }

    return updateState(state, {
        jobs: [...jobs],
        error: null,
        isUpdating: false,
    });
};

const deleteJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const copyJobStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const copyJobSuccess = (state, action) => {
    const addedJob = action.addedJob;

    return updateState(state, {
        error: null,
        isUpdating: false,
        job: addedJob,
        jobs: [...state.jobs, addedJob],
    });
};

const copyJobFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const copyJobsStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const copyJobsSuccess = (state, action) => {
    const addedJobs = action.addedJobs;

    return updateState(state, {
        error: null,
        isUpdating: false,
        jobs: [...state.jobs, ...addedJobs],
    });
};

const copyJobsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const updateJobSkillAttributesStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateJobSkillAttributesSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
        jobSkillAttributes: action.jobSkillAttributes,
    });
};

const updateJobSkillAttributesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getMyJobMatchTypesStart = (state) => {
    return updateState(state, { error: null, jobMatchTypes: [], loading: true });
};

const getMyJobMatchTypesSuccess = (state, action) => {
    return updateState(state, {
        jobMatchTypes: action.jobMatchTypes,
        error: null,
        loading: false,
    });
};

const getMyJobMatchTypesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getJobMatchesStart = (state) => {
    return updateState(state, { error: null, lors: [], jobMatchesLoading: true });
};

const getJobMatchesSuccess = (state, action) => {
    return updateState(state, {
        jobMatches: action.jobMatches,
        error: null,
        jobMatchesLoading: false,
    });
};

const getJobMatchesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        jobMatchesLoading: false,
    });
};

const updateJobMatchesStart = (state) => {
    return updateState(state, { error: null, isUpdating: true, jobMatchesUpdated: false });
};

const updateJobMatchesSuccess = (state, action) => {
    return updateState(state, {
        jobMatches: action.jobMatches,
        error: null,
        isUpdating: false,
        jobMatchesUpdated: true,
    });
};

const updateJobMatchesFail = (state, action) => {
    return updateState(state, {
        jobMatches: [],
        jobMatchesUpdated: false,
        error: action.error,
        isUpdating: false,
    });
};

const getJobProfileVersionsStart = (state) => {
    return updateState(state, { error: null, profileVersions: [], loading: true });
};

const getJobProfileVersionsSuccess = (state, action) => {
    return updateState(state, {
        profileVersions: action.profileVersions,
        error: null,
        loading: false,
    });
};

const getJobProfileVersionsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getJobLorProfilesStart = (state) => {
    return updateState(state, { error: null, jobLorProfiles: [], loading: true });
};

const getJobLorProfilesSuccess = (state, action) => {
    return updateState(state, {
        jobLorProfiles: action.jobLorProfiles,
        error: null,
        loading: false,
    });
};

const getJobLorProfilesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getJobSkillProfilesStart = (state) => {
    return updateState(state, { error: null, jobSkillProfiles: [], loading: true });
};

const getJobSkillProfilesSuccess = (state, action) => {
    return updateState(state, {
        jobSkillProfiles: action.jobSkillProfiles,
        error: null,
        loading: false,
    });
};

const getJobSkillProfilesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateJobSkillProfilesStart = (state) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: true, profileUpdatingSuccess: false, udpatedSkills: null });
};

const updateJobSkillProfilesSuccess = (state, action) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: false, profileUpdatingSuccess: true, udpatedSkills: action.udpatedSkills });
};

const updateJobSkillProfilesFail = (state, action) => {
    return updateState(state, { profileUpdatingError: action.error, profileUpdating: false });
};

const clearUpdateJobSkillProfiles = (state) => {
    return updateState(state, { profileUpdatingError: null, profileUpdating: false, profileUpdatingSuccess: false, udpatedSkills: null });
};

const getJobSkillAttributesStart = (state) => {
    return updateState(state, { error: null, jobSkillAttributes: [], loading: true });
};

const getJobSkillAttributesSuccess = (state, action) => {
    return updateState(state, {
        jobSkillAttributes: action.jobSkillAttributes,
        error: null,
        loading: false,
    });
};

const getJobSkillAttributesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const setJobImportSessionId = (state, action) => {
    return updateState(state, {
        jobImportSessionId: action.jobImportSessionId,
    });
};

const exportJobsDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportJobsDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportJobsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importJobsDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, addedJobImports: [] });
};

const importJobsDataExcelSuccess = (state, action) => {
    let jobImportQueue = state.jobImportQueue;
    const addedJobImportQueue = action.addedJobImportQueue;

    return updateState(state, {
        error: null,
        isImporting: false,
        jobImportQueue: [...jobImportQueue, ...addedJobImportQueue],
        addedJobImports: addedJobImportQueue,
    });
};

const importJobsDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const getJobImportQueueStart = (state) => {
    return updateState(state, { error: null, jobImportQueue: [], loading: true });
};

const getJobImportQueueSuccess = (state, action) => {
    return updateState(state, {
        jobImportQueue: action.jobImportQueue,
        error: null,
        loading: false,
    });
};

const getJobImportQueueFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateImportJobStatus = (state, action) => {
    const updatedImportJobRequest = action.updatedImportJobRequest;
    const importedJobs = action.updatedJobs;

    let jobs = state.jobs;
    const jobImportQueue = state.jobImportQueue;

    if (jobImportQueue.find((it) => it.id == updatedImportJobRequest.id)) {
        const updatedJobImport = jobImportQueue.find((it) => it.id == updatedImportJobRequest.id);
        updatedJobImport.status = updatedImportJobRequest.status;
        updatedJobImport.statusMessage = updatedImportJobRequest.statusMessage;
        updatedJobImport.message = updatedImportJobRequest.message;
    }

    jobs.filter((x) => importedJobs.find((y) => y.id == x.id)).forEach((updatedJob) => {
        const importedJob = importedJobs.find((y) => y.id == updatedJob.id);
        if (importedJob) {
            updatedJob.name = importedJob.name;
            updatedJob.description = importedJob.description;
            updatedJob.reference = importedJob.reference;
            updatedJob.isRole = importedJob.isRole;
            updatedJob.reportingTo = importedJob.reportingTo;
            updatedJob.employmentStatus = importedJob.employmentStatus;
            updatedJob.department = importedJob.department;
            updatedJob.location = importedJob.location;
            updatedJob.purpose = importedJob.purpose;
            updatedJob.keyAccountibilities = importedJob.keyAccountibilities;
            updatedJob.keyProcesses = importedJob.keyProcesses;
            updatedJob.educationRequirements = importedJob.educationRequirements;
            updatedJob.qualificationRequirements = importedJob.qualificationRequirements;
            updatedJob.otherRequirements = importedJob.otherRequirements;
        }
    });

    const addedJobs = importedJobs.filter((x) => !jobs.find((y) => x.id == y.id));

    return updateState(state, {
        jobImportQueue,
        jobs: [...jobs, ...addedJobs],
    });
};

const getJobMatchPersonsStart = (state) => {
    return updateState(state, { error: null, jobMatchPersons: [], loading: true });
};

const getJobMatchPersonsSuccess = (state, action) => {
    return updateState(state, {
        jobMatchPersons: action.jobMatchPersons,
        error: null,
        loading: false,
    });
};

const getJobMatchPersonsFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const assignJobMatchPersonStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const assignJobMatchPersonSuccess = (state, action) => {
    const updatedJobMatch = action.updatedJobMatch;
    const jobMatchPersons = state.jobMatchPersons;

    const updatedJobMatchPerson = jobMatchPersons.find((it) => it.personId == updatedJobMatch.personId);
    if (updatedJobMatchPerson) {
        updatedJobMatchPerson.currentJobMatchId = updatedJobMatch.currentJobMatchId;
        updatedJobMatchPerson.currentJobMatch = updatedJobMatch.currentJobMatch;
        updatedJobMatchPerson.currentJobReference = updatedJobMatch.currentJobReference;
        updatedJobMatchPerson.currentJobMatchPercentSelf = updatedJobMatch.currentJobMatchPercentSelf;
        updatedJobMatchPerson.currentJobMatchUpdatedOn = updatedJobMatch.currentJobMatchUpdatedOn;

        updatedJobMatchPerson.futureJobMatchId = updatedJobMatch.futureJobMatchId;
        updatedJobMatchPerson.futureJobMatch = updatedJobMatch.futureJobMatch;
        updatedJobMatchPerson.futureJobReference = updatedJobMatch.futureJobReference;
        updatedJobMatchPerson.futureJobMatchPercentSelf = updatedJobMatch.futureJobMatchPercentSelf;
        updatedJobMatchPerson.futureJobMatchUpdatedOn = updatedJobMatch.futureJobMatchUpdatedOn;

        updatedJobMatchPerson.proposedJobMatchId = updatedJobMatch.proposedJobMatchId;
        updatedJobMatchPerson.proposedJobMatch = updatedJobMatch.proposedJobMatch;
        updatedJobMatchPerson.proposedJobReference = updatedJobMatch.proposedJobReference;
        updatedJobMatchPerson.proposedJobMatchPercentSelf = updatedJobMatch.proposedJobMatchPercentSelf;
        updatedJobMatchPerson.proposedJobMatchUpdatedOn = updatedJobMatch.proposedJobMatchUpdatedOn;

        updatedJobMatchPerson.lastUpdatedOn = updatedJobMatch.lastUpdatedOn;
        updatedJobMatchPerson.lastUpdatedOn1 = updatedJobMatch.lastUpdatedOn1;
        updatedJobMatchPerson.lastUpdatedOn2 = updatedJobMatch.lastUpdatedOn2;
        updatedJobMatchPerson.lastUpdatedOn3 = updatedJobMatch.lastUpdatedOn3;
    }

    return updateState(state, {
        jobMatchPersons: [...jobMatchPersons],
        error: null,
        isUpdating: false,
    });
};

const assignJobMatchPersonFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const exportJobMatchesDataExcelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const exportJobMatchesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        exportData: action.exportData,
        error: null,
        loadingExport: false,
    });
};

const exportJobMatchesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const importJobMatchesDataExcelStart = (state) => {
    return updateState(state, { error: null, isImporting: true, message: null });
};

const importJobMatchesDataExcelSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isImporting: false,
    });
};

const importJobMatchesDataExcelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isImporting: false,
    });
};

const getJobFamiliesStart = (state) => {
    return updateState(state, { error: null, jobFamilies: [], loading: true });
};

const getJobFamiliesSuccess = (state, action) => {
    return updateState(state, {
        jobFamilies: action.jobFamilies,
        error: null,
        loading: false,
    });
};

const getJobFamiliesFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateJobFamilyStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateJobFamilySuccess = (state, action) => {
    const jobFamily = action.jobFamily;
    const jobs = state.jobs;

    const deletedJobIds = action.deletedJobIds;

    const jobFamilies = state.jobFamilies;

    const oriJobFamily = jobFamilies.find((it) => it.id == jobFamily.id);
    if (oriJobFamily) {
        oriJobFamily.name = jobFamily.name;
        oriJobFamily.entries = jobFamily.entries;
    } else {
        jobFamilies.push(jobFamily);
    }

    if (jobFamily.entries && jobFamily.entries.length > 0) {
        jobFamily.entries.forEach((entry) => {
            const job = jobs.find((j) => j.id == entry.jobId);
            if (job) {
                job.jobFamilyId = jobFamily.id;
                job.jobFamily = jobFamily.name;
            }
        });
    }

    if (deletedJobIds && deletedJobIds.length > 0) {
        deletedJobIds.forEach((deletedJobId) => {
            const job = jobs.find((j) => j.id == deletedJobId);
            if (job) {
                job.jobFamilyId = null;
                job.jobFamily = null;
            }
        });
    }

    return updateState(state, {
        jobFamilies: [...jobFamilies],
        jobs: [...jobs],
        error: null,
        isUpdating: false,
    });
};

const updateJobFamilyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const deleteJobFamilyStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const deleteJobFamilySuccess = (state, action) => {
    const deletedJobFamilyId = action.deletedJobFamilyId;
    const jobs = state.jobs;
    const jobFamilies = state.jobFamilies;

    const oriJobFamily = jobFamilies.find((it) => it.id == deletedJobFamilyId);
    if (oriJobFamily) {
        var index = jobFamilies.indexOf(oriJobFamily);
        if (index !== -1) {
            jobFamilies.splice(index, 1);
        }

        const deletedJobs = jobs.filter((j) => j.jobFamilyId == deletedJobFamilyId);
        deletedJobs.forEach((job) => {
            job.jobFamilyId = null;
            job.jobFamily = null;
        });
    }

    return updateState(state, {
        jobFamilies: [...jobFamilies],
        jobs: [...jobs],
        error: null,
        isUpdating: false,
    });
};

const deleteJobFamilyFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_JOBS_START:
            return getJobsStart(state);
        case actionTypes.GET_JOBS_SUCCESS:
            return getJobsSuccess(state, action);
        case actionTypes.GET_JOBS_FAIL:
            return getJobsFail(state, action);

        case actionTypes.GET_SAMPLE_JOBS_START:
            return getSampleJobsStart(state);
        case actionTypes.GET_SAMPLE_JOBS_SUCCESS:
            return getSampleJobsSuccess(state, action);
        case actionTypes.GET_SAMPLE_JOBS_FAIL:
            return getSampleJobsFail(state, action);

        case actionTypes.GET_JOB_START:
            return getJobStart(state);
        case actionTypes.GET_JOB_SUCCESS:
            return getJobSuccess(state, action);
        case actionTypes.GET_JOB_FAIL:
            return getJobFail(state, action);

        case actionTypes.PRINT_JOB_REPORT_START:
            return printJobReportStart(state);
        case actionTypes.PRINT_JOB_REPORT_SUCCESS:
            return printJobReportSuccess(state, action);
        case actionTypes.PRINT_JOB_REPORT_FAIL:
            return printJobReportFail(state, action);

        case actionTypes.PRINT_JOB_REPORTS_START:
            return printJobReportsStart(state);
        case actionTypes.PRINT_JOB_REPORTS_SUCCESS:
            return printJobReportsSuccess(state, action);
        case actionTypes.PRINT_JOB_REPORTS_FAIL:
            return printJobReportsFail(state, action);

        case actionTypes.CREATE_JOB_START:
            return createJobStart(state);
        case actionTypes.CREATE_JOB_SUCCESS:
            return createJobSuccess(state, action);
        case actionTypes.CREATE_JOB_FAIL:
            return createJobFail(state, action);

        case actionTypes.UPDATE_JOB_START:
            return updateJobStart(state);
        case actionTypes.UPDATE_JOB_SUCCESS:
            return updateJobSuccess(state, action);
        case actionTypes.UPDATE_JOB_BY_NOTIFICATION:
            return updateJobByNotification(state, action);
        case actionTypes.UPDATE_JOB_FAIL:
            return updateJobFail(state, action);

        case actionTypes.UPDATE_JOB_NOTE_START:
            return updateJobNoteStart(state);
        case actionTypes.UPDATE_JOB_NOTE_SUCCESS:
            return updateJobNoteSuccess(state, action);
        case actionTypes.UPDATE_JOB_NOTE_FAIL:
            return updateJobNoteFail(state, action);

        case actionTypes.DELETE_JOB_START:
            return deleteJobStart(state);
        case actionTypes.DELETE_JOB_SUCCESS:
            return deleteJobSuccess(state, action);
        case actionTypes.DELETE_JOB_FAIL:
            return deleteJobFail(state, action);

        case actionTypes.COPY_JOB_START:
            return copyJobStart(state);
        case actionTypes.COPY_JOB_SUCCESS:
            return copyJobSuccess(state, action);
        case actionTypes.COPY_JOB_FAIL:
            return copyJobFail(state, action);

        case actionTypes.COPY_JOBS_START:
            return copyJobsStart(state);
        case actionTypes.COPY_JOBS_SUCCESS:
            return copyJobsSuccess(state, action);
        case actionTypes.COPY_JOBS_FAIL:
            return copyJobsFail(state, action);

        case actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_START:
            return updateJobSkillAttributesStart(state);
        case actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_SUCCESS:
            return updateJobSkillAttributesSuccess(state, action);
        case actionTypes.UPDATE_JOB_SKILL_ATTRIBUTES_FAIL:
            return updateJobSkillAttributesFail(state, action);

        case actionTypes.GET_JOB_MATCH_PERSONS_START:
            return getJobMatchPersonsStart(state);
        case actionTypes.GET_JOB_MATCH_PERSONS_SUCCESS:
            return getJobMatchPersonsSuccess(state, action);
        case actionTypes.GET_JOB_MATCH_PERSONS_FAIL:
            return getJobMatchPersonsFail(state, action);

        case actionTypes.ASSIGN_JOB_MATCH_PERSON_START:
            return assignJobMatchPersonStart(state);
        case actionTypes.ASSIGN_JOB_MATCH_PERSON_SUCCESS:
            return assignJobMatchPersonSuccess(state, action);
        case actionTypes.ASSIGN_JOB_MATCH_PERSON_FAIL:
            return assignJobMatchPersonFail(state, action);

        case actionTypes.EXPORT_JOB_MATCHES_DATA_START:
            return exportJobMatchesDataExcelStart(state);
        case actionTypes.EXPORT_JOB_MATCHES_DATA_SUCCESS:
            return exportJobMatchesDataExcelSuccess(state, action);
        case actionTypes.EXPORT_JOB_MATCHES_DATA_FAIL:
            return exportJobMatchesDataExcelFail(state, action);

        case actionTypes.GET_MY_JOB_MATCH_TYPES_START:
            return getMyJobMatchTypesStart(state);
        case actionTypes.GET_MY_JOB_MATCH_TYPES_SUCCESS:
            return getMyJobMatchTypesSuccess(state, action);
        case actionTypes.GET_MY_JOB_MATCH_TYPES_FAIL:
            return getMyJobMatchTypesFail(state, action);

        case actionTypes.GET_MY_JOB_MATCHES_START:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_START:
            return getJobMatchesStart(state);
        case actionTypes.GET_MY_JOB_MATCHES_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS:
            return getJobMatchesSuccess(state, action);
        case actionTypes.GET_MY_JOB_MATCHES_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_MATCHES_FAIL:
            return getJobMatchesFail(state, action);

        case actionTypes.UPDATE_MY_JOB_MATCHES_START:
        case actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_START:
            return updateJobMatchesStart(state);
        case actionTypes.UPDATE_MY_JOB_MATCHES_SUCCESS:
        case actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS:
            return updateJobMatchesSuccess(state, action);
        case actionTypes.UPDATE_MY_JOB_MATCHES_FAIL:
        case actionTypes.UPDATE_MANAGED_PEOPLE_JOB_MATCHES_FAIL:
            return updateJobMatchesFail(state, action);

        case actionTypes.GET_JOB_PROFILES_VERSIONS_START:
            return getJobProfileVersionsStart(state);
        case actionTypes.GET_JOB_PROFILES_VERSIONS_SUCCESS:
            return getJobProfileVersionsSuccess(state, action);
        case actionTypes.GET_JOB_PROFILES_VERSIONS_FAIL:
            return getJobProfileVersionsFail(state, action);

        case actionTypes.GET_JOB_LOR_PROFILES_START:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_START:
            return getJobLorProfilesStart(state);
        case actionTypes.GET_JOB_LOR_PROFILES_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_SUCCESS:
            return getJobLorProfilesSuccess(state, action);
        case actionTypes.GET_JOB_LOR_PROFILES_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_FAIL:
            return getJobLorProfilesFail(state, action);
        case actionTypes.GET_JOB_SKILL_PROFILES_START:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_START:
            return getJobSkillProfilesStart(state);
        case actionTypes.GET_JOB_SKILL_PROFILES_SUCCESS:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_SUCCESS:
            return getJobSkillProfilesSuccess(state, action);
        case actionTypes.GET_JOB_SKILL_PROFILES_FAIL:
        case actionTypes.GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_FAIL:
            return getJobSkillProfilesFail(state, action);

        case actionTypes.UPDATE_JOB_SKILL_PROFILES_START:
            return updateJobSkillProfilesStart(state);
        case actionTypes.UPDATE_JOB_SKILL_PROFILES_SUCCESS:
            return updateJobSkillProfilesSuccess(state, action);
        case actionTypes.UPDATE_JOB_SKILL_PROFILES_FAIL:
            return updateJobSkillProfilesFail(state, action);
        case actionTypes.CLEAR_UPDATE_JOB_SKILL_PROFILES:
            return clearUpdateJobSkillProfiles(state);

        case actionTypes.GET_JOB_SKILL_ATTRIBUTES_START:
            return getJobSkillAttributesStart(state);
        case actionTypes.GET_JOB_SKILL_ATTRIBUTES_SUCCESS:
            return getJobSkillAttributesSuccess(state, action);
        case actionTypes.GET_JOB_SKILL_ATTRIBUTES_FAIL:
            return getJobSkillAttributesFail(state, action);

        case actionTypes.SET_JOBIMPORT_SESSIONID:
            return setJobImportSessionId(state, action);

        case actionTypes.EXPORT_JOBS_DATA_START:
            return exportJobsDataExcelStart(state);
        case actionTypes.EXPORT_JOBS_DATA_SUCCESS:
            return exportJobsDataExcelSuccess(state, action);
        case actionTypes.EXPORT_JOBS_DATA_FAIL:
            return exportJobsDataExcelFail(state, action);

        case actionTypes.IMPORT_JOBS_DATA_START:
            return importJobsDataExcelStart(state);
        case actionTypes.IMPORT_JOBS_DATA_SUCCESS:
            return importJobsDataExcelSuccess(state, action);
        case actionTypes.IMPORT_JOBS_DATA_FAIL:
            return importJobsDataExcelFail(state, action);

        case actionTypes.UPDATE_IMPORT_JOB_STATUS:
            return updateImportJobStatus(state, action);

        case actionTypes.GET_JOB_IMPORT_QUEUE_START:
            return getJobImportQueueStart(state);
        case actionTypes.GET_JOB_IMPORT_QUEUE_SUCCESS:
            return getJobImportQueueSuccess(state, action);
        case actionTypes.GET_JOB_IMPORT_QUEUE_FAIL:
            return getJobImportQueueFail(state, action);

        case actionTypes.IMPORT_JOB_MATCHES_DATA_START:
            return importJobMatchesDataExcelStart(state);
        case actionTypes.IMPORT_JOB_MATCHES_DATA_SUCCESS:
            return importJobMatchesDataExcelSuccess(state, action);
        case actionTypes.IMPORT_JOB_MATCHES_DATA_FAIL:
            return importJobMatchesDataExcelFail(state, action);

        case actionTypes.GET_JOB_FAMILIES_START:
            return getJobFamiliesStart(state);
        case actionTypes.GET_JOB_FAMILIES_SUCCESS:
            return getJobFamiliesSuccess(state, action);
        case actionTypes.GET_JOB_FAMILIES_FAIL:
            return getJobFamiliesFail(state, action);

        case actionTypes.UPDATE_JOB_FAMILY_START:
            return updateJobFamilyStart(state);
        case actionTypes.UPDATE_JOB_FAMILY_SUCCESS:
            return updateJobFamilySuccess(state, action);
        case actionTypes.UPDATE_JOB_FAMILY_FAIL:
            return updateJobFamilyFail(state, action);

        case actionTypes.DELETE_JOB_FAMILY_START:
            return deleteJobFamilyStart(state);
        case actionTypes.DELETE_JOB_FAMILY_SUCCESS:
            return deleteJobFamilySuccess(state, action);
        case actionTypes.DELETE_JOB_FAMILY_FAIL:
            return deleteJobFamilyFail(state, action);

        default:
            return state;
    }
};

export default reducer;
