import { dismisAlert, generateAlert } from "../../../src/utils/alertUtils";
import * as authActions from "../../store/actions/authAction";
import * as contentActions from "../../store/actions/contentAction";
import * as jobActions from "../../store/actions/jobAction";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-my-people.css";
import { HubConnectionBuilder } from "@aspnet/signalr";
import { faCheckCircle, faExclamationCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Modal, ModalBody, ModalHeader, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "22px",
    fontFamily: "IBMPlexSans-Bold",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

class RequirementProfile extends React.Component {
    static displayName = RequirementProfile.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            loading: false,
            contentLoading: true,
            jobs: [],
            alerts: [],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaManage").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                this.props.onAuth.authCheckState().then(() => {
                    this.setState({ loading: this.props.authLoading });
                    this.initData();
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                this.setState({ loading: this.props.authLoading });
                this.initData();
            });
        }
    }

    connectSignalR = (jobId) => {
        this.connection = new HubConnectionBuilder().withUrl(messageHubHostname).build();

        this.connection.on(`UpdateJobProfilesNotification${jobId}`, (response) => {
            console.log("response", response);
            if (response.action == "UpdateJobProfiles") {
                if (response.status == "Success") {
                    let udpatedSkills = response.data;
                    this.props.onJob.updateJobSkillProfilesSuccess(udpatedSkills);
                } else {
                    this.props.onJob.updateJobSkillProfilesFail(response.message);
                }
            }
        });

        this.connection.start().catch((err) => console.error(err.toString()));
    };

    disconnectSignalR = () => {
        if (this.connection) this.connection.stop();
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    initData() {
        const {
            match: { params },
        } = this.props;

        var jobId = params.id;

        this.connectSignalR(jobId);

        Promise.all([this.props.onJob.getJob(jobId), this.props.onJob.getJobProfileVersions(jobId), this.props.onLor.getLors(), this.props.onCategory.getCategories()])
            .then(() => {
                if (!(this.props.loading || this.props.jobsLoading || this.props.lorLoading || this.props.categoryLoading)) {
                    const profileVersions = this.props.profileVersions;
                    let selectedProfileVersion = null;
                    if (profileVersions.length > 0) {
                        selectedProfileVersion = {
                            version: profileVersions[0].version,
                            sfiaVersion: profileVersions[0].sfiaVersion,
                        };
                    }
                    this.setState(
                        {
                            jobId,
                            loading: this.props.loading || this.props.jobsLoading || this.props.lorLoading || this.props.categoryLoading,
                            job: this.props.job,
                            profileVersions: this.props.profileVersions.map((it) => {
                                return {
                                    value: `Version ${it.version}`,
                                    label: `v${it.version} - ${new Intl.DateTimeFormat("en-GB", {
                                        year: "2-digit",
                                        month: "short",
                                        day: "numeric",
                                    }).format(new Date(it.createdOn))}`,
                                    version: it.version,
                                    sfiaVersion: it.sfiaVersion,
                                    createdOn: it.createdOn,
                                };
                            }),
                            selectedProfileVersion: {
                                value: `Version ${this.props.profileVersions[0].version}`,
                                label: `v${this.props.profileVersions[0].version} - ${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(this.props.profileVersions[0].createdOn))}`,
                                version: this.props.profileVersions[0].version,
                                sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                                createdOn: this.props.profileVersions[0].createdOn,
                            },
                        },
                        () => {
                            Promise.all([
                                this.props.onJob.getJobLorProfiles(jobId, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version),
                                this.props.onJob.getJobSkillProfiles(jobId, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version),
                                this.props.onJob.getJobSkillAttributes(jobId),
                            ])
                                .then(() => {
                                    if (!this.props.jobLoading && !this.props.jobError) {
                                        this.setState({
                                            jobLorProfiles: this.props.jobLorProfiles,
                                            jobSkillProfiles: this.props.jobSkillProfiles,
                                            jobSkillAttributes: this.props.jobSkillAttributes,
                                        });
                                    }

                                    this.setState({
                                        loadingProfile: this.props.jobLoading,
                                    });
                                })
                                .catch(() => {
                                    this.setState({
                                        loadingProfile: false,
                                    });
                                });
                        }
                    );
                }
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { jobs } = this.state;

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <ContentWrapperLg></ContentWrapperLg>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        loading: state.job.loading,
        error: state.job.error,
        jobs: state.job.jobs,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequirementProfile);
