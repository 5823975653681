import CategoryAPI from "../../../../common/api/service/CategoryService";
import JobService from "../../../../common/api/service/JobService";
import PersonService from "../../../../common/api/service/PersonService";
import ProfileService from "../../../../common/api/service/ProfileService";
import { LevelResponsibility } from "../../components/config/mockdata/levels";
import { OrderCategory } from "../../components/config/mockdata/order";
import SkillGapsFilters from "../../components/filters/skillgaps";
import { SkillGapTable } from "../../components/table";
import { Typography, Empty } from "antd";
import { groupBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const { Title } = Typography;

const DivCenter = ({ Component }) => {
  return (
    <div className="p-grid p-align-center vertical-container">
      <div className="layout-wrapper">
        <div className="p-grid">
          <div
            className="p-col-12"
            style={{ position: "fixed", top: "50%", left: "50%" }}
          >
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  padding: 10px 30px;
  flex-wrap: wrap !important;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Container = styled.div`
  border-width: 0.5px;
  padding: 20px 30px;
  border-color: gainsboro;
  border-style: solid;
  border-radius: 10px;
`;

// Constant Data
const constantData = {
  titleLevel: 5,
  empty: "",
  stringName: "string",
  skillGapName: "skillGap",
  jobNeedName: "jobNeed",
  profileStatusName: "profileStatus",
  desirableName: "Desirable",
  requiredName: "Required",
  fullName: "fullName",
  requirementTypes: ["Current", "Future"],
  competencyName: "Competency",
  knowledgeName: "Knowledge",
  proficiencyName: "Proficiency",
  categoryName: "category",
  subCategoryName: "subCategory",
  levelName: "level",
  skillName: "skill",
  NoName: "No",
  GapName: "Gap",
  noGapName: "no gap",
  minorName: "Minor",
  majorName: "Major",
  characters: {
    m: "M",
    p: "P",
    na: "NA",
  },
};

// Define the function to compute skill gap based on the skill profile.
const getSkillGap = (skillProfile) => {
  if (!skillProfile) return `${constantData.majorName} ${constantData.GapName}`;
  const { m, p, na } = constantData.characters;
  if (skillProfile.stringVal === m)
    return `${constantData.NoName} ${constantData.GapName}`;
  if (skillProfile.stringVal === p && parseFloat(skillProfile.numericVal) < 1)
    return `${constantData.minorName} ${constantData.GapName} - ${constantData.proficiencyName}`;
  if (skillProfile.stringVal === na)
    return `${constantData.minorName} ${constantData.GapName} - ${constantData.proficiencyName}`;
  return `${constantData.majorName} ${constantData.GapName}`;
};

function SkillGaps() {
  // Data
  const [data, setData] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [isDefaultValue, setIsDefaultValue] = useState(false);
  const [isNoGap, setIsNoGap] = useState(true);
  // Selection
  const [selectedSkillType, setSelectedSkillType] = useState([]);
  const [selectedSkillGap, setSelectedSkillGap] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState([]);

  // Fetch people and process
  const processJobDetailData = async (item, persons, categories) => {
    const availableSkillCodes = categories
      ? categories.flatMap((category, index) => {
            return category.subCategories.flatMap((subCategory, index) => {
                return subCategory.skills
                    .filter((it) => !it.isHidden)
                    .flatMap((skill, index) => {
                        return skill.skillsCode;
                    });
            });
        })
      : [];

    const person = persons.find((person) => person.id === item.personId);
    const skillVersions =
      person.version_m ||
      (await ProfileService.getManagedPeopleProfileVersions(person.email));

    const skillVersion = skillVersions && skillVersions.length > 0 ? skillVersions[0] : null;

    const [job, jobSkills] = await Promise.all([
      JobService.getJob(item.jobId),
      JobService.getJobSkillProfiles(item.jobId)
    ]);

    let skillsProfile = [];

    const [skillsProfileSelfAssessed] = await Promise.all([
      person.profile_m
        ? ProfileService.getMySkillPofiles(skillVersion ? skillVersion.sfiaVersion : null, skillVersion ? skillVersion.version : null, "Self Assessed")
        : ProfileService.getManagedPeopleSkillPofiles(
            person.email,
            skillVersion ? skillVersion.sfiaVersion : null, 
            skillVersion ? skillVersion.version : null,
            "Self Assessed"
          )]);

    if (skillsProfileSelfAssessed && skillsProfileSelfAssessed.filter(it => availableSkillCodes.includes(it.skillCode)).length > 0){
      skillsProfile.push(...skillsProfileSelfAssessed.filter(it => availableSkillCodes.includes(it.skillCode)));
    }

    const [skillsProfileEndorsed] = await Promise.all([
      person.profile_m
        ? ProfileService.getMySkillPofiles(skillVersion ? skillVersion.sfiaVersion : null, skillVersion ? skillVersion.version : null, "Endorsed")
        : ProfileService.getManagedPeopleSkillPofiles(
            person.email,
            skillVersion ? skillVersion.sfiaVersion : null, 
            skillVersion ? skillVersion.version : null,
            "Endorsed"
          )]);
    
    if (skillsProfileEndorsed && skillsProfileEndorsed.filter(it => availableSkillCodes.includes(it.skillCode)).length > 0){
      skillsProfile.push(...skillsProfileEndorsed.filter(it => availableSkillCodes.includes(it.skillCode)));
    }

    return jobSkills
      .filter(it => availableSkillCodes.includes(it.skillCode))
      .map((jobSkill) => {
      const findCategory = categories.find(
        (item) => item.name === jobSkill.category
      );
      const findSubCategory = findCategory.subCategories.find(
        (item) => item.name === jobSkill.subCategory
      );
      const findSkill = findSubCategory.skills.find(
        (item) => item.name === jobSkill.skill
      );
      const findLevel = findSkill.levels.find(
        (item) => item.level === jobSkill.level
      );

      const getJobNeed = (val) => {
        if (val === constantData.characters.m) {
          return constantData.requiredName;
        } else if (val === constantData.characters.p) {
          return constantData.desirableName;
        } else {
          return constantData.empty;
        }
      };

      const matchedSkillProfile = skillsProfile.find(
        (skill) =>
          skill.level === jobSkill.level &&
          jobSkill.skillCode === skill.skillCode
      );

      if (matchedSkillProfile) {
        const { m, p } = constantData.characters;
        matchedSkillProfile.capability =
          {
            [m]: constantData.competencyName,
            [p]: constantData.proficiencyName,
          }[matchedSkillProfile.stringVal] || constantData.knowledgeName;
      }

      const selfAssessedMatchedSkillProfile = skillsProfile.find(
        (skill) =>
          skill.level === jobSkill.level &&
          jobSkill.skillCode === skill.skillCode && 
          skill.profileType == "Self Assessed"
      );

      if (selfAssessedMatchedSkillProfile) {
        const { m, p } = constantData.characters;
        selfAssessedMatchedSkillProfile.capability =
          {
            [m]: constantData.competencyName,
            [p]: constantData.proficiencyName,
          }[selfAssessedMatchedSkillProfile.stringVal] || constantData.knowledgeName;
      }

      const endorsedMatchedSkillProfile = skillsProfile.find(
        (skill) =>
          skill.level === jobSkill.level &&
          jobSkill.skillCode === skill.skillCode && 
          skill.profileType == "Endorsed"
      );

      if (endorsedMatchedSkillProfile) {
        const { m, p } = constantData.characters;
        endorsedMatchedSkillProfile.capability =
          {
            [m]: constantData.competencyName,
            [p]: constantData.proficiencyName,
          }[endorsedMatchedSkillProfile.stringVal] || constantData.knowledgeName;
      }

      return {
        category: findCategory.name,
        categoryDetail: {
          colour: findCategory.colour,
          description: findCategory.description,
        },
        subCategory: jobSkill.subCategory,
        subCategoryDetail: {
          skillColour: findSubCategory.skillColour,
          colour: findSubCategory.colour,
          description: findSubCategory.description,
        },
        skill: jobSkill.skill,
        skillDetail: {
          description: findSkill.description,
        },
        skillCode: jobSkill.skillCode,
        level: jobSkill.level,
        levelDetail: {
          description: findLevel.description,
        },
        jobNeed: getJobNeed(jobSkill.stringVal),
        jobName: job.name,
        jobRef: job.reference,
        jobType: item.jobType,
        fullName: `${person.firstName} ${person.lastName}`,
        position: person.position,
        email: person.email,
        jobSkill: jobSkill,
        profileStatus: !matchedSkillProfile
          ? "Not Applicable - No Skill"
          : matchedSkillProfile.profileType,        
        skillGap: getSkillGap(matchedSkillProfile),
        matchedSkillProfile: matchedSkillProfile,
        selfAssessedProfileStatus: !selfAssessedMatchedSkillProfile
          ? "Not Applicable - No Skill"
          : selfAssessedMatchedSkillProfile.profileType,        
        selfAssessedSkillGap: getSkillGap(selfAssessedMatchedSkillProfile),
        selfAssessedMatchedSkillProfile: selfAssessedMatchedSkillProfile,
        endorsedProfileStatus: !endorsedMatchedSkillProfile
          ? "Not Applicable - No Skill"
          : endorsedMatchedSkillProfile.profileType,        
        endorsedSkillGap: getSkillGap(endorsedMatchedSkillProfile),
        endorsedMatchedSkillProfile: endorsedMatchedSkillProfile,
      };
    });
  };

  // Fetch data and process
  const processAllData = async () => {
    try {
      // Fetch the initial data.
      const [
        personPeople,
        personProfile,
        profileVersion,
        jobMatches,
        categories,
      ] = await Promise.all([
        PersonService.getPersonMyPeoples(),
        PersonService.getPersonMyProfile(),
        ProfileService.getMyProfileVersions(),
        JobService.getMyJobMatches(),
        CategoryAPI.getCategories(),
      ]);
      // Filter matches based on predefined job types.
      const filterMatches = (match) =>
        constantData.requirementTypes.includes(match.jobType);
      const validMatches = jobMatches.filter(filterMatches);

      // Fetch job matches for associated people.
      const childMatchesPromises = personPeople.map(({ personB }) =>
        JobService.getManagedPeopleJobMatches(personB.email)
      );
      const childMatchesResponses = await Promise.all(childMatchesPromises);
      const childMatches = childMatchesResponses.flat(3).filter(filterMatches);

      // Combine main and child matches, and prepare the persons list.
      const combinedMatches = [...childMatches, ...validMatches];
      const persons = [
        ...personPeople.map(({ personB }) => personB),
        { ...personProfile, version_m: profileVersion, profile_m: {} },
      ];

      // Flatten the job skill data.
      const resultData = await Promise.all(
        combinedMatches.map((item) =>
          processJobDetailData(item, persons, categories)
        )
      );

      setData(resultData.flat(2));
    } catch (error) {
      // Log the error if fetching fails
      console.error("Failed to fetch action plan data:", error);

      // Optionally, you can set an error state here if you'd like to display an error message to users.
    }
  };

  // Filter Data by No Gap
  const getDataFilterByNoGap = () => {
    if (data.length === 0) return [];
    if (isNoGap) {
      return data.filter(
        (item) => 
          item.skillGap.toLowerCase() !== constantData.noGapName ||
          item.selfAssessedSkillGap.toLowerCase() !== constantData.noGapName || 
          item.endorsedSkillGap.toLowerCase() !== constantData.noGapName
      );
    }
    return data;
  };

  // Filter skill gap option
  const getOptionSkillGap = () => {
    const tempData = getDataFilterByNoGap();
    if (tempData.length === 0) return [];
    return Object.keys(groupBy(tempData, constantData.skillGapName)).map(
      (item) => ({
        value: item,
        label: item,
      })
    );
  };

  // Filter skill type option
  const getOptionSkillType = () => {
    const tempData = getDataFilterByNoGap();
    if (tempData.length === 0) return [];
    return Object.keys(groupBy(tempData, constantData.jobNeedName)).map(
      (item) => ({
        value: item,
        label: item,
      })
    );
  };

  // Filter Person Profile Type option
  const getOptionProfile = () => {
    const tempData = getDataFilterByNoGap();
    if (tempData.length === 0) return [];
    let profiles = [];
    
    Object.keys(groupBy(tempData, constantData.profileStatusName)).forEach(
      (item) => {
        if (!profiles.find(it => it.value == item)){
          profiles.push({
            value: item,
            label: item,
          })
        }
      }
    );
    return profiles;
  };

  // Filter Person Name option
  const getOptionName = () => {
    const tempData = getDataFilterByNoGap();
    if (tempData.length === 0) return [];
    return Object.keys(groupBy(tempData, constantData.fullName)).map(
      (item) => ({
        value: item,
        label: item,
      })
    );
  };

  // Filter Person Profile Type option
  const getOptionRequirement = () => {
    if (constantData.requirementTypes.length === 0) return [];

    if (isDefaultValue === false) {
      setSelectedRequirement([constantData.requirementTypes[0]]);
      setIsDefaultValue(true);
    }

    return constantData.requirementTypes.map((item) => ({
      value: item,
      label: item,
    }));
  };

  // Get All Option
  const options = useMemo(
    () => ({
      names: getOptionName(),
      profiles: getOptionProfile(),
      skillGaps: getOptionSkillGap(),
      requirements: getOptionRequirement(),
      skillType: getOptionSkillType(),
    }),
    [data, isNoGap]
  );

  // Data Filter => Data
  const dataFilter = useMemo(() => {
    const tempData = getDataFilterByNoGap();
    if (tempData.length === 0) return [];
    return tempData.filter((entry) => {
      const { jobType, fullName, jobNeed, 
        skillGap, selfAssessedSkillGap, endorsedSkillGap,
        profileStatus, selfAssessedProfileStatus, endorsedProfileStatus} = entry;

      const isRequirementSelected = !selectedRequirement.length || selectedRequirement.includes(jobType);
      const isSkillGapSelected = !selectedSkillGap.length || 
        ((!selectedProfile.length || (selectedProfile.find(it => it == "Not Applicable - No Skill") && !selectedProfile.find(it => it == "Self Assessed" || it == "Endorsed"))) && selectedSkillGap.includes(skillGap)) ||
        ((!selectedProfile.length || selectedProfile.find(it => it == "Self Assessed")) && selectedSkillGap.includes(selfAssessedSkillGap)) || 
        ((!selectedProfile.length || selectedProfile.find(it => it == "Endorsed")) && selectedSkillGap.includes(endorsedSkillGap));
      
      const isProfileSelected = !selectedProfile.length || 
        (
          selectedProfile.find(it => it == "Not Applicable - No Skill") 
          && !selectedProfile.find(it => it == "Self Assessed" || it == "Endorsed") 
          && selectedProfile.includes(profileStatus)
        ) ||
        (
          selectedProfile.find(it => it == "Self Assessed") 
          && (selectedProfile.includes(selfAssessedProfileStatus) || selfAssessedProfileStatus == "Not Applicable - No Skill")
          && (selfAssessedSkillGap != "No Gap" || (selectedSkillGap.includes("No Gap") && selfAssessedSkillGap == "No Gap"))
        ) || 
        (
          selectedProfile.find(it => it == "Endorsed") 
          && (selectedProfile.includes(endorsedProfileStatus) || endorsedProfileStatus == "Not Applicable - No Skill")
          && (endorsedSkillGap != "No Gap" || (selectedSkillGap.includes("No Gap") && endorsedSkillGap == "No Gap"))
        );

      const isNameSelected = !selectedName.length || selectedName.includes(fullName);
      const isSkillType = !selectedSkillType.length || selectedSkillType.includes(jobNeed);

      return (
        isRequirementSelected &&
        isSkillGapSelected &&
        isSkillType &&
        isProfileSelected &&
        isNameSelected
      );
    });
  }, [
    data,
    isNoGap,
    selectedRequirement,
    selectedSkillGap,
    selectedSkillType,
    selectedProfile,
    selectedName,
  ]);

  // Skills Level By Skills Count (Table) => Data Filter
  const dashboardLevelSkillCountTable = useMemo(() => {
    // If there's no data, immediately return an empty array.
    if (!dataFilter.length) return [];

    // Destructure for easier access.
    const { categoryName, subCategoryName, skillName, levelName } =
      constantData;

    // Helper function to extract style attributes.
    const extractStyles = (categoryDetail, subCategoryDetail) => ({
      colour: subCategoryDetail?.colour || "",
      skillColour: subCategoryDetail?.skillColour || "",
      cateColour: categoryDetail?.colour || "",
    });

    // Use a single reduce function to categorize and structure the data.
    const groupedData = dataFilter.reduce((acc, item) => {
      // Extract keys for categorization.
      const categoryKey = item[categoryName];
      const subCategoryKey = item[subCategoryName];
      const skillKey = item[skillName];
      const levelKey = item[levelName];

      // If the category does not exist, initialize it.
      if (!acc[categoryKey]) {
        acc[categoryKey] = {
          values: [],
          subCategories: {},
        };
      }

      // Push the item into the category values.
      acc[categoryKey].values.push(item);

      // Initialize sub-category if it doesn't exist.
      if (!acc[categoryKey].subCategories[subCategoryKey]) {
        acc[categoryKey].subCategories[subCategoryKey] = {
          values: [],
          skills: {},
        };
      }

      // Push the item into sub-category values.
      acc[categoryKey].subCategories[subCategoryKey].values.push(item);

      // Initialize skill within sub-category if it doesn't exist.
      if (!acc[categoryKey].subCategories[subCategoryKey].skills[skillKey]) {
        acc[categoryKey].subCategories[subCategoryKey].skills[skillKey] = {
          values: [],
          levels: {},
        };
      }

      const currentSkill =
        acc[categoryKey].subCategories[subCategoryKey].skills[skillKey];
      currentSkill.values.push(item);

      // Initialize level within skill if it doesn't exist.
      if (!currentSkill.levels[levelKey]) {
        currentSkill.levels[levelKey] = {
          values: [],
        };
      }

      // Push the item into skill level values.
      currentSkill.levels[levelKey].values.push(item);
      return acc;
    }, {});

    // Extract keys from groupedData
    const groupedKeys = Object.keys(groupedData);

    let orderedCats = OrderCategory.map((key) =>
      groupedKeys.find((e) => e.name === key)
    );

    let orderedKeys = [];
    if (orderedCats.filter(it => it == undefined).length > 0){
      orderedKeys = [...groupedKeys];
    }else{
      //Filter and reorder the keys based on OrderCategory
      orderedKeys = OrderCategory.filter((key) =>
        groupedKeys.includes(key)
      );
    }

    // Construct the ordered grouped data
    const orderedGroupedData = orderedKeys.map((key) => [
      key,
      groupedData[key],
    ]);

    // Convert the structured data into the desired output format.
    return orderedGroupedData.map(
      ([categoryKey, { values: categoryValues, subCategories }]) => {
        const { categoryDetail } = categoryValues[0];

        return {
          name: categoryKey,
          meta: { levelResponsibility: LevelResponsibility },
          description: categoryDetail.description,
          styles: {
            tableHeaderColor: "white",
            tableHeaderBackgroundColor: categoryDetail.colour || "",
          },
          subCategories: Object.entries(subCategories).map(
            ([subCategoryKey, { values: subCategoryValues, skills }]) => {
              const { subCategoryDetail } = subCategoryValues[0];
              const styles = extractStyles(categoryDetail, subCategoryDetail);

              return {
                name: subCategoryKey,
                description: subCategoryDetail.description,
                ...styles,
                skills: Object.entries(skills).map(
                  ([skillKey, { values: skillValues, levels }]) => {
                    const firstSkill = skillValues[0];

                    return {
                      name: `${skillKey} (${firstSkill.skillCode})`,
                      description: firstSkill.skillDetail.description,
                      levels: Object.entries(levels).map(
                        ([levelKey, { values: levelValues }]) => ({
                          lv: levelKey,
                          value: levelValues.length * -1,
                          description: levelValues[0].levelDetail.description,
                        })
                      ),
                      people: skillValues.map(
                        ({
                          fullName,
                          email,
                          skill,
                          skillCode,
                          level,
                          skillGap,
                          selfAssessedSkillGap,
                          endorsedSkillGap,
                          jobName,
                          jobType,
                          matchedSkillProfile,
                        }) => ({
                          fullName,
                          email,
                          skill,
                          skillCode,
                          level,
                          skillGap,
                          selfAssessedSkillGap,
                          endorsedSkillGap,
                          jobName,
                          jobType,
                          capability: matchedSkillProfile?.capability,
                          ...styles,
                        })
                      ),
                    };
                  }
                ),
              };
            }
          ),
        };
      }
    );
  }, [dataFilter]);

  useEffect(() => {
    // This variable helps us ensure that we don't call `setIsContentLoaded` if the component has unmounted before our asynchronous operation completes.
    let isMounted = true;

    processAllData().then(() => {
      if (isMounted) {
        // Only set the loading state to false if the component is still mounted
        setIsContentLoaded(true);
      }
    });

    // The cleanup function for the effect
    return () => {
      // If the component unmounts, we set isMounted to false to prevent any state updates after unmounting.
      isMounted = false;
    };
  }, []);

  // If content is still loading, display a spinner.
  if (!isContentLoaded) {
    return <DivCenter Component={() => <Spinner />} />;
  }

  return (
    <Wrapper>
      <SkillGapsFilters
        objName={{
          options: options.names,
          value: selectedName,
          setValue: setSelectedName,
        }}
        objSkillGap={{
          options: options.skillGaps,
          value: selectedSkillGap,
          setValue: setSelectedSkillGap,
        }}
        objSkillType={{
          options: options.skillType,
          value: selectedSkillType,
          setValue: setSelectedSkillType,
        }}
        objProfile={{
          options: options.profiles,
          value: selectedProfile,
          setValue: setSelectedProfile,
        }}
        objRequirementType={{
          options: options.requirements,
          value: selectedRequirement,
          setValue: setSelectedRequirement,
        }}
      />
      <Container className="mt-4">
        {dashboardLevelSkillCountTable.length > 0 ? (
          <>
            <Title level={constantData.titleLevel}>Skills Level By Count</Title>
            {dashboardLevelSkillCountTable.map((item, index) => (
              <SkillGapTable data={item} key={`${index}-${item.name}`} />
            ))}
          </>
        ) : (
          <Empty />
        )}
      </Container>
    </Wrapper>
  );
}
export { SkillGaps };