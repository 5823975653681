import ErrorHandler from "./../errorResponseHandler";
import Instance from "./AxiosInstance";
import jwt_decode from "jwt-decode";

export const axios = Instance.apiInstance();

class AuthAPI {
    static registerEmailChecking(email) {
        return axios
            .post(`/authentication/register/emailchecking`, { email }, { withCredentials: true })
            .then((res) => {
                return {
                    emailCheckingExist: false,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static register(person) {
        return axios
            .post(`/authentication/register`, person, { withCredentials: true })
            .then((res) => {
                const response = res.data;

                return {
                    token: response.token,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static signin(email, password) {
        const body = {
            email,
            password,
        };

        return axios
            .post(`/authentication/signin`, body, { withCredentials: true })
            .then((res) => {
                const response = res.data;
                const tokenData = jwt_decode(response.token);

                return {
                    expiresIn: tokenData.exp,
                    token: response.token,
                    refreshToken: response.refreshToken,
                    personId: tokenData.PersonId,
                    firstName: tokenData.FirstName,
                    lastName: tokenData.LastName,
                    email: tokenData.Email,
                    personType: tokenData.PersonType,
                    authMode: tokenData.AuthMode,
                    assessmentId: tokenData.AssessmentId,
                    jobProfileReportFormat: tokenData.JobProfileReportFormat,
                    selfAssessedProfileReportFormat: tokenData.SelfAssessedProfileReportFormat,
                    hasPeoples: tokenData.HasPeoples,
                    isEndorser: tokenData.IsEndorser,
                    ownJobs: tokenData.OwnJobs,
                    manageEndorsements: tokenData.ManageEndorsements,
                    manageCertifications: tokenData.ManageCertifications,
                    loginWithMembee: tokenData.LoginWithMembee,
                    loginWithTeams: tokenData.LoginWithTeams,
                    receiveLicenseNotification: tokenData.ReceiveLicenseNotification,
                    lastActiveUserNotification: tokenData.LastActiveUserNotification,
                    allowSkipSurvey: tokenData.AllowSkipSurvey,
                    manageSelfAssessed: tokenData.ManageSelfAssessed,
                    manageSelfProfiles: tokenData.ManageSelfProfiles,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static signinwithdownline(downline) {
        const body = {
            downline,
        };

        return axios
            .post(`/authentication/signinwithdownline`, body, { withCredentials: true })
            .then((res) => {
                const response = res.data;
                const tokenData = jwt_decode(response.token);

                return {
                    expiresIn: tokenData.exp,
                    token: response.token,
                    refreshToken: response.refreshToken,
                    personId: tokenData.PersonId,
                    firstName: tokenData.FirstName,
                    lastName: tokenData.LastName,
                    email: tokenData.Email,
                    personType: tokenData.PersonType,
                    authMode: tokenData.AuthMode,
                    assessmentId: tokenData.AssessmentId,
                    jobProfileReportFormat: tokenData.JobProfileReportFormat,
                    selfAssessedProfileReportFormat: tokenData.SelfAssessedProfileReportFormat,
                    hasPeoples: tokenData.HasPeoples,
                    isEndorser: tokenData.IsEndorser,
                    ownJobs: tokenData.OwnJobs,
                    manageEndorsements: tokenData.ManageEndorsements,
                    manageCertifications: tokenData.ManageCertifications,
                    loginWithMembee: tokenData.LoginWithMembee,
                    loginWithTeams: tokenData.LoginWithTeams,
                    receiveLicenseNotification: tokenData.ReceiveLicenseNotification,
                    lastActiveUserNotification: tokenData.LastActiveUserNotification,
                    allowSkipSurvey: tokenData.AllowSkipSurvey,
                    manageSelfAssessed: tokenData.ManageSelfAssessed,
                    manageSelfProfiles: tokenData.ManageSelfProfiles,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static signinUseToken(token) {
        const body = {
            token,
        };

        return axios
            .post(`/authentication/signinusetoken`, body, { withCredentials: true })
            .then((res) => {
                const response = res.data;
                const tokenData = jwt_decode(response.token);

                return {
                    expiresIn: tokenData.exp,
                    token: response.token,
                    refreshToken: response.refreshToken,
                    personId: tokenData.PersonId,
                    firstName: tokenData.FirstName,
                    lastName: tokenData.LastName,
                    email: tokenData.Email,
                    personType: tokenData.PersonType,
                    authMode: tokenData.AuthMode,
                    assessmentId: tokenData.AssessmentId,
                    jobProfileReportFormat: tokenData.JobProfileReportFormat,
                    selfAssessedProfileReportFormat: tokenData.SelfAssessedProfileReportFormat,
                    hasPeoples: tokenData.HasPeoples,
                    isEndorser: tokenData.IsEndorser,
                    ownJobs: tokenData.OwnJobs,
                    manageEndorsements: tokenData.ManageEndorsements,
                    manageCertifications: tokenData.ManageCertifications,
                    loginWithMembee: tokenData.LoginWithMembee,
                    loginWithTeams: tokenData.LoginWithTeams,
                    receiveLicenseNotification: tokenData.ReceiveLicenseNotification,
                    lastActiveUserNotification: tokenData.LastActiveUserNotification,
                    allowSkipSurvey: tokenData.AllowSkipSurvey,
                    manageSelfAssessed: tokenData.ManageSelfAssessed,
                    manageSelfProfiles: tokenData.ManageSelfProfiles,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static signinUseOneTimeLoginToken(token) {
        const body = {
            token,
        };

        return axios
            .post(`/authentication/signinonetimelink`, body, { withCredentials: true })
            .then((res) => {
                const response = res.data;
                const tokenData = jwt_decode(response.token);

                return {
                    expiresIn: tokenData.exp,
                    token: response.token,
                    refreshToken: response.refreshToken,
                    personId: tokenData.PersonId,
                    firstName: tokenData.FirstName,
                    lastName: tokenData.LastName,
                    email: tokenData.Email,
                    personType: tokenData.PersonType,
                    authMode: tokenData.AuthMode,
                    assessmentId: tokenData.AssessmentId,
                    jobProfileReportFormat: tokenData.JobProfileReportFormat,
                    selfAssessedProfileReportFormat: tokenData.SelfAssessedProfileReportFormat,
                    hasPeoples: tokenData.HasPeoples,
                    isEndorser: tokenData.IsEndorser,
                    ownJobs: tokenData.OwnJobs,
                    manageEndorsements: tokenData.ManageEndorsements,
                    manageCertifications: tokenData.ManageCertifications,
                    loginWithMembee: tokenData.LoginWithMembee,
                    loginWithTeams: tokenData.LoginWithTeams,
                    receiveLicenseNotification: tokenData.ReceiveLicenseNotification,
                    lastActiveUserNotification: tokenData.LastActiveUserNotification,
                    allowSkipSurvey: tokenData.AllowSkipSurvey,
                    manageSelfAssessed: tokenData.ManageSelfAssessed,
                    manageSelfProfiles: tokenData.ManageSelfProfiles,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static refreshToken() {
        return axios
            .post(`/authentication/refreshtoken`, "", { withCredentials: true })
            .then((res) => {
                const response = res.data;
                const tokenData = jwt_decode(response.token);

                return {
                    expiresIn: tokenData.exp,
                    token: response.token,
                    refreshToken: response.refreshToken,
                    personId: tokenData.PersonId,
                    firstName: tokenData.FirstName,
                    lastName: tokenData.LastName,
                    email: tokenData.Email,
                    personType: tokenData.PersonType,
                    authMode: tokenData.AuthMode,
                    assessmentId: tokenData.AssessmentId,
                    jobProfileReportFormat: tokenData.JobProfileReportFormat,
                    selfAssessedProfileReportFormat: tokenData.SelfAssessedProfileReportFormat,
                    hasPeoples: tokenData.HasPeoples,
                    isEndorser: tokenData.IsEndorser,
                    ownJobs: tokenData.OwnJobs,
                    manageEndorsements: tokenData.ManageEndorsements,
                    manageCertifications: tokenData.ManageCertifications,
                    loginWithMembee: tokenData.LoginWithMembee,
                    loginWithTeams: tokenData.LoginWithTeams,
                    receiveLicenseNotification: tokenData.ReceiveLicenseNotification,
                    lastActiveUserNotification: tokenData.LastActiveUserNotification,
                    allowSkipSurvey: tokenData.AllowSkipSurvey,
                    manageSelfAssessed: tokenData.ManageSelfAssessed,
                    manageSelfProfiles: tokenData.ManageSelfProfiles,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static logOut() {
        return axios
            .post(`/authentication/logout`, "", { withCredentials: true })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static logOutIntended() {
        return axios
            .post(`/authentication/logoutIntended`, "", { withCredentials: true })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static forgotPassword(email) {
        const body = {
            email,
        };

        return axios
            .post(`/authentication/forgotpassword`, body, { withCredentials: true })
            .then((res) => {
                return {
                    result: true,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static createAccount(surveyRating) {
        return axios
            .post(`/authentication/createaccount`, surveyRating, { withCredentials: true })
            .then((res) => {
                return {
                    result: true,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitRating(surveyRating) {
        return axios
            .post(`/authentication/submitratingsso`, surveyRating, { withCredentials: true })
            .then((res) => {
                return {
                    result: true,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static submitRatingTeams(surveyRating) {
        return axios
            .post(`/authentication/submitratingteams`, surveyRating, { withCredentials: true })
            .then((res) => {
                return {
                    result: true,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static getResetPassword(token) {
        return axios
            .get(`/authentication/resetpassword?token=${token}`, { withCredentials: true })
            .then((res) => {
                const response = res.data;
                return response;
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }

    static processResetPassword(body) {
        return axios
            .post(`/authentication/resetpassword`, body, { withCredentials: true })
            .then((res) => {
                return {
                    result: true,
                };
            })
            .catch((err) => {
                throw ErrorHandler(err);
            });
    }
}

export default AuthAPI;
