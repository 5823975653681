import * as authActions from "../../../store/actions/authAction";
import * as configActions from "../../../store/actions/configAction";
import changeInput from "../../../utils/changeInput";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import { dismisAlert, generateAlert } from "./../../../utils/alertUtils";
import ManageActionTypes from "./ManageActionTypes";
import ManageAllowedRegistrationEmailDomains from "./ManageAllowedRegistrationEmailDomains";
import ManageEvidenceTypes from "./ManageEvidenceTypes";
import ManageMentorPreferences from "./ManageMentorPreferences";
import ManageRegistrationReasons from "./ManageRegistrationReasons";
import ShowPowerBIReport from "./ShowPowerBIReport";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { SketchPicker, ChromePicker } from "react-color";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const apiHostname = process.env.REACT_APP_API_ENDPOINT;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

const rowStyle = {
    fontSize: "12px",
};

const defaultSelfRegistrationBody =
    "<p style='color:#2b2b2b; font-family:IBMPlexSans-Bold; font-size:38px;'>Apply for employment</p>" +
    "<p style='color:#2b2b2b; font-family: IBMPlexSans-Light; font-size: 14px;'>" +
    "If you are interested in working for [companyname] as a Digital professional, we invite you register for a self-assessment.<br /><br />" +
    "The assessment uses the Skills Framework for the Information Age (SFIA).<a href='https://skillstx.com/about-sfia/' target='_blank'>Learn More</a>.<br /><br />" +
    "After completing the self-assessment we will provide you access to a career planner to review and maintain your skill profile.<br /><br />" +
    "IMPORTANT: The self-assessment is NOT a test but it is very important that you are honest and take your time to study the options carefully." +
    "</p>";

const defaultSelfRegistrationConfirmationBody =
    "<p class='color-dark' style='font-family: IBMPlexSans-Bold; font-size: 20px;'>Great you're NOW registered.</p>" +
    "<div class='color-dark' style='font-family: IBMPlexSans; font-size: 16px;'>" +
    "<span>" +
    "NOTE: You will shortly receive your survey invitation email to the address you registered with.<br>" +
    "If you haven't received it within 5 minutes please check junk/clutter folders.<br>After completing your self-assessment you will:" +
    "</span>" +
    "<ol style='padding-left: 20px;'>" +
    "<li style='padding-left: 5px;'>Receive an email with a report attached based on your survey answers</li>" +
    "<li style='padding-left: 5px;'>Receive an email to set up your password for access to the SkillsTX Planner where you can:<br>" +
    "* Review and refine your Profile<br>" +
    "* Compare your skills against sample jobs<br>" +
    "* Create an action plan" +
    "</li>" +
    "</ol>" +
    "</div>";

class ManageSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            config: null,
            isUpdating: false,
            showEditor: false,
            bodyMode1: "ShowEditor",
            bodyMode2: "ShowEditor",
            optionWhoAllowApproval: [
                {
                    label: "Nobody",
                    value: "Nobody",
                },
                {
                    label: "Managers Only",
                    value: "Managers Only",
                },
                {
                    label: "Managers and Team Leaders",
                    value: "Managers and Team Leaders",
                },
            ],
            optionWhoAllowApprovalIncludeMentor: [
                {
                    label: "Nobody",
                    value: "Nobody",
                },
                {
                    label: "Managers Only",
                    value: "Managers Only",
                },
                {
                    label: "Managers and Team Leaders",
                    value: "Managers and Team Leaders",
                },
                {
                    label: "Mentors Only",
                    value: "Mentors Only",
                },
                {
                    label: "Managers and Mentors",
                    value: "Managers and Mentors",
                },
                {
                    label: "Managers, Team Leaders and Mentors",
                    value: "Managers, Team Leaders and Mentors",
                },
            ],
            optionSelfRegistransMarkedAsCandidate: [
                {
                    label: "Candidate",
                    value: true,
                },
                {
                    label: "Employee",
                    value: false,
                },
            ],
            optionRequirementAssignment: [
                {
                    label: "None",
                    value: "None",
                },
                {
                    label: "Future Only",
                    value: "Future Only",
                },
                {
                    label: "Current Only",
                    value: "Current Only",
                },
                {
                    label: "Both Current and Future",
                    value: "Both Current and Future",
                },
            ],
            optionDefaultActivityStatementFrequency: [
                {
                    label: 14,
                    value: 14,
                },
                {
                    label: 30,
                    value: 30,
                },
                {
                    label: 60,
                    value: 60,
                },
                {
                    label: 90,
                    value: 90,
                },
                {
                    label: 365,
                    value: 365,
                },
            ],
            optionReminderRepeatInterval: [
                // {
                //     label: 0,
                //     value: 0,
                // },
                {
                    label: 1,
                    value: 1,
                },
                {
                    label: 2,
                    value: 2,
                },
                {
                    label: 3,
                    value: 3,
                },
                {
                    label: 4,
                    value: 4,
                },
            ],
            optionReminderTotal: [
                {
                    label: 0,
                    value: 0,
                },
                {
                    label: 1,
                    value: 1,
                },
                {
                    label: 2,
                    value: 2,
                },
                {
                    label: 3,
                    value: 3,
                },
                {
                    label: 4,
                    value: 4,
                },
            ],
            optionJobProfileReportFormat: [
                {
                    label: "PDF",
                    value: "PDF",
                },
                {
                    label: "DOCX",
                    value: "DOCX",
                },
            ],
            optionSelfAssessedProfileReportFormat: [
                {
                    label: "PDF",
                    value: "PDF",
                },
                {
                    label: "DOCX",
                    value: "DOCX",
                },
            ],
            showEvidenceTypesDialogTs: null,
            showMentorPreferencesDialogTs: null,
            showActionTypesDialogTs: null,
            downloadPowerBiModelLoading: false,
            powerBiModelLink: null,
            powerBiModelFileName: null,
            powerBiReportId: null,
            powerBiEmbeddedUrl: null,
            powerBiEmbeddedToken: null,
            showBIReport: false,
            displayBackgroundColorPicker: false,
            displayFontColorPicker: false,
            optionBadgeProvider: [
                {
                    label: "AMPG",
                    value: "APMG",
                },
                {
                    label: "DSA",
                    value: "DSA",
                },
            ],
            optionYesOrNo: [
                {
                    label: "Yes",
                    value: true,
                },
                {
                    label: "No",
                    value: false,
                },
            ],
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onConfig.getAdminConfig().then(() => {
                if (this.props.config && this.props.config.powerBiModelLink && this.props.config.powerBiModelLink != "") {
                    this.setState({ powerBiModelLink: this.props.config.powerBiModelLink, powerBiModelFileName: this.props.config.powerBiModelFileName });
                }

                if (this.props.config && this.props.config.powerBiEmbeddedUrl && this.props.config.powerBiEmbeddedUrl != "" && this.props.config.powerBiEmbeddedToken && this.props.config.powerBiEmbeddedToken != "") {
                    this.setState({ powerBiReportId: this.props.config.powerBiReportId, powerBiEmbeddedUrl: this.props.config.powerBiEmbeddedUrl, powerBiEmbeddedToken: this.props.config.powerBiEmbeddedToken });
                }

                const form = this.reformFormState(this.props.config);
                this.setState({ showEditor: true, config: this.props.config, form, isFormValid: true, loading: this.props.configLoading });
            });

            this.setState({ loading: this.props.loading });
        });
    }

    reformFormState = (config) => {
        const form = {
            allowSelfRegistration: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowSelfRegistration ? config.allowSelfRegistration : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            selfRegistrationWording: {
                validation: {
                    required: true,
                },
                value: config && config.selfRegistrationWording ? config.selfRegistrationWording : defaultSelfRegistrationBody,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            selfRegistrationConfirmationWording: {
                validation: {
                    required: true,
                },
                value: config && config.selfRegistrationConfirmationWording ? config.selfRegistrationConfirmationWording : defaultSelfRegistrationConfirmationBody,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            selfRegistransMarkedAsCandidate: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.selfRegistransMarkedAsCandidate ? config.selfRegistransMarkedAsCandidate : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            restrictRegistransEmailDomain: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.restrictRegistransEmailDomain ? config.restrictRegistransEmailDomain : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            automaticUserInactivationLoginDays: {
                validation: {
                    required: true,
                    isNumeric: true,
                },
                value: config && config.automaticUserInactivationLoginDays ? config.automaticUserInactivationLoginDays : 0,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            assessmentId: {
                validation: {
                    required: false,
                },
                value: config && config.assessmentId,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            whoAllowApproval: {
                validation: {
                    required: true,
                },
                value: config ? config.whoAllowApproval : "Managers Only",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            enableApprovalRequest: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.enableApprovalRequest,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowUsersToBeForgotten: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowUsersToBeForgotten ? config.allowUsersToBeForgotten : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowUserExportSelfAssessedProfile: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowUserExportSelfAssessedProfile ? config.allowUserExportSelfAssessedProfile : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            hasCurrentJobLocked: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.hasCurrentJobLocked ? config.hasCurrentJobLocked : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            hasFutureJobLocked: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.hasFutureJobLocked ? config.hasFutureJobLocked : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            defaultActivityStatementFrequency: {
                validation: {
                    required: true,
                    isNumeric: true,
                },
                value: config && config.defaultActivityStatementFrequency ? config.defaultActivityStatementFrequency : 30,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            reminderRepeatInterval: {
                validation: {
                    required: true,
                    isNumeric: true,
                },
                value: config && config.reminderRepeatInterval ? config.reminderRepeatInterval : 2,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            reminderTotal: {
                validation: {
                    required: true,
                    isNumeric: true,
                },
                value: config && config.reminderTotal ? config.reminderTotal : 2,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            assessmentTutorialUrl: {
                validation: {
                    required: true,
                },
                value: config && config.assessmentTutorialUrl,
                valid: config && config.assessmentTutorialUrl && config.assessmentTutorialUrl != "" ? true : false,
                isValidFormat: config && config.assessmentTutorialUrl && config.assessmentTutorialUrl != "" ? true : false,
                touched: false,
            },
            assessmentDescription: {
                validation: {
                    required: true,
                },
                value: config && config.assessmentDescription,
                valid: config && config.assessmentDescription && config.assessmentDescription != "" ? true : false,
                isValidFormat: config && config.assessmentDescription && config.assessmentDescription != "" ? true : false,
                touched: false,
            },
            assessmentSurveyTargetCompletionInterval: {
                validation: {
                    required: true,
                    isNumeric: true,
                },
                value: config && config.assessmentSurveyTargetCompletionInterval ? config.assessmentSurveyTargetCompletionInterval : 14,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            jobProfileReportFormat: {
                validation: {
                    required: true,
                },
                value: config && config.jobProfileReportFormat,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            selfAssessedProfileReportFormat: {
                validation: {
                    required: true,
                },
                value: config && config.selfAssessedProfileReportFormat,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            suppressActivityNotification: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.suppressActivityNotification,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowUserSuppressActivityNotification: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowUserSuppressActivityNotification,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowCertification: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowCertification,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav1Label: {
                validation: {
                    required: true,
                },
                value: config && config.nav1Label ? config.nav1Label : "Training Portal",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav1Link: {
                validation: {
                    required: true,
                },
                value: config && config.nav1Link ? config.nav1Link : "http://licensed-skillstx.talentlms.com/",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav2Label: {
                validation: {
                    required: true,
                },
                value: config && config.nav2Label ? config.nav2Label : "Support",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav2Link: {
                validation: {
                    required: true,
                },
                value: config && config.nav2Link ? config.nav2Link : "https://skillstx.com/support-center/",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav3Label: {
                validation: {
                    required: true,
                },
                value: config && config.nav3Label ? config.nav3Label : "News",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            nav3Link: {
                validation: {
                    required: true,
                },
                value: config && config.nav3Link ? config.nav3Link : "https://skillstx.com/wisdom/",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            stepPanelBackgroundColor: {
                validation: {
                    required: true,
                },
                value: config && config.stepPanelBackgroundColor ? config.stepPanelBackgroundColor : "#2D2D2D",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            stepPanelFontColor: {
                validation: {
                    required: true,
                },
                value: config && config.stepPanelFontColor ? config.stepPanelFontColor : "#FFFFFF",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            companyName: {
                validation: {
                    required: true,
                },
                value: config && config.companyName ? config.companyName : null,
                valid: config && config.companyName ? true : false,
                isValidFormat: config && config.companyName ? true : false,
                touched: false,
            },
            entityName: {
                validation: {
                    required: true,
                },
                value: config && config.entityName ? config.entityName : null,
                valid: config && config.entityName ? true : false,
                isValidFormat: config && config.entityName ? true : false,
                touched: false,
            },
            enablePromoteAction: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.enablePromoteAction != null ? config.enablePromoteAction : true,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            actionTargetCompletionDateIsMandatory: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.actionTargetCompletionDateIsMandatory != null ? config.actionTargetCompletionDateIsMandatory : false,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            badgeProvider: {
                validation: {
                    required: true,
                },
                value: config && config.badgeProvider ? config.badgeProvider : "APMG",
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            enableFindMentor: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.enableFindMentor != null ? config.enableFindMentor : true,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowUpdateProfileAndEvidenceFromAction: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowUpdateProfileAndEvidenceFromAction,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowSkipSelfAssessmentSurvey: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowSkipSelfAssessmentSurvey,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
            allowShowHiddenSkills: {
                validation: {
                    required: false,
                    isBool: true,
                },
                value: config && config.allowShowHiddenSkills != null ? config.allowShowHiddenSkills : true,
                valid: true,
                isValidFormat: true,
                touched: false,
            },
        };

        return form;
    };

    changeMode = (name, value) => {
        let updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleSaveClick = () => {
        const form = this.state.form;
        const oriConfig = this.props.config;

        const config = {
            allowSelfRegistration: form.allowSelfRegistration.value,
            selfRegistransMarkedAsCandidate: form.selfRegistransMarkedAsCandidate.value,
            restrictRegistransEmailDomain: form.restrictRegistransEmailDomain.value,
            selfRegistrationWording: form.selfRegistrationWording.value,
            selfRegistrationConfirmationWording: form.selfRegistrationConfirmationWording.value,
            assessmentId: form.assessmentId.value,
            hasCurrentJobLocked: form.hasCurrentJobLocked.value,
            hasFutureJobLocked: form.hasFutureJobLocked.value,
            whoAllowApproval: form.whoAllowApproval.value,
            enableApprovalRequest: form.enableApprovalRequest.value,
            allowUsersToBeForgotten: form.allowUsersToBeForgotten.value,
            allowUserExportSelfAssessedProfile: form.allowUserExportSelfAssessedProfile.value,
            defaultActivityStatementFrequency: form.defaultActivityStatementFrequency.value,
            reminderRepeatInterval: form.reminderRepeatInterval.value,
            reminderTotal: form.reminderTotal.value,
            assessmentTutorialUrl: form.assessmentTutorialUrl.value,
            assessmentDescription: form.assessmentDescription.value,
            assessmentSurveyTargetCompletionInterval: form.assessmentSurveyTargetCompletionInterval.value,
            jobProfileReportFormat: form.jobProfileReportFormat.value,
            selfAssessedProfileReportFormat: form.selfAssessedProfileReportFormat.value,
            suppressActivityNotification: form.suppressActivityNotification.value,
            allowUserSuppressActivityNotification: form.allowUserSuppressActivityNotification.value,
            allowCertification: form.allowCertification.value,
            nav1Label: oriConfig && oriConfig.nav1Label != form.nav1Label.value && form.nav1Label.touched ? form.nav1Label.value : oriConfig.nav1Label,
            nav1Link: oriConfig && oriConfig.nav1Link != form.nav1Link.value && form.nav1Link.touched ? form.nav1Link.value : oriConfig.nav1Link,
            nav2Label: oriConfig && oriConfig.nav2Label != form.nav2Label.value && form.nav2Label.touched ? form.nav2Label.value : oriConfig.nav2Label,
            nav2Link: oriConfig && oriConfig.nav2Link != form.nav2Link.value && form.nav2Link.touched ? form.nav2Link.value : oriConfig.nav2Link,
            nav3Label: oriConfig && oriConfig.nav3Label != form.nav3Label.value && form.nav3Label.touched ? form.nav3Label.value : oriConfig.nav3Label,
            nav3Link: oriConfig && oriConfig.nav3Link != form.nav3Link.value && form.nav3Link.touched ? form.nav3Link.value : oriConfig.nav3Link,
            stepPanelBackgroundColor: form.stepPanelBackgroundColor.value,
            stepPanelFontColor: form.stepPanelFontColor.value,
            companyName: form.companyName.value,
            entityName: form.entityName.value,
            automaticUserInactivationLoginDays: form.automaticUserInactivationLoginDays.value,
            showEndorsedMatchPercentInRequirementAssign: oriConfig.showEndorsedMatchPercentInRequirementAssign,
            enablePromoteAction: form.enablePromoteAction.value,
            actionTargetCompletionDateIsMandatory: form.actionTargetCompletionDateIsMandatory.value,
            badgeProvider: form.badgeProvider.value,
            enableFindMentor: form.enableFindMentor.value,
            allowUpdateProfileAndEvidenceFromAction: form.allowUpdateProfileAndEvidenceFromAction.value,
            allowSkipSelfAssessmentSurvey: form.allowSkipSelfAssessmentSurvey.value,
            allowShowHiddenSkills: form.allowShowHiddenSkills.value,
        };

        this.setState({ isUpdating: true });

        this.props.onConfig.updateAdminConfig(config).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.configError) {
                    this.generateAlert("success", "Save configuration success.");
                } else {
                    this.generateAlert("danger", this.props.configError.errData.Message);
                }
            }
            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleResetClick = () => {
        this.setState({ showEditor: false });
        const form = this.reformFormState(this.props.config);

        setTimeout(() => {
            this.setState({ form, showEditor: true, isFormValid: true });
        }, 100);
    };

    changeBodyMode1 = (bodyMode1) => {
        this.setState({ bodyMode1 });
    };

    changeBodyMode2 = (bodyMode2) => {
        this.setState({ bodyMode2 });
    };

    onEditorStateChange1(editorContent) {
        let html = draftToHtml(convertToRaw(editorContent.getCurrentContent()));
        if (html.trim() == "<p></p>") {
            html = "";
        }

        const updateState = changeInput(this.state.form, "selfRegistrationWording", html);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    onEditorStateChange2(editorContent) {
        let html = draftToHtml(convertToRaw(editorContent.getCurrentContent()));
        if (html.trim() == "<p></p>") {
            html = "";
        }

        const updateState = changeInput(this.state.form, "selfRegistrationConfirmationWording", html);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    handleChange = (event) => {
        const form = this.state.form;
        const { name, value } = event.target;

        if (name == "requirmentAssignment") {
            let hasCurrentJobLocked = true;
            let hasFutureJobLocked = true;

            if (value == "Current Only" || value == "Both Current and Future") {
                hasCurrentJobLocked = false;
            }

            if (value == "Future Only" || value == "Both Current and Future") {
                hasFutureJobLocked = false;
            }

            form.hasCurrentJobLocked.value = hasCurrentJobLocked;
            form.hasFutureJobLocked.value = hasFutureJobLocked;

            this.setState({ form });
        } else {
            let updateState = changeInput(this.state.form, name, value);

            if (name == "whoAllowApproval") {
                if (value == "Nobody") {
                    updateState = changeInput(updateState.form, "enableApprovalRequest", false);
                }
            }

            // if (name == "enableFindMentor" && (value == false || value == "false")) {
            //     const whoAllowApproval = updateState.form.whoAllowApproval.value;
            //     if (whoAllowApproval.includes("Mentor")) {
            //         updateState = changeInput(updateState.form, "whoAllowApproval", "Nobody");
            //     }
            // }

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
                isFormDirty: true,
            });
        }
    };

    handleChangeCheckBox = (event) => {
        const form = this.state.form;

        if (form.automaticUserInactivationLoginDays.value == 0) {
            form.automaticUserInactivationLoginDays.value = 90;

            const updateState = changeInput(this.state.form, "automaticUserInactivationLoginDays", 90);

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
            });
        } else {
            const updateState = changeInput(this.state.form, "automaticUserInactivationLoginDays", 0);

            this.setState({
                form: updateState.form,
                isFormValid: updateState.valid,
            });
        }
    };

    handleInactivationUsersClick = () => {
        const config = this.props.config;
        const form = this.state.form;

        if (config.automaticUserInactivationLoginDays != form.automaticUserInactivationLoginDays.value) {
            if (window.confirm("Updated config will applied after the updated config Save, this process will still using previous config. Continue?")) {
                this.setState({ isUpdating: true });

                this.props.onConfig.executeInactivationUsersLessLogin().then(() => {
                    if (!this.props.isUpdating) {
                        if (!this.props.configError) {
                            this.generateAlert("success", `Success, ${this.props.numberOfPersonsPutToInactive} users has been put to inactive.`);
                        } else {
                            this.generateAlert("danger", this.props.configError.errData.Message);
                        }
                    }
                    this.setState({ isUpdating: this.props.isUpdating });
                });
            }
        } else {
            this.setState({ isUpdating: true });

            this.props.onConfig.executeInactivationUsersLessLogin().then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.configError) {
                        this.generateAlert("success", `Success, ${this.props.numberOfPersonsPutToInactive} users has been put to inactive.`);
                    } else {
                        this.generateAlert("danger", this.props.configError.errData.Message);
                    }
                }
                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    openManageEvidenceType = () => {
        this.setState({ showEvidenceTypesDialogTs: new Date() });
    };

    openManageMentorPreferences = () => {
        this.setState({ showMentorPreferencesDialogTs: new Date() });
    };

    openManageActionType = () => {
        this.setState({ showActionTypesDialogTs: new Date() });
    };

    openManageActionLog = () => {
        this.props.history.push("/admin/settings/actionlogs");
    };

    openManageAllowedEmailDomains = () => {
        this.setState({ showAllowedEmailDomainsDialogTs: new Date() });
    };

    openManageRegistrationReason = () => {
        this.setState({ showRegistrationReasonsDialogTs: new Date() });
    };

    handleDownloadPowerBI = (powerBiModelLink, powerBiModelFileName) => {
        this.setState({ downloadPowerBiModelLoading: true });
        this.props.onConfig.downloadPowerBIModel().then(() => {
            if (!this.props.loadingExport && !this.props.configError) {
                if (this.props.blobData) {
                    FileDownload(this.props.blobData, powerBiModelFileName);
                }
            }

            this.setState({ downloadPowerBiModelLoading: this.props.loadingExport });
        });
    };

    handleShowPowerBIReport = (showBIReport) => {
        this.setState({ showBIReport });
    };

    handleOpenBackgroundColorPicker = () => {
        this.setState({ displayBackgroundColorPicker: !this.state.displayBackgroundColorPicker });
    };

    handleCloseBackgroundColorPicker = () => {
        this.setState({ displayBackgroundColorPicker: false });
    };

    handleChangeBackgroundColorComplete = (color) => {
        const form = this.state.form;
        form.stepPanelBackgroundColor.value = color.hex;
        this.setState({ form });
    };

    resetBackgroundColor = () => {
        const form = this.state.form;

        form.stepPanelBackgroundColor.value = this.props.config && this.props.config.stepPanelBackgroundColor ? this.props.config.stepPanelBackgroundColor : "#2D2D2D";

        this.setState({ form });
    };

    handleOpenFontColorPicker = () => {
        this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker });
    };

    handleCloseFontColorPicker = () => {
        this.setState({ displayFontColorPicker: false });
    };

    handleChangeFontColorComplete = (color) => {
        const form = this.state.form;
        form.stepPanelFontColor.value = color.hex;
        this.setState({ form });
    };

    resetFontColor = () => {
        const form = this.state.form;

        form.stepPanelFontColor.value = this.props.config && this.props.config.stepPanelFontColor ? this.props.config.stepPanelFontColor : "#FFFFFF";

        this.setState({ form });
    };

    render() {
        const popover = {
            position: "absolute",
            zIndex: "2",
        };
        const cover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        };

        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Features Settings");

        const toolbar = {
            options: ["inline", "list", "textAlign", "fontSize", "link", "history", "fontFamily", "colorPicker"],
            inline: {
                inDropdown: false,
                className: undefined,
                options: ["bold", "italic", "underline", "superscript", "subscript"],
            },
            fontFamily: {
                options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
            },
        };

        const {
            form,
            optionRequirementAssignment,
            optionWhoAllowApproval,
            optionWhoAllowApprovalIncludeMentor,
            optionDefaultActivityStatementFrequency,
            optionReminderRepeatInterval,
            optionReminderTotal,
            optionJobProfileReportFormat,
            optionSelfAssessedProfileReportFormat,
            optionSelfRegistransMarkedAsCandidate,
            optionBadgeProvider,
            optionYesOrNo,
        } = this.state;

        const blocksFromHtml1 = htmlToDraft(form && form.selfRegistrationWording ? form.selfRegistrationWording.value : "");
        const contentBlocks1 = blocksFromHtml1.contentBlocks;
        const entityMap1 = blocksFromHtml1.entityMap;
        const contentState1 = ContentState.createFromBlockArray(contentBlocks1, entityMap1);
        const selfRegistrationWordingEditorState = EditorState.createWithContent(contentState1);

        const blocksFromHtml2 = htmlToDraft(form && form.selfRegistrationConfirmationWording ? form.selfRegistrationConfirmationWording.value : "");
        const contentBlocks2 = blocksFromHtml2.contentBlocks;
        const entityMap2 = blocksFromHtml2.entityMap;
        const contentState2 = ContentState.createFromBlockArray(contentBlocks2, entityMap2);
        const selfRegistrationConfirmationWordingEditorState = EditorState.createWithContent(contentState2);

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <ManageEvidenceTypes
                            showDialog={this.state.showEvidenceTypesDialogTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></ManageEvidenceTypes>
                        <ManageActionTypes
                            showDialog={this.state.showActionTypesDialogTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></ManageActionTypes>
                        <ManageMentorPreferences
                            showDialog={this.state.showMentorPreferencesDialogTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></ManageMentorPreferences>
                        <ManageAllowedRegistrationEmailDomains
                            showDialog={this.state.showAllowedEmailDomainsDialogTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></ManageAllowedRegistrationEmailDomains>
                        <ManageRegistrationReasons
                            showDialog={this.state.showRegistrationReasonsDialogTs}
                            generateAlert={this.generateAlert}
                            allowEdit={pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false}
                        ></ManageRegistrationReasons>
                        <ShowPowerBIReport showBIReport={this.state.showBIReport} reportId={this.state.powerBiReportId} embedUrl={this.state.powerBiEmbeddedUrl} accessToken={this.state.powerBiEmbeddedToken} closeReport={this.handleShowPowerBIReport}></ShowPowerBIReport>
                        <ContentWrapperLg style={{ width: "100%", maxWidth: "100%" }} className="contentWrapperLgAdmin-Style">
                            <p className={"page-title"} style={headerStyle}>
                                Manage Features/Settings
                            </p>
                            <Form>
                                <Row>
                                    <Col md={12} sm={12}>
                                        {this.props.config && (
                                            <React.Fragment>
                                                {(this.props.config.accountName || this.props.config.accountNumber) && (
                                                    <React.Fragment>
                                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                                            <Label sm={3}>CRM Customer Account Name</Label>
                                                            <Label sm={8}>: {this.props.config.accountName ? this.props.config.accountName : "-"}</Label>
                                                        </FormGroup>
                                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                                            <Label sm={3}>CRM Customer Account Number</Label>
                                                            <Label sm={8}>: {this.props.config.accountNumber ? this.props.config.accountNumber : "-"}</Label>
                                                        </FormGroup>
                                                    </React.Fragment>
                                                )}
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label
                                                        sm={12}
                                                        dangerouslySetInnerHTML={{
                                                            __html: `Your license count is <b>${this.props.config.licenceAllocations}</b> with a renewal date of <b>${
                                                                this.props.config.renewalDate &&
                                                                new Intl.DateTimeFormat("en-GB", {
                                                                    year: "2-digit",
                                                                    month: "long",
                                                                    day: "numeric",
                                                                }).format(new Date(this.props.config.renewalDate))
                                                            }</b> and you currently have <b>${this.props.config.liveUsers}</b> live users`,
                                                        }}
                                                    ></Label>
                                                </FormGroup>
                                                {this.props.config.analyticSyncEnable && this.props.config.analyticSyncStatus && (
                                                    <FormGroup row style={{ marginBottom: "5px" }}>
                                                        <Label
                                                            sm={12}
                                                            dangerouslySetInnerHTML={{
                                                                __html: `Latest Analytic Sync Status : <b>${this.props.config.analyticSyncStatus}</b>, Last Sync Started On : <b>${
                                                                    this.props.config.lastAnalyticSyncStartedOn &&
                                                                    new Intl.DateTimeFormat("en-GB", {
                                                                        year: "2-digit",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                        hour: "numeric",
                                                                        minute: "numeric",
                                                                        second: "numeric",
                                                                    }).format(new Date(this.props.config.lastAnalyticSyncStartedOn))
                                                                }</b>, Last Sync Success On : <b>${
                                                                    this.props.config.lastAnalyticSyncSuccessOn &&
                                                                    new Intl.DateTimeFormat("en-GB", {
                                                                        year: "2-digit",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                        hour: "numeric",
                                                                        minute: "numeric",
                                                                        second: "numeric",
                                                                    }).format(new Date(this.props.config.lastAnalyticSyncSuccessOn))
                                                                }</b>`,
                                                            }}
                                                        ></Label>
                                                    </FormGroup>
                                                )}
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={12}></Label>
                                                </FormGroup>
                                            </React.Fragment>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Who can approve self-assessed profiles?</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="whoAllowApproval" id="whoAllowApproval" value={form && form.whoAllowApproval.value} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionWhoAllowApprovalIncludeMentor.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Can users request approval?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="enableApprovalRequest"
                                                    id="enableApprovalRequest"
                                                    value={form && form.enableApprovalRequest.value ? form.enableApprovalRequest.value : false}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating || (form && form.whoAllowApproval.value == "Nobody")}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>What type of Requirement can users assign themselves?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="requirmentAssignment"
                                                    id="requirmentAssignment"
                                                    value={
                                                        form
                                                            ? form.hasCurrentJobLocked.value == false && form.hasFutureJobLocked.value == false
                                                                ? "Both Current and Future"
                                                                : form.hasCurrentJobLocked.value == false
                                                                ? "Current Only"
                                                                : form.hasFutureJobLocked.value == false
                                                                ? "Future Only"
                                                                : "None"
                                                            : "None"
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionRequirementAssignment.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>
                                                Can users opt to be forgotten?
                                                <br />
                                                <span style={{ color: "#FF0000" }}>NOTE: If Yes this allows your employees to delete themselves from the system!!!</span>
                                            </Label>
                                            <Col sm={6}>
                                                <Input type="select" name="allowUsersToBeForgotten" id="allowUsersToBeForgotten" value={form && form.allowUsersToBeForgotten.value ? form.allowUsersToBeForgotten.value : false} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Allow users to export their profile?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="allowUserExportSelfAssessedProfile"
                                                    id="allowUserExportSelfAssessedProfile"
                                                    value={form && form.allowUserExportSelfAssessedProfile.value ? form.allowUserExportSelfAssessedProfile.value : false}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Show self-assessment registration page?</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="allowSelfRegistration" id="allowSelfRegistration" value={form && form.allowSelfRegistration.value ? form.allowSelfRegistration.value : false} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {form && form.allowSelfRegistration.value && (form.allowSelfRegistration.value == true || form.allowSelfRegistration.value == "true") && (
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Label sm={6}>Self Registration Wording</Label>
                                                    <Col sm={6}>
                                                        <ButtonGroup>
                                                            <Button color="primary" outline={this.state.bodyMode1 == "ShowEditor" ? false : true} onClick={() => this.changeBodyMode1("ShowEditor")} disabled={this.state.isUpdating}>
                                                                Show Editor
                                                            </Button>
                                                            <Button color="primary" outline={this.state.bodyMode1 != "ShowEditor" ? false : true} onClick={() => this.changeBodyMode1("ShowHtml")} disabled={this.state.isUpdating}>
                                                                Show Html
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col sm={12}>
                                                        {this.state.showEditor &&
                                                            (this.state.bodyMode1 == "ShowEditor" ? (
                                                                <Editor
                                                                    toolbar={toolbar}
                                                                    defaultEditorState={selfRegistrationWordingEditorState}
                                                                    onEditorStateChange={this.onEditorStateChange1.bind(this)}
                                                                    stripPastedStyles={true}
                                                                    toolbarStyle={{ backgroundColor: "#d4d5d6", border: "1px solid #a6a6a6" }}
                                                                    editorStyle={{ minHeight: "500px", padding: "10px", border: "1px solid #a6a6a6" }}
                                                                    disabled={this.state.isUpdating}
                                                                />
                                                            ) : (
                                                                <ReactTextareaAutosize
                                                                    minRows={10}
                                                                    style={{ width: "100%", padding: "10px", border: "1px solid #ced4da" }}
                                                                    aria-label="maximum height"
                                                                    value={form && form.selfRegistrationWording ? form.selfRegistrationWording.value : ""}
                                                                    onChange={this.handleChange}
                                                                    disabled={this.state.isUpdating}
                                                                    id="selfRegistrationWording"
                                                                    name="selfRegistrationWording"
                                                                />
                                                            ))}
                                                    </Col>
                                                </FormGroup>
                                            </React.Fragment>
                                        )}
                                        {form && form.allowSelfRegistration.value && (form.allowSelfRegistration.value == true || form.allowSelfRegistration.value == "true") && (
                                            <React.Fragment>
                                                <FormGroup row>
                                                    <Label sm={6}>Self Registration Confirmation Wording</Label>
                                                    <Col sm={6}>
                                                        <ButtonGroup>
                                                            <Button color="primary" outline={this.state.bodyMode2 == "ShowEditor" ? false : true} onClick={() => this.changeBodyMode2("ShowEditor")} disabled={this.state.isUpdating}>
                                                                Show Editor
                                                            </Button>
                                                            <Button color="primary" outline={this.state.bodyMode2 != "ShowEditor" ? false : true} onClick={() => this.changeBodyMode2("ShowHtml")} disabled={this.state.isUpdating}>
                                                                Show Html
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col sm={12}>
                                                        {this.state.showEditor &&
                                                            (this.state.bodyMode2 == "ShowEditor" ? (
                                                                <Editor
                                                                    toolbar={toolbar}
                                                                    defaultEditorState={selfRegistrationConfirmationWordingEditorState}
                                                                    onEditorStateChange={this.onEditorStateChange2.bind(this)}
                                                                    stripPastedStyles={true}
                                                                    toolbarStyle={{ backgroundColor: "#d4d5d6", border: "1px solid #a6a6a6" }}
                                                                    editorStyle={{ minHeight: "300px", padding: "10px", border: "1px solid #a6a6a6" }}
                                                                    disabled={this.state.isUpdating}
                                                                />
                                                            ) : (
                                                                <ReactTextareaAutosize
                                                                    minRows={10}
                                                                    style={{ width: "100%", padding: "10px", border: "1px solid #ced4da" }}
                                                                    aria-label="maximum height"
                                                                    value={form && form.selfRegistrationConfirmationWording ? form.selfRegistrationConfirmationWording.value : ""}
                                                                    onChange={this.handleChange}
                                                                    disabled={this.state.isUpdating}
                                                                    id="selfRegistrationConfirmationWording"
                                                                    name="selfRegistrationConfirmationWording"
                                                                />
                                                            ))}
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={6}>Registrants will be marked as</Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            type="select"
                                                            name="selfRegistransMarkedAsCandidate"
                                                            id="selfRegistransMarkedAsCandidate"
                                                            value={form && form.selfRegistransMarkedAsCandidate.value ? form.selfRegistransMarkedAsCandidate.value : false}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.isUpdating}
                                                        >
                                                            {optionSelfRegistransMarkedAsCandidate.map((it, key) => (
                                                                <option key={key} value={it.value == true ? true : false}>
                                                                    {it.label}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={6}>Restrict registrant email domain</Label>
                                                    <Col sm={6}>
                                                        <Input
                                                            type="select"
                                                            name="restrictRegistransEmailDomain"
                                                            id="restrictRegistransEmailDomain"
                                                            value={form && form.restrictRegistransEmailDomain.value ? form.restrictRegistransEmailDomain.value : false}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.isUpdating}
                                                        >
                                                            {optionYesOrNo.map((it, key) => (
                                                                <option key={key} value={it.value == true ? true : false}>
                                                                    {it.label}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                                {form && (form.restrictRegistransEmailDomain.value == true || form.restrictRegistransEmailDomain.value == "true") && (
                                                    <FormGroup row style={{ marginBottom: "5px" }}>
                                                        <Label sm={6}>Manage Allowed Email Domains</Label>
                                                        <Col sm={6} style={{ paddingTop: "8px" }}>
                                                            <Link to={{}} onClick={() => this.openManageAllowedEmailDomains()}>
                                                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "Click for edit page" : "Click for show list"}
                                                            </Link>
                                                        </Col>
                                                    </FormGroup>
                                                )}
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={6}>Manage Registration Reasons</Label>
                                                    <Col sm={6} style={{ paddingTop: "8px" }}>
                                                        <Link to={{}} onClick={() => this.openManageRegistrationReason()}>
                                                            {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "Click for edit page" : "Click for show list"}
                                                        </Link>
                                                    </Col>
                                                </FormGroup>
                                            </React.Fragment>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Default Activity Statement frequency?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="defaultActivityStatementFrequency"
                                                    id="defaultActivityStatementFrequency"
                                                    value={form && form.defaultActivityStatementFrequency.value ? form.defaultActivityStatementFrequency.value : 30}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionDefaultActivityStatementFrequency.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    {form && (
                                        <React.Fragment>
                                            <Container
                                                style={{
                                                    border: 1,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: form ? form.stepPanelBackgroundColor.value : "#2D2D2D",
                                                    paddingLeft: "72px",
                                                    paddingRight: "72px",
                                                    paddingTop: "28px",
                                                    paddingBottom: "28px",
                                                    marginBottom: "10px",
                                                    borderRadius: "10px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Row xs="1" sm="1" md="3">
                                                    <Col className="p-0">
                                                        <Container>
                                                            <Row className="mb-1 align-items-center">
                                                                <Col xs="auto" className="p-0">
                                                                    <div id="divStep1Circle" className={"rounded-circle col align-self-center stepCircle"} style={{ cursor: "pointer", border: `1px solid ${form ? form.stepPanelFontColor.value : "#FFFFFF"}` }}>
                                                                        <p className="mt-1" style={{ color: form ? form.stepPanelFontColor.value : "#FFFFFF", fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                                                            1
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <p
                                                                        id="pStep1"
                                                                        className="ml-2 mb-0"
                                                                        style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: form ? form.stepPanelFontColor.value : "#FFFFFF" }}
                                                                        dangerouslySetInnerHTML={{ __html: "The Skills You have:<br />Review and Refine (edit)" }}
                                                                    ></p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                    <Col className="p-0">
                                                        <Container>
                                                            <Row className="mb-1 align-items-center">
                                                                <Col xs="auto" className="p-0">
                                                                    <div id="divStep2Circle" className={"rounded-circle col align-self-center stepCircle"} style={{ cursor: "pointer", border: `1px solid ${form ? form.stepPanelFontColor.value : "#FFFFFF"}` }}>
                                                                        <p className="mt-1" style={{ color: form ? form.stepPanelFontColor.value : "#FFFFFF", fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                                                            2
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <p
                                                                        id="pStep2"
                                                                        className="ml-2 mb-0"
                                                                        style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: form ? form.stepPanelFontColor.value : "#FFFFFF" }}
                                                                        dangerouslySetInnerHTML={{ __html: "The Skills You Need:<br />Assign Current and Future Skill requirements" }}
                                                                    ></p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                    <Col className="p-0">
                                                        <Container>
                                                            <Row className="mb-1  align-items-center">
                                                                <Col xs="auto" className="p-0">
                                                                    <div id="divStep3Circle" className={"rounded-circle col align-self-center stepCircle"} style={{ cursor: "pointer", border: `1px solid ${form ? form.stepPanelFontColor.value : "#FFFFFF"}` }}>
                                                                        <p className="mt-1" style={{ color: form ? form.stepPanelFontColor.value : "#FFFFFF", fontFamily: "IBMPlexSans-Bold", fontSize: "20px" }}>
                                                                            3
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <p
                                                                        id="pStep3"
                                                                        className="ml-2 mb-0"
                                                                        style={{ fontFamily: "IBMPlexSans-Light", fontSize: 11, width: "135px", cursor: "pointer", color: form ? form.stepPanelFontColor.value : "#FFFFFF" }}
                                                                        dangerouslySetInnerHTML={{ __html: "Career Development:<br />Build your Action Plan" }}
                                                                    ></p>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            <Col md={12} sm={12}>
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={6}>Step Panel Background Color?</Label>
                                                    <Col sm={6}>
                                                        <div style={{ float: "left", border: "1px solid #000000", backgroundColor: form ? form.stepPanelBackgroundColor.value : "#2D2D2D", width: "60px", height: "30px", cursor: "pointer" }} onClick={this.handleOpenBackgroundColorPicker}>
                                                            &nbsp;
                                                        </div>
                                                        {this.state.displayBackgroundColorPicker ? (
                                                            <div style={popover}>
                                                                <div style={cover} onClick={this.handleCloseBackgroundColorPicker} />
                                                                <ChromePicker disableAlpha={true} color={form ? form.stepPanelBackgroundColor.value : "#2D2D2D"} onChangeComplete={this.handleChangeBackgroundColorComplete} />
                                                            </div>
                                                        ) : null}
                                                        <div style={{ float: "left", paddingLeft: "10px", color: "#0056b3" }}>
                                                            <Link to={{}} onClick={() => this.resetBackgroundColor()}>
                                                                <FontAwesomeIcon icon={faRotateLeft} style={{ cursor: "pointer", color: "#0056b3" }} /> Reset
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row style={{ marginBottom: "5px" }}>
                                                    <Label sm={6}>Step Panel Font Color?</Label>
                                                    <Col sm={6}>
                                                        <div style={{ float: "left", border: "1px solid #000000", backgroundColor: form ? form.stepPanelFontColor.value : "#FFFFFF", width: "60px", height: "30px", cursor: "pointer" }} onClick={this.handleOpenFontColorPicker}>
                                                            &nbsp;
                                                        </div>
                                                        {this.state.displayFontColorPicker ? (
                                                            <div style={popover}>
                                                                <div style={cover} onClick={this.handleCloseFontColorPicker} />
                                                                <ChromePicker disableAlpha={true} color={form ? form.stepPanelFontColor.value : "#FFFFFF"} onChangeComplete={this.handleChangeFontColorComplete} />
                                                            </div>
                                                        ) : null}
                                                        <div style={{ float: "left", paddingLeft: "10px", color: "#0056b3" }}>
                                                            <Link to={{}} onClick={() => this.resetFontColor()}>
                                                                <FontAwesomeIcon icon={faRotateLeft} style={{ cursor: "pointer", color: "#0056b3" }} /> Reset
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </React.Fragment>
                                    )}
                                    <Col md={12} sm={12}>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Number of assessment reminders</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="reminderTotal" id="reminderTotal" value={form && form.reminderTotal.value ? form.reminderTotal.value : 2} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionReminderTotal.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Reminder frequency in days</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="reminderRepeatInterval" id="reminderRepeatInterval" value={form && form.reminderRepeatInterval.value ? form.reminderRepeatInterval.value : 2} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionReminderRepeatInterval.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>URL to Tutorial</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="assessmentTutorialUrl"
                                                    name="assessmentTutorialUrl"
                                                    type="text"
                                                    value={form && form.assessmentTutorialUrl.value ? form.assessmentTutorialUrl.value : ""}
                                                    invalid={form && form.assessmentTutorialUrl.touched && !form.assessmentTutorialUrl.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Tutorial Url"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Company Name</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    value={form && form.companyName.value ? form.companyName.value : ""}
                                                    invalid={form && form.companyName.touched && !form.companyName.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Company Name"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Department Name</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="entityName"
                                                    name="entityName"
                                                    type="text"
                                                    value={form && form.entityName.value ? form.entityName.value : ""}
                                                    invalid={form && form.entityName.touched && !form.entityName.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Department Name"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Assessment Description</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="assessmentDescription"
                                                    name="assessmentDescription"
                                                    type="text"
                                                    value={form && form.assessmentDescription.value ? form.assessmentDescription.value : ""}
                                                    invalid={form && form.assessmentDescription.touched && !form.assessmentDescription.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Assessment Description"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Assessment Completion Target Date (Invite date + nn days)</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="assessmentSurveyTargetCompletionInterval"
                                                    name="assessmentSurveyTargetCompletionInterval"
                                                    type="number"
                                                    value={form && form.assessmentSurveyTargetCompletionInterval.value ? form.assessmentSurveyTargetCompletionInterval.value : 14}
                                                    invalid={form && form.assessmentSurveyTargetCompletionInterval.touched && !form.assessmentSurveyTargetCompletionInterval.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                />
                                            </Col>
                                        </FormGroup>
                                        {this.props.config && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={6}>Download Power BI model</Label>
                                                <Col sm={6} style={{ paddingTop: "8px" }}>
                                                    <div>
                                                        <div style={{ float: "left", paddingRight: "20px" }}>
                                                            {this.state.powerBiModelLink && this.state.powerBiModelLink != "" ? (
                                                                <React.Fragment>
                                                                    {this.state.downloadPowerBiModelLoading ? (
                                                                        <Spinner size="lg" animation="border" role="status"></Spinner>
                                                                    ) : (
                                                                        <Link to={{}} onClick={() => this.handleDownloadPowerBI(this.state.powerBiModelLink, this.state.powerBiModelFileName)}>
                                                                            Click to download
                                                                        </Link>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </div>
                                                        <div style={{ float: "left", paddingRight: "20px" }}>
                                                            {this.state.powerBiReportId && this.state.powerBiReportId != "" && this.state.powerBiEmbeddedUrl && this.state.powerBiEmbeddedUrl != "" && this.state.powerBiEmbeddedToken && this.state.powerBiEmbeddedToken != "" && (
                                                                <React.Fragment>
                                                                    <Link to={{}} onClick={() => this.handleShowPowerBIReport(true)}>
                                                                        View Report
                                                                    </Link>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Requirement profile report format</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="jobProfileReportFormat" id="jobProfileReportFormat" value={form && form.jobProfileReportFormat.value ? form.jobProfileReportFormat.value : "PDF"} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionJobProfileReportFormat.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Self assessed profile report format</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="selfAssessedProfileReportFormat"
                                                    id="selfAssessedProfileReportFormat"
                                                    value={form && form.selfAssessedProfileReportFormat.value ? form.selfAssessedProfileReportFormat.value : "PDF"}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionSelfAssessedProfileReportFormat.map((it, key) => (
                                                        <option key={key} value={it.value}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>
                                                Suppress system generated notifcations?
                                                <br />
                                                The system will automatically send notifications to inform of evidence expiry, low activity and missed target dates
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="suppressActivityNotification"
                                                    id="suppressActivityNotification"
                                                    value={form && form.suppressActivityNotification.value ? form.suppressActivityNotification.value : false}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Allow users to individually suppress notifications?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="allowUserSuppressActivityNotification"
                                                    id="allowUserSuppressActivityNotification"
                                                    value={form && form.allowUserSuppressActivityNotification.value ? form.allowUserSuppressActivityNotification.value : false}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating || (form && (form.suppressActivityNotification.value == "true" || form.suppressActivityNotification.value == true))}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {this.props.config && this.props.config.showSfiaCertificationAdmin && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={6}>Allow users to request skill certification (fees apply)</Label>
                                                <Col sm={6}>
                                                    <Input type="select" name="allowCertification" id="allowCertification" value={form && form.allowCertification.value ? form.allowCertification.value : false} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                        {optionYesOrNo.map((it, key) => (
                                                            <option key={key} value={it.value == true ? true : false}>
                                                                {it.label}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        {this.props.config && this.props.config.showSfiaCertificationAdmin && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={6}>Badge Provider</Label>
                                                <Col sm={6}>
                                                    <Input type="select" name="badgeProvider" id="badgeProvider" value={form && form.badgeProvider.value != null ? form.badgeProvider.value : "APMG"} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                        {optionBadgeProvider.map((it, key) => (
                                                            <option key={key} value={it.value}>
                                                                {it.label}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Col sm={6} style={{ display: "flex" }}>
                                                <Input
                                                    type="checkbox"
                                                    name="subscribe"
                                                    id="formSubscribe"
                                                    checked={form && form.automaticUserInactivationLoginDays.value > 0 ? true : false}
                                                    invalid={form && form.automaticUserInactivationLoginDays.touched && !form.automaticUserInactivationLoginDays.valid}
                                                    style={{ marginRight: "10px" }}
                                                    onChange={this.handleChangeCheckBox}
                                                    disabled={this.state.isUpdating}
                                                />
                                                <Label for="formSubscribe" check>
                                                    Make dormant users inactive after the number of days specified without logging in (min 90 maximum 365)
                                                </Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Input
                                                    id="automaticUserInactivationLoginDays"
                                                    name="automaticUserInactivationLoginDays"
                                                    type="number"
                                                    value={form && form.automaticUserInactivationLoginDays.value}
                                                    invalid={
                                                        (form && form.automaticUserInactivationLoginDays.touched && !form.automaticUserInactivationLoginDays.valid) ||
                                                        (form && form.automaticUserInactivationLoginDays.value > 0 && (form.automaticUserInactivationLoginDays.value < 90 || form.automaticUserInactivationLoginDays.value > 365))
                                                    }
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating || (form && form.automaticUserInactivationLoginDays.value == 0)}
                                                    min={form && form.automaticUserInactivationLoginDays.value > 0 ? 90 : 0}
                                                    max={form && form.automaticUserInactivationLoginDays.value > 0 ? 365 : 0}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Col sm={6} style={{ display: "flex" }}>
                                                &nbsp;
                                            </Col>
                                            <Col sm={6}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        this.handleInactivationUsersClick();
                                                    }}
                                                    disabled={this.state.isUpdating || (this.props.config && this.props.config.automaticUserInactivationLoginDays == 0)}
                                                >
                                                    Execute Inactivation Process
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Can users Promote Custom Actions?</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="enablePromoteAction" id="enablePromoteAction" value={form && form.enablePromoteAction.value != null ? form.enablePromoteAction.value : true} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Make Target Completion Date for Actions mandatory?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="actionTargetCompletionDateIsMandatory"
                                                    id="actionTargetCompletionDateIsMandatory"
                                                    value={form && form.actionTargetCompletionDateIsMandatory.value != null ? form.actionTargetCompletionDateIsMandatory.value : false}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Can find Mentor?</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="enableFindMentor" id="enableFindMentor" value={form && form.enableFindMentor.value != null ? form.enableFindMentor.value : true} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        {form && form.enableFindMentor.value && (form.enableFindMentor.value === true || form.enableFindMentor.value === "true") && (
                                            <FormGroup row style={{ marginBottom: "5px" }}>
                                                <Label sm={6}>Manage Mentor Preferences</Label>
                                                <Col sm={6} style={{ paddingTop: "8px" }}>
                                                    <Link to={{}} onClick={() => this.openManageMentorPreferences()}>
                                                        {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "Click for edit page" : "Click for show list"}
                                                    </Link>
                                                </Col>
                                            </FormGroup>
                                        )}
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Allow profile updates and evidence from actions?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="allowUpdateProfileAndEvidenceFromAction"
                                                    id="allowUpdateProfileAndEvidenceFromAction"
                                                    value={form && form.allowUpdateProfileAndEvidenceFromAction.value != null ? form.allowUpdateProfileAndEvidenceFromAction.value : true}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Allow users to skip Self assessment surveys?</Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    name="allowSkipSelfAssessmentSurvey"
                                                    id="allowSkipSelfAssessmentSurvey"
                                                    value={form && form.allowSkipSelfAssessmentSurvey.value != null ? form.allowSkipSelfAssessmentSurvey.value : true}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                >
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Allow Show Hidden Skills?</Label>
                                            <Col sm={6}>
                                                <Input type="select" name="allowShowHiddenSkills" id="allowShowHiddenSkills" value={form && form.allowShowHiddenSkills.value != null ? form.allowShowHiddenSkills.value : true} onChange={this.handleChange} disabled={this.state.isUpdating}>
                                                    {optionYesOrNo.map((it, key) => (
                                                        <option key={key} value={it.value == true ? true : false}>
                                                            {it.label}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Manage Evidence Types</Label>
                                            <Col sm={6} style={{ paddingTop: "8px" }}>
                                                <Link to={{}} onClick={() => this.openManageEvidenceType()}>
                                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "Click for edit page" : "Click for show list"}
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Manage Action Types</Label>
                                            <Col sm={6} style={{ paddingTop: "8px" }}>
                                                <Link to={{}} onClick={() => this.openManageActionType()}>
                                                    {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? "Click for edit page" : "Click for show list"}
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 1 Label</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav1Label"
                                                    name="nav1Label"
                                                    type="text"
                                                    value={form && form.nav1Label.value ? form.nav1Label.value : ""}
                                                    invalid={form && form.nav1Label.touched && !form.nav1Label.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 1 Label"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 1 Link</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav1Link"
                                                    name="nav1Link"
                                                    type="text"
                                                    value={form && form.nav1Link.value ? form.nav1Link.value : ""}
                                                    invalid={form && form.nav1Link.touched && !form.nav1Link.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 1 Link"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 2 Label</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav2Label"
                                                    name="nav2Label"
                                                    type="text"
                                                    value={form && form.nav2Label.value ? form.nav2Label.value : ""}
                                                    invalid={form && form.nav2Label.touched && !form.nav2Label.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 2 Label"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 2 Link</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav2Link"
                                                    name="nav2Link"
                                                    type="text"
                                                    value={form && form.nav2Link.value ? form.nav2Link.value : ""}
                                                    invalid={form && form.nav2Link.touched && !form.nav2Link.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 2 Link"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 3 Label</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav3Label"
                                                    name="nav3Label"
                                                    type="text"
                                                    value={form && form.nav3Label.value ? form.nav3Label.value : ""}
                                                    invalid={form && form.nav3Label.touched && !form.nav3Label.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 3 Label"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Navigation 3 Link</Label>
                                            <Col sm={6}>
                                                <Input
                                                    id="nav3Link"
                                                    name="nav3Link"
                                                    type="text"
                                                    value={form && form.nav3Link.value ? form.nav3Link.value : ""}
                                                    invalid={form && form.nav3Link.touched && !form.nav3Link.valid}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.isUpdating}
                                                    placeholder="Navigation 3 Link"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Col sm={12}>
                                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) && (
                                                    <React.Fragment>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                this.handleSaveClick();
                                                            }}
                                                            disabled={!this.state.isFormValid || this.state.isUpdating || (form && form.automaticUserInactivationLoginDays.value > 0 && (form.automaticUserInactivationLoginDays.value < 90 || form.automaticUserInactivationLoginDays.value > 365))}
                                                        >
                                                            Save
                                                        </Button>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <Button color="primary" onClick={this.handleResetClick} disabled={this.state.isUpdating}>
                                                            Reset
                                                        </Button>
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: "5px" }}>
                                            <Label sm={6}>Audit Logs</Label>
                                            <Col sm={6} style={{ paddingTop: "8px" }}>
                                                <Link to="/admin/settings/actionlogs">Click for show Audit Logs</Link>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </ContentWrapperLg>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        configLoading: state.config.loading,
        configError: state.config.error,
        config: state.config.config,
        isUpdating: state.config.isUpdating,
        numberOfPersonsPutToInactive: state.config.numberOfPersonsPutToInactive,

        loadingExport: state.config.loadingExport,
        blobData: state.config.blobData,
        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageSettings));
