import changeInput from "../../../utils/changeInput";
import { formatDate_localeString } from "../../../utils/stringUtils";
import { ContentWrapperLg } from "../../common/ContentWrapperLg";
import React, { Component } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

var DatePicker = require("reactstrap-date-picker");
const textareaStyle = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    color: "#495057",
};

const textareaStyleDisable = {
    width: "100%",
    padding: "10px",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    background: "#e9ecef",
    color: "#495057",
};

class ManageActionPromotionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPromotion: this.props.actionPromotion,
            interventionLevels: [],
            interventionLorLevels: [],
            form: null,
            isFormValid: this.props.actionPromotion ? true : false,
            isUpdating: false,
            updateSuccess: false,
            editMode: false,
            reasonDecline: "",
        };

        this.formatDate_localeString = formatDate_localeString.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.actionPromotion !== this.props.actionPromotion) {
            this.setState({ actionPromotion: this.props.actionPromotion });
        }

        if (prevProps.isUpdating !== this.props.isUpdating) {
            this.setState({ isUpdating: this.props.isUpdating });
        }

        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true, editMode: false });
            this.prepareFormState();
        }

        if (prevProps.updateSuccess !== this.props.updateSuccess) {
            if (this.props.updateSuccess) {
                this.setState({ editMode: false });
            }
        }

        if (prevProps.acceptSuccess !== this.props.acceptSuccess) {
            if (this.props.acceptSuccess) {
                this.setState({ showDialog: false, showAcceptActionPromotionConfirmationDialog: false });
            }
        }

        if (prevProps.declineSuccess !== this.props.declineSuccess) {
            if (this.props.declineSuccess) {
                this.setState({ showDialog: false, showDeclineActionPromotionConfirmationDialog: false });
            }
        }
    }

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    prepareFormState = () => {
        const actionPromotion = this.props.actionPromotion;

        this.setState({
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: actionPromotion && actionPromotion.name ? actionPromotion.name : "",
                    valid: actionPromotion && actionPromotion.name && actionPromotion.name != "" ? true : false,
                    isValidFormat: actionPromotion && actionPromotion.name && actionPromotion.name != "" ? true : false,
                    touched: false,
                },
                actionType: {
                    validation: {
                        required: true,
                    },
                    value: actionPromotion && actionPromotion.actionType ? actionPromotion.actionType : null,
                    valid: actionPromotion && actionPromotion.actionType && actionPromotion.actionType != "" ? true : false,
                    isValidFormat: actionPromotion && actionPromotion.actionType && actionPromotion.actionType != "" ? true : false,
                    touched: false,
                },
                description: {
                    validation: {
                        required: true,
                    },
                    value: actionPromotion && actionPromotion.description ? actionPromotion.description : "",
                    valid: actionPromotion ? true : false,
                    isValidFormat: actionPromotion ? true : false,
                    touched: false,
                },
                duration: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: actionPromotion && actionPromotion.duration ? actionPromotion.duration : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                durationHours: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: actionPromotion && actionPromotion.durationHours ? actionPromotion.durationHours : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: actionPromotion && actionPromotion.link ? actionPromotion.link : "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            interventionLevels: actionPromotion && actionPromotion.levels ? [...actionPromotion.levels] : [],
            interventionLorLevels: actionPromotion && actionPromotion.lorLevels ? [...actionPromotion.lorLevels] : [],
            isFormValid: actionPromotion && ((actionPromotion.levels && actionPromotion.levels.length > 0) || (actionPromotion.lorLevels && actionPromotion.levels.lorLevels > 0)) && actionPromotion.id ? true : false,
        });
    };

    handleChange = (event) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        let { name, value } = event.target;

        if (name == "duration" && value && value != "") {
            if (value < 0) {
                value = 0;
            }
        }

        if (name == "actionType" && value && value == "Select Type") {
            value = "";
        }

        let updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0),
        });
    };

    handleDateChange = (name, value) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0),
        });
    };

    handleLorsCheckedChange = (lorName, lorCode, level) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        if (interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lorCode}${level}`)) {
            const interventionLorLevel = interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lorCode}${level}`);
            const index = interventionLorLevels.indexOf(interventionLorLevel);
            if (index !== -1) {
                interventionLorLevels.splice(index, 1);
            }
        } else {
            interventionLorLevels.push({
                lorName,
                lorCode,
                level,
            });
        }

        const updateState = changeInput(this.state.form, "name", this.state.form.name.value);
        this.setState({ interventionLorLevels, isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0) });
    };

    handleSkillsCheckedChange = (skillsName, skillsCode, level) => {
        const interventionLorLevels = this.state.interventionLorLevels ? this.state.interventionLorLevels : [];
        const interventionLevels = this.state.interventionLevels ? this.state.interventionLevels : [];

        if (interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${skillsCode}${level}`)) {
            const interventionLevel = interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${skillsCode}${level}`);
            const index = interventionLevels.indexOf(interventionLevel);
            if (index !== -1) {
                interventionLevels.splice(index, 1);
            }
        } else {
            interventionLevels.push({
                skillsName,
                skillsCode,
                level,
            });
        }

        const updateState = changeInput(this.state.form, "name", this.state.form.name.value);
        this.setState({ interventionLevels, isFormValid: updateState.valid && (interventionLorLevels.length > 0 || interventionLevels.length > 0) });
    };

    handleEdit = () => {
        const editMode = this.state.editMode;

        this.setState({ editMode: !editMode });
    };

    handleCancel = () => {
        const editMode = this.state.editMode;

        this.setState({ editMode: !editMode });
    };

    handleSave = () => {
        const { form, actionPromotion, interventionLevels, interventionLorLevels } = this.state;

        actionPromotion.name = form.name.value;
        actionPromotion.actionType = form.actionType.value;
        actionPromotion.description = form.description.value;
        actionPromotion.duration = form.duration.value;
        actionPromotion.durationHours = form.durationHours.value;
        actionPromotion.link = form.link.value;
        actionPromotion.levels = interventionLevels;
        actionPromotion.lorLevels = interventionLorLevels;

        this.props.handleSaveUpdateActionPromotion(actionPromotion);
    };

    handleAcceptActionPromotion = () => {
        this.setState({ showDialog: false, showAcceptActionPromotionConfirmationDialog: true });
    };

    handleDeclineActionPromotion = () => {
        this.setState({ showDialog: false, showDeclineActionPromotionConfirmationDialog: true, reasonDecline: "" });
    };

    handleCancelAcceptActionPromotion = () => {
        this.setState({ showDialog: true, showAcceptActionPromotionConfirmationDialog: false });
    };

    handleConfirmAcceptActionPromotion = () => {
        const { actionPromotion } = this.state;

        this.props.handleAcceptActionPromotion(actionPromotion.id);
    };

    renderAcceptActionPromotionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showAcceptActionPromotionConfirmationDialog}>
                <ModalHeader>Accept Action Promotion Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        This action will now be added to the Action List and be visible to all users.
                                        <br />
                                        The promoter will be informed.
                                        <br />
                                        OK to continue?
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmAcceptActionPromotion();
                        }}
                        disabled={this.state.isUpdating}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelAcceptActionPromotion} disabled={this.state.isUpdating}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleChangeReasonDecline = (event) => {
        let { name, value } = event.target;

        this.setState({ reasonDecline: value });
    };

    handleCancelDeclineActionPromotion = () => {
        this.setState({ showDialog: true, showDeclineActionPromotionConfirmationDialog: false });
    };

    handleConfirmDeclineActionPromotion = () => {
        const { actionPromotion } = this.state;

        this.props.handleDeclineActionPromotion(actionPromotion.id, this.state.reasonDecline);
    };

    renderDeclineActionPromotionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showDeclineActionPromotionConfirmationDialog}>
                <ModalHeader>Decline Action Promotion Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        Please enter a reason for declining. <br />
                                        This will be sent to the promoter.
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={12} sm={12}>
                                        <ReactTextareaAutosize
                                            minRows={3}
                                            style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                            aria-label="maximum height"
                                            value={this.state.reasonDecline}
                                            onChange={this.handleChangeReasonDecline}
                                            disabled={this.state.isUpdating}
                                            name="reasonDecline"
                                            id="reasonDecline"
                                            placeholder="Reason"
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmDeclineActionPromotion();
                        }}
                        disabled={this.state.reasonDecline == "" || this.state.isUpdating}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelDeclineActionPromotion} disabled={this.state.isUpdating}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    render() {
        const { form, interventionLevels, interventionLorLevels, editMode } = this.state;

        const lors = this.props.lors
            .flatMap((lor, index) => {
                return lor.levels.flatMap((level, index) => {
                    return {
                        lorName: lor.name,
                        lorCode: lor.lorCode,
                        level: level.levelNumber,
                    };
                });
            })
            .sort((a, b) => (`${a.lorCode}` > `${b.lorCode}` ? 1 : -1));

        const categories = this.props.categories
            .flatMap((category, index) => {
                return category.subCategories.flatMap((subCategory, index) => {
                    return subCategory.skills.flatMap((skill, index) => {
                        return skill.levels.flatMap((level, index) => {
                            return {
                                skillsName: skill.name,
                                skillsCode: skill.skillsCode,
                                level: level.level,
                            };
                        });
                    });
                });
            })
            .sort((a, b) => (`${a.skillsCode}` > `${b.skillsCode}` ? 1 : -1));

        const actionTypes = [{ name: null }, ...this.props.actionTypes];

        return (
            <React.Fragment>
                {this.renderAcceptActionPromotionConfirmation()}
                {this.renderDeclineActionPromotionConfirmation()}
                <Modal isOpen={this.state.showDialog} toggle={!this.state.isUpdating && !editMode ? this.togleDialogModal : null} size="xl">
                    <ModalHeader toggle={!this.state.isUpdating && !editMode ? this.togleDialogModal : null}>Review Action</ModalHeader>
                    <ModalBody>
                        <Form style={{ maxHeight: "500px", overflowX: "hidden", overflowY: "scroll" }}>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Name
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input type="text" name="name" id="name" value={form && form.name.value ? form.name.value : ""} invalid={form && form.name.touched && !form.name.valid} onChange={this.handleChange} disabled={this.state.isUpdating || !editMode} placeholder="Name" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Type
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input
                                                type="select"
                                                name="actionType"
                                                id="actionType"
                                                value={form && form.actionType.value ? form.actionType.value : ""}
                                                invalid={form && form.actionType.touched && !form.actionType.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating || !editMode}
                                            >
                                                {actionTypes.map((it, key) => (
                                                    <option key={key} value={it.name}>
                                                        {it.name ? it.name : "Select Type"}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Description
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <ReactTextareaAutosize
                                                minRows={5}
                                                style={!this.state.isUpdating ? textareaStyle : textareaStyleDisable}
                                                aria-label="maximum height"
                                                value={form && form.description.value ? form.description.value : ""}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating || !editMode}
                                                name="description"
                                                id="description"
                                                placeholder="Description"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>
                                            Website
                                        </Label>
                                        <Col md={10} sm={8}>
                                            <Input type="text" name="link" id="link" value={form && form.link.value ? form.link.value : ""} invalid={form && form.link.touched && !form.link.valid} onChange={this.handleChange} disabled={this.state.isUpdating || !editMode} placeholder="Link" />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label md={2} sm={4}>Duration</Label>
                                        <Label sm={1}>Days</Label>
                                        <Col md={4} sm={3}>
                                            <Input
                                                type="number"
                                                name="duration"
                                                id="duration"
                                                value={form && form.duration.value ? form.duration.value : ""}
                                                invalid={form && form.duration.touched && !form.duration.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating || !editMode}
                                                placeholder="Duration (days)"
                                            />
                                        </Col>
                                        <Label sm={1}>Hours</Label>
                                        <Col md={4} sm={3}>
                                            <Input
                                                type="number"
                                                name="durationHours"
                                                id="durationHours"
                                                value={form && form.durationHours.value ? form.durationHours.value : ""}
                                                invalid={form && form.durationHours.touched && !form.durationHours.valid}
                                                onChange={this.handleChange}
                                                disabled={this.state.isUpdating || !editMode}
                                                placeholder="Duration (hours)"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>Level of Responsibilities</Label>
                                        <Col md={12}>
                                            {lors &&
                                                lors
                                                    .filter((lor) => editMode || (interventionLorLevels && interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lor.lorCode}${lor.level}`) ? true : false))
                                                    .map((lor, index) => {
                                                        return (
                                                            <div key={index} style={{ width: "100px", float: "left" }}>
                                                                <CustomInput
                                                                    key={index}
                                                                    type="checkbox"
                                                                    name={`chkLors${lor.lorCode}${lor.level}`}
                                                                    id={`chkLors${lor.lorCode}${lor.level}`}
                                                                    checked={interventionLorLevels && interventionLorLevels.find((it) => `${it.lorCode}${it.level}` == `${lor.lorCode}${lor.level}`) ? true : false}
                                                                    label={`${lor.lorCode}${lor.level}`}
                                                                    onChange={() => this.handleLorsCheckedChange(lor.lorName, lor.lorCode, lor.level)}
                                                                    inline
                                                                    className="copy-skill-checkbox"
                                                                    disabled={!editMode}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={12}>Skills</Label>
                                        <Col md={12}>
                                            {categories &&
                                                categories
                                                    .filter((category) => editMode || (interventionLevels && interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${category.skillsCode}${category.level}`) ? true : false))
                                                    .map((category, index) => {
                                                        return (
                                                            <div key={index} style={{ width: "100px", float: "left" }}>
                                                                <CustomInput
                                                                    key={index}
                                                                    type="checkbox"
                                                                    name={`chkCategories${category.skillsCode}${category.level}`}
                                                                    id={`chkCategories${category.skillsCode}${category.level}`}
                                                                    checked={interventionLevels && interventionLevels.find((it) => `${it.skillsCode}${it.level}` == `${category.skillsCode}${category.level}`) ? true : false}
                                                                    label={`${category.skillsCode}${category.level}`}
                                                                    onChange={() => this.handleSkillsCheckedChange(category.skillsName, category.skillsCode, category.level)}
                                                                    inline
                                                                    className="copy-skill-checkbox"
                                                                    disabled={!editMode}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {!editMode && (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleEdit();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Edit
                                </Button>
                                <Button
                                    color="success"
                                    onClick={() => {
                                        this.handleAcceptActionPromotion();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Accept
                                </Button>
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        this.handleDeclineActionPromotion();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Decline
                                </Button>
                            </React.Fragment>
                        )}
                        {editMode && (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleSave();
                                    }}
                                    disabled={!this.state.isFormValid || this.state.isUpdating}
                                >
                                    Save
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        this.handleCancel();
                                    }}
                                    disabled={this.state.isUpdating}
                                >
                                    Cancel
                                </Button>
                            </React.Fragment>
                        )}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ManageActionPromotionDetail;
