import * as evidenceActions from "../../store/actions/evidenceAction";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "../translator/ContentTranslator";
import AddNewEvidenceForm from "./AddNewEvidenceForm";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const buttonActionPrimaryStyle = {
    height: "32px",
    width: "80px",
};

class EvidenceResponsibilityBody extends React.Component {
    static displayName = EvidenceResponsibilityBody.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAddEvidenceForm: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showHiddenSkills !== this.props.showHiddenSkills) {
            this.setState({ showAddEvidenceForm: false });
        }
    }

    handleAddEvidenceClick = () => {
        this.setState({
            showAddEvidenceForm: !this.state.showAddEvidenceForm,
        });
    };

    handleCloseAddEvidence = () => {
        this.setState({
            showAddEvidenceForm: false,
        });
    };

    handleEvidenceAdded = (addedEvidence) => {
        this.handleCloseAddEvidence();
        this.props.handleEvidenceAdded(addedEvidence);
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const { showAll, levelIndex, skill, level, categories, evidences } = this.props;

        let evidencesSkills = evidences.map((evidence) => {
            return {
                id: evidence.id,
                skills: [
                    ...evidence.lorLevels.map((lor) => {
                        return lor.lorCode + lor.level;
                    }),
                    ...evidence.skillLevels.map((lor) => {
                        return lor.skillCode + lor.level;
                    }),
                ],
            };
        });

        if (skill) {
            const evidenceAdded = evidencesSkills.find((evidencesSkill) => evidencesSkill.skills.some((it) => it == skill.skillsCode + level.level)) ? true : false;

            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skill.name && it.skillsCode == skill.skillsCode)));
            const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == skill.name && it.skillsCode == skill.skillsCode));
            const categorySkill = subCategory && subCategory.skills.find((it) => it.name == skill.name && it.skillsCode == skill.skillsCode);
            const skillLevel = categorySkill && categorySkill.levels.find((it) => it.level == level.level);

            const defaultSelectedSkill = {
                skillLevelId: skillLevel ? skillLevel.id : null,
                skill: skill.name,
                skillCode: skill.skillsCode,
                level: level.level,
            };

            return (
                <React.Fragment>
                    <tr style={{ display: !this.props.skillHidden ? "" : "none" }}>
                        <td className={"pt-1 pb-1 border-0"}></td>
                        <td className={"pt-1 border-0"}>{(showAll ? levelIndex === 0 : level.skillNameShown) && `${this.contentTranslator(this.props.contentTranslations, "Skills", skill.name, skill.name)} (${skill.skillsCode})`}</td>
                        <td colSpan="2" className={"pt-1 pb-1 td-item "}>
                            {`${skill.skillsCode}${level.level} - `}
                            <label
                                style={{ cursor: "pointer", color: "#212529", textDecoration: "underline" }}
                                onClick={() =>
                                    this.props.openSkillLevelDescriptionModal({
                                        skillsCode: skill.skillsCode,
                                        level: level.level,
                                        description: this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skill.skillsCode} ${level.level} Description`, level.description),
                                    })
                                }
                            >
                                <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceViewDescription" contentText="View Description" />
                            </label>
                        </td>
                        <td className={"pt-1 pb-1 td-item "}>
                            {!this.state.showAddEvidenceForm ? (
                                <button className={`btn btn-primary ${evidenceAdded ? "btnAmber-Style" : "btnPrimary-Style"}`} style={buttonActionPrimaryStyle} onClick={this.handleAddEvidenceClick} disabled={isAdmin}>
                                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
                                </button>
                            ) : (
                                <button className={`btn btn-secondary`} style={buttonActionPrimaryStyle} onClick={this.handleAddEvidenceClick}>
                                    <FontAwesomeIcon icon={faMinus} style={{ fontSize: "13px", position: "relative", top: "-5px" }} />
                                </button>
                            )}
                        </td>
                    </tr>
                    {this.state.showAddEvidenceForm && (
                        <tr>
                            <td className="border-0" colSpan="5">
                                <div style={{ float: "right" }}>
                                    <AddNewEvidenceForm
                                        categories={this.props.categories}
                                        lors={this.props.lors}
                                        evidences={this.props.evidences}
                                        lorProfiles={this.props.lorProfiles}
                                        skillProfiles={this.props.skillProfiles}
                                        defaultSelectedSkill={defaultSelectedSkill}
                                        handleClose={this.handleCloseAddEvidence}
                                        handleEvidenceAdded={this.handleEvidenceAdded}
                                        generateAlert={this.props.generateAlert}
                                        showHiddenSkills={this.props.showHiddenSkills}
                                    ></AddNewEvidenceForm>
                                </div>
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            );
        } else {
            return <React.Fragment></React.Fragment>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidence: bindActionCreators(evidenceActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceResponsibilityBody);
