import * as authActions from "../../store/actions/authAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as contentActions from "../../store/actions/contentAction";
import * as jobActions from "../../store/actions/jobAction";
import * as lorActions from "../../store/actions/lorAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import TableProfile from "./TableProfile";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Col, Container, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const buttonStyle = {
    marginLeft: "10px",
};

const labelCaptionStyle = {
    fontWeight: "bold",
};

class JobsProfile extends React.Component {
    static displayName = JobsProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            contentLoading: true,
            jobLoading: true,
            alerts: [],
            showMoreDetail: false,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        var jobId = params.jobId;

        this.props.onAuth.authCheckState().then(() => {
            if (this.props.language) {
                this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaProfile-Lors-Skills").then(() => {
                    this.setState({ contentLoading: this.props.contentLoading });
                });

                this.props.onJob.getJob(jobId).then(() => {
                    if (this.props.job) {
                        Promise.all([
                            this.props.onLor.getLors(),
                            this.props.onCategory.getCategories(),
                            this.props.onCategory.getExtraFrameworkCapabilities(),
                            this.props.onCategory.getExtraFrameworkLevels(),
                            this.props.onJob.getJobLorProfiles(jobId),
                            this.props.onJob.getJobSkillProfiles(jobId),
                            this.props.onJob.getJobSkillAttributes(jobId),
                        ])
                            .then(() => {
                                this.setState({ jobLoading: this.props.jobLoading || this.props.lorLoading || this.props.categoryLoading });
                            })
                            .catch(() => {
                                this.setState({
                                    jobLoading: false,
                                });
                            });
                    } else {
                        this.setState({ jobLoading: this.props.jobLoading });
                    }
                });
            }

            this.setState({ loading: this.props.loading });
        });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChangeShowDetail = () => {
        this.setState({
            showDetail: !this.state.showDetail,
        });
    };

    handleChangeShowMoreDetail = () => {
        this.setState({
            showMoreDetail: !this.state.showMoreDetail,
        });
    };

    render() {
        const { job } = this.props;
        const { showMoreDetail } = this.state;

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {!this.state.loading && !this.state.jobLoading && !this.state.contentLoading ? (
                    <React.Fragment>
                        {job ? (
                            <ContentWrapperLg>
                                <Container className="p-0">
                                    {job && <p className={"page-title"}>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileJobSkillRequirement", "Skill Requirement - {s1}"), `${job.name}`)}</p>}
                                    <Row style={{ paddingBottom: "10px", fontSize: "12px" }}>
                                        <Col md={12} sm={12}>
                                            <Row>
                                                <Label sm={12} style={{ fontWeight: "bold", fontSize: "14px" }}>
                                                    <ContentTranslator page="SfiaAll" name="JobDetail" contentText="Requirement Detail" />
                                                </Label>
                                            </Row>
                                            <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    <ContentTranslator page="SfiaAll" name="JobReference" contentText="Unique reference" />
                                                </Label>
                                                <Label sm={9}>{job.reference}</Label>
                                            </Row>
                                            <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    <ContentTranslator page="SfiaAll" name="JobSeniority" contentText="Seniority" />
                                                </Label>
                                                <Label sm={9}>{job.seniority}</Label>
                                            </Row>
                                            <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    <ContentTranslator page="SfiaAll" name="JobEmploymentStatus" contentText="Employment Status" />
                                                </Label>
                                                <Label sm={9}>{job.employmentStatus}</Label>
                                            </Row>
                                            <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    <ContentTranslator page="SfiaAll" name="JobPurpose" contentText="Purpose" />
                                                </Label>
                                                <Label sm={9}>{job.purpose}</Label>
                                            </Row>
                                            <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                <Label sm={3} style={labelCaptionStyle}>
                                                    <ContentTranslator page="SfiaAll" name="JobQualificationRequirements" contentText="Qualification Requirements" />
                                                </Label>
                                                <Label sm={9}>{job.qualificationRequirements}</Label>
                                            </Row>
                                            {showMoreDetail && (
                                                <React.Fragment>
                                                    {job.department && job.department != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobDepartment" contentText="Department" />
                                                            </Label>
                                                            <Label sm={9}>{job.department}</Label>
                                                        </Row>
                                                    )}
                                                    {job.location && job.location != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobLocation" contentText="Location" />
                                                            </Label>
                                                            <Label sm={9}>{job.location}</Label>
                                                        </Row>
                                                    )}
                                                    {job.fte && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobFTECount" contentText="FTE Count" />
                                                            </Label>
                                                            <Label sm={9}>{job.fte}</Label>
                                                        </Row>
                                                    )}
                                                    {job.keyAccountibilities && job.keyAccountibilities != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobKeyAccountibilities" contentText="Key Accountibilities" />
                                                            </Label>
                                                            <Label sm={9}>{job.keyAccountibilities}</Label>
                                                        </Row>
                                                    )}
                                                    {job.keyProcesses && job.keyProcesses != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobKeyProcesses" contentText="Key Processes" />
                                                            </Label>
                                                            <Label sm={9}>{job.keyProcesses}</Label>
                                                        </Row>
                                                    )}
                                                    {job.educationRequirements && job.educationRequirements != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobEducationRequirements" contentText="Education Requirements" />
                                                            </Label>
                                                            <Label sm={9}>{job.educationRequirements}</Label>
                                                        </Row>
                                                    )}
                                                    {job.otherRequirements && job.otherRequirements != "" && (
                                                        <Row style={{ borderBottom: "1px solid #ced4da" }}>
                                                            <Label sm={3} style={labelCaptionStyle}>
                                                                <ContentTranslator page="SfiaAll" name="JobOtherRequirements" contentText="Other Requirements" />
                                                            </Label>
                                                            <Label sm={9}>{job.otherRequirements}</Label>
                                                        </Row>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            <Row>
                                                <Label sm={3} style={labelCaptionStyle}></Label>
                                                <Label sm={9}>
                                                    <button className="btn btn-light btnSecondary-Style" onClick={this.handleChangeShowMoreDetail}>
                                                        {showMoreDetail ? <ContentTranslator page="SfiaAll" name="JobShowLessDetail" contentText="Show Less Requirement Detail" /> : <ContentTranslator page="SfiaAll" name="JobShowMoreDetail" contentText="Show More Requirement Detail" />}
                                                    </button>
                                                </Label>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                        <Col
                                            sm="12"
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowDetail} disabled={this.state.showEdit}>
                                                {this.state.showDetail || this.state.showEdit
                                                    ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonHideDetail", "Hide Detail")
                                                    : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonShowDetail", "Show Detail")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Container>
                                <TableProfile
                                    isShowJobProfile={true}
                                    loading={this.state.loading}
                                    showDetail={this.state.showDetail}
                                    showEdit={false}
                                    lorLoading={this.state.lorLoading}
                                    lors={this.props.lors}
                                    selectedJobMatchType={{
                                        label: "None",
                                        value: "None",
                                    }}
                                    selectedprofileType={{
                                        label: "Self Assessed",
                                        value: "Self Assessed",
                                    }}
                                    jobLorProfiles={this.props.jobLorProfiles}
                                    jobSkillProfiles={this.props.jobSkillProfiles}
                                    jobSkillAttributes={this.props.jobSkillAttributes}
                                    lorProfiles={this.props.jobLorProfiles}
                                    categories={this.props.categories}
                                    extraFrameworkCapabilities={this.props.extraFrameworkCapabilities}
                                    extraFrameworkLevels={this.props.extraFrameworkLevels}
                                    skillProfiles={this.props.jobSkillProfiles}
                                ></TableProfile>
                            </ContentWrapperLg>
                        ) : (
                            <ContentWrapperLg>
                                <p className="page-title">Job detail not found</p>
                            </ContentWrapperLg>
                        )}
                    </React.Fragment>
                ) : (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        token: state.auth.token,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        jobLoading: state.job.loading,
        jobError: state.job.error,
        jobLoading: state.job.loading,
        job: state.job.job,

        jobLorProfiles: state.job.jobLorProfiles,
        jobSkillProfiles: state.job.jobSkillProfiles,
        jobSkillAttributes: state.job.jobSkillAttributes,

        lorLoading: state.lor.loading,
        lors: state.lor.lors,

        categoryLoading: state.category.loading,
        categories: state.category.categories,
        extraFrameworkCapabilities: state.category.extraFrameworkCapabilities,
        extraFrameworkLevels: state.category.extraFrameworkLevels,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsProfile);
