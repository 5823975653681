import EvidenceAPI from "../../common/api/service/EvidenceService";
import * as authActions from "../../store/actions/authAction";
import * as personActions from "../../store/actions/personAction";
import { contentTranslator } from "../../utils/translatorUtils";
import ContentTranslator from "./../translator/ContentTranslator";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink as RRNavLink } from "react-router-dom";
import { Collapse, Container, Nav, NavItem, NavLink, Navbar, NavbarToggler } from "reactstrap";
import { bindActionCreators } from "redux";

const navbarStyle = {
    borderRadius: "10px",
    fontFamily: "IBMPlexSans-Bold",
};

const navlinkLeftStyle = {
    fontSize: "11px",
    color: "#FFFFFF",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "20px",
    // paddingBottom: "20px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "0.5px solid #194293",
    height: "57px",
};

const navlinkCenterStyle = {
    fontSize: "11px",
    color: "#FFFFFF",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "20px",
    // paddingBottom: "20px",
    borderRight: "0.5px solid #194293",
    height: "57px",
};

const navlinkLeftStyle10 = {
    fontSize: "11px",
    color: "#FFFFFF",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "13px",
    // paddingBottom: "20px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderRight: "0.5px solid #194293",
    height: "57px",
};

const navlinkCenterStyle10 = {
    fontSize: "11px",
    color: "#FFFFFF",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "13px",
    // paddingBottom: "20px",
    borderRight: "0.5px solid #194293",
    height: "57px",
};

const navItem = {
    height: "57px",
};

class NavSecondary extends React.Component {
    static displayName = NavSecondary.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isDdOpen: false,
            //setIsOpen: false,
            contentTranslations: [],
            hasBadges: false,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    backToProfile = () => {
        Promise.resolve(this.props.onAuth.myPeopleClearManagedRelationship()).then(() => {
            Promise.resolve(this.props.onPerson.clearPersonAccountState()).then(() => {
                this.props.history.push("/");
            });
        });
    };

    navigateBack = () => {
        Promise.resolve(this.props.onAuth.myPeopleClearManagedRelationship()).then(() => {
            Promise.resolve(this.props.onPerson.clearPersonAccountState()).then(() => {
                localStorage.setItem("routeGoBack", "TRUE");

                const routeAdminFrom = localStorage.getItem("routeAdminFrom");
                if (routeAdminFrom) {
                    this.props.history.push(routeAdminFrom);
                } else {
                    this.props.history.goBack();
                }
            });
        });
    };

    componentDidMount() {
        const fetchedBadges = async () => {
            const badges = await EvidenceAPI.getMyBadges();
            this.setState({
                hasBadges: badges && Array.isArray(badges) && badges.length > 0,
            });
        };
        fetchedBadges();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentTranslations !== this.props.contentTranslations || prevProps.language !== this.props.language) {
            this.setState({ contentTranslations: this.props.contentTranslations });
        }
    }

    render() {
        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        let numberOfMenu = 0;

        const manageProfileByAdminPopup = localStorage.getItem("manageProfileByAdminPopup") && localStorage.getItem("manageProfileByAdminPopup") == "TRUE" ? true : false;

        if (authModes.includes("Planner") && !authModes.includes("Admin")) {
            numberOfMenu += 4;
        } else if (authModes.includes("Planner") && authModes.includes("Admin")) {
            numberOfMenu += 4.5;
        } else if (authModes.includes("Admin") && !authModes.includes("Planner")) {
            // if (this.props.managedPeople) {
            //     numberOfMenu += 4.5;
            // } else {
            //     numberOfMenu += 0.5;
            // }

            numberOfMenu += 0.5;
        }

        if (authModes.includes("Planner") && !manageProfileByAdminPopup) {
            if (this.props.hasPeoples) {
                numberOfMenu += 2;
            }

            if (this.props.isEndorser) {
                numberOfMenu += 1;
            }

            if ((this.props.hasPeoples || this.props.isEndorser) && this.props.managedPeople) {
                numberOfMenu += 1;
            }
        }

        if (authModes.includes("Planner") && !authModes.includes("Admin") && this.props.ownJobs) {
            //Add Manage Requirements Button
            numberOfMenu += 1;
        }

        let showBackButton = false;

        if (
            manageProfileByAdminPopup &&
            (window.location.pathname.includes("/SfiaProfile/Manage") ||
                window.location.pathname.includes("/ActionPlan/Manage") ||
                window.location.pathname.includes("/Evidence/Manage") ||
                window.location.pathname.includes("/Notes/Manage") ||
                window.location.pathname.includes("/SfiaProfile/Certify") ||
                window.location.pathname.includes("/SfiaProfile/TeamAnalytics"))
        ) {
            //Add Back Button
            showBackButton = true;
        }

        return (
            <Container
                style={{
                    background: "#6699FF",
                    borderRadius: "10px",
                    marginBottom: "30px",
                }}
                className={this.props.isAdmin ? "p-0 containerMax" : "p-0"}
            >
                <Navbar className="navbar-expand-md navbar-toggleable-md p-0" light>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse isOpen={!this.state.collapsed} navbar>
                        <Nav style={navbarStyle} className="mr-auto" navbar>
                            {authModes.includes("Admin") && (
                                <React.Fragment>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={authModes.includes("Planner") ? (numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle) : numberOfMenu >= 8 ? navlinkLeftStyle10 : navlinkLeftStyle} to="/admin">
                                            <ContentTranslator page="SfiaMenu" name="SfiaMenuAdmin" contentText="Admin" />
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            )}
                            {authModes.includes("Planner") && !manageProfileByAdminPopup && (
                                <React.Fragment>
                                    <NavItem style={navItem}>
                                        <NavLink
                                            tag={RRNavLink}
                                            activeClassName="custNavSecondActive"
                                            style={numberOfMenu >= 8 ? (authModes.includes("Admin") ? navlinkCenterStyle10 : navlinkLeftStyle10) : authModes.includes("Admin") ? navlinkCenterStyle : navlinkLeftStyle}
                                            exact
                                            to={this.props.managedPeople ? `/SfiaProfile/Manage?email=${this.props.managedPeople.personB.email}` : "/SfiaProfile/"}
                                        >
                                            {this.contentTranslator(this.state.contentTranslations, "SfiaProfileMenu", "SfiaProfileMenuSkillsProfile", "Skills Profile")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink
                                            tag={RRNavLink}
                                            activeClassName="custNavSecondActive"
                                            style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle}
                                            to={this.props.managedPeople ? `/SfiaProfile/ActionPlan/Manage?email=${this.props.managedPeople.personB.email}` : "/SfiaProfile/ActionPlan/"}
                                        >
                                            {this.contentTranslator(this.state.contentTranslations, "SfiaProfileMenu", "SfiaProfileMenuActionPlan", "Action plan")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink
                                            tag={RRNavLink}
                                            activeClassName="custNavSecondActive"
                                            style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle}
                                            to={this.props.managedPeople ? `/SfiaProfile/Evidence/Manage?email=${this.props.managedPeople.personB.email}` : "/SfiaProfile/Evidence/"}
                                        >
                                            <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuEvidence" contentText="Evidence" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink
                                            tag={RRNavLink}
                                            activeClassName="custNavSecondActive"
                                            style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle}
                                            to={this.props.managedPeople ? `/SfiaProfile/Notes/Manage?email=${this.props.managedPeople.personB.email}` : "/SfiaProfile/Notes/"}
                                        >
                                            <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuNotes" contentText="Notes" />
                                        </NavLink>
                                    </NavItem>
                                    {this.props.hasPeoples && !manageProfileByAdminPopup && (
                                        <NavItem style={navItem}>
                                            <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to="/SfiaProfile/MyPeoples">
                                                <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuMyPeople" contentText="My People" />
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {this.props.hasPeoples && !manageProfileByAdminPopup && (
                                        <NavItem style={navItem}>
                                            <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to="/SfiaProfile/TeamAnalytics">
                                                <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuTeamAnalytics" contentText="Team Analytics" />
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {this.state.hasBadges && (
                                        <NavItem style={navItem}>
                                            <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to="/SfiaProfile/DigitalWallet">
                                                <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuDigitalWallet" contentText="Digital Wallet" />
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {(this.props.hasPeoples || this.props.isEndorser) && !manageProfileByAdminPopup && this.props.managedPeople && (
                                        <NavItem style={navItem}>
                                            <NavLink tag={RRNavLink} style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to="#" onClick={() => this.backToProfile()}>
                                                <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuReturnToProfile" contentText="Return to My Profile" />
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </React.Fragment>
                            )}
                            {authModes.includes("Admin") && manageProfileByAdminPopup && this.props.managedPeople && (
                                <React.Fragment>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} exact to={`/SfiaProfile/Manage?email=${this.props.managedPeople.personB.email}`}>
                                            {this.contentTranslator(this.state.contentTranslations, "SfiaProfileMenu", "SfiaProfileMenuSkillsProfile", "Skills Profile")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to={`/SfiaProfile/ActionPlan/Manage?email=${this.props.managedPeople.personB.email}`}>
                                            {this.contentTranslator(this.state.contentTranslations, "SfiaProfileMenu", "SfiaProfileMenuActionPlan", "Action plan")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to={`/SfiaProfile/Evidence/Manage?email=${this.props.managedPeople.personB.email}`}>
                                            <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuEvidence" contentText="Evidence" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to={`/SfiaProfile/Notes/Manage?email=${this.props.managedPeople.personB.email}`}>
                                            <ContentTranslator page="SfiaProfileMenu" name="SfiaProfileMenuNotes" contentText="Notes" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} to={`/SfiaProfile/TeamAnalytics/skill-change?email=${this.props.managedPeople.personB.email}`}>
                                            Skill Changes
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            )}
                            {authModes.includes("Planner") && !authModes.includes("Admin") && this.props.ownJobs && (
                                <React.Fragment>
                                    <NavItem style={navItem}>
                                        <NavLink tag={RRNavLink} activeClassName="custNavSecondActive" style={authModes.includes("Planner") ? (numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle) : numberOfMenu >= 8 ? navlinkLeftStyle10 : navlinkLeftStyle} to="/SfiaProfile/Requirements">
                                            Manage Requirements
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            )}
                            {showBackButton && (
                                <React.Fragment>
                                    <NavItem style={navItem}>
                                        <NavLink style={numberOfMenu >= 8 ? navlinkCenterStyle10 : navlinkCenterStyle} onClick={() => this.navigateBack()}>
                                            Back
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        ownJobs: state.auth.ownJobs,
        managedPeople: state.auth.managedPeople,
        language: state.language.language,
        contentTranslations: state.content.contentTranslations,

        authMode: state.auth.authMode,
        loginWithTeams: state.auth.loginWithTeams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavSecondary));
