import * as authActions from "../../../store/actions/authAction";
import * as personActions from "../../../store/actions/personAction";
import * as skillProfileActions from "../../../store/actions/skillProfileAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableClearSearch } from "../../common/SkillsTxTableClearSearch";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import PersonAssessorCell from "./PersonAssessorCell";
import PersonEndorserCell from "./PersonEndorserCell";
import { HubConnectionBuilder } from "@aspnet/signalr";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSync, faDownload, faSquareCheck, faSquareMinus, faPenToSquare, faClipboardCheck, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");
const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;
const messageHubHostname = process.env.NODE_ENV === "development" ? process.env.REACT_APP_MESSAGE_HUB_ENDPOINT : "/messagehub";

class ManageEndorsement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            persons: [],
            endorserPersons: [],
            assessorPersons: [],
            isUpdating: false,
            searchProperties: ["email", "firstName", "lastName", "endorsedBy", "endorsementLatestStatus"],
            modeGenerateReports: false,
            personsSkillProfileSelected: [],
            personsEndorsedSelected: [],
            personsActionSelected: [],
            loadingPrint: false,
            filterAssignedToMe: false,
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const routeGoBack = localStorage.getItem("routeGoBack");
        localStorage.setItem("routeAdminFrom", "/admin/endorsements");
        localStorage.setItem("manageProfileByAdminPopup", "FALSE");

        let repopulate = true;
        if (routeGoBack == "TRUE") {
            repopulate = false;
        }

        this.props.onAuth.authCheckState().then(() => {
            //this.connectSignalR();
            if (repopulate || !this.props.persons || (this.props.persons && this.props.persons.length == 0)) {
                this.props.onAdminPerson.getPersonsAdmin().then(() => {
                    const endorserPersons = this.props.personsAdmin
                        ? [
                              ...this.props.personsAdmin
                                  .filter(
                                      (it) =>
                                          !it.inactive &&
                                          it.roles &&
                                          it.roles.some((it) => it.role == "Admin") &&
                                          it.pageAssignments &&
                                          it.pageAssignments.some((it) => it.pageName == "Manage Endorsements and Reports" && it.operations && it.operations.some((it) => it.operation == "Open" && it.allow) && it.operations.some((it) => it.operation == "Approve" && it.allow))
                                  )
                                  .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                          ]
                        : [];

                    const assessorPersons = this.props.personsAdmin ? [...this.props.personsAdmin.filter((it) => !it.inactive && it.roles && it.roles.some((it) => it.role == "Admin")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))] : [];

                    this.setState(
                        {
                            endorserPersons,
                            assessorPersons,
                        },
                        () => {
                            this.initData();
                        }
                    );
                });
            } else {
                const endorserPersons = this.props.personsAdmin
                    ? [
                          ...this.props.personsAdmin
                              .filter(
                                  (it) =>
                                      !it.inactive &&
                                      it.roles &&
                                      it.roles.some((it) => it.role == "Admin") &&
                                      it.pageAssignments &&
                                      it.pageAssignments.some((it) => it.pageName == "Manage Endorsements and Reports" && it.operations && it.operations.some((it) => it.operation == "Open" && it.allow) && it.operations.some((it) => it.operation == "Approve" && it.allow))
                              )
                              .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                      ]
                    : [];

                const assessorPersons = this.props.personsAdmin ? [...this.props.personsAdmin.filter((it) => !it.inactive && it.roles && it.roles.some((it) => it.role == "Admin")).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))] : [];

                const filterAssignedToMe = this.state.filterAssignedToMe;
                let persons = this.reformPersons(this.props.persons.filter((it) => !it.inactive));
                if (filterAssignedToMe) {
                    persons = persons.filter((it) => !it.inactive && it.endorsedById == this.props.personId);
                }

                if (this.state.modeGenerateReports) {
                    persons.forEach((it) => {
                        it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
                        it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
                        it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
                    });
                }

                this.setState({
                    endorserPersons,
                    assessorPersons,
                    persons: persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                    personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
                    personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
                    personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id),
                    loading: false,
                });
            }
        });
    }

    componentWillUnmount() {
        localStorage.setItem("routeGoBack", "FALSE");
        //this.disconnectSignalR();
    }

    connectSignalR = () => {
        const personId = this.props.personId;
        this.connection = new HubConnectionBuilder().withUrl(messageHubHostname).build();

        this.connection.on(`UpdatePersonEndorsementNotification${personId}`, (response) => {
            if (response.action == "PersonEndorsementUpdate") {
                if (response.status == "Success") {
                    let updateEndorsement = response.data;
                    this.props.onAdminPerson.updatePersonEndorsement(updateEndorsement);
                }
            }
        });

        this.connection.start().catch((err) => console.error(err.toString()));
    };

    disconnectSignalR = () => {
        if (this.connection) this.connection.stop();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons && this.props.persons) {
            this.setState({ persons: this.reformPersons(this.props.persons.filter((it) => !it.inactive).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))) });
        }

        if (prevProps.mode !== this.props.mode && prevProps.mode) {
            this.handleRefresh();
        }
    }

    reformPersons = (persons) => {
        return persons.map((it) => {
            return {
                id: it.id,
                idSkillProfile: it.id,
                idActionPlan: it.id,
                idEndorsedProfile: it.id,
                companyId: it.companyId,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                completeName: `${it.firstName}${it.lastName ? ` ${it.lastName}` : ""}${it.email ? ` ${it.email}` : ""}`,
                salutation: it.salutation,
                position: it.position,
                inactive: it.inactive,
                personType: it.personType,
                accessGroup: it.accessGroup,
                allowNotifications: it.allowNotifications,
                lastActivityOn: it.lastActivityOn,
                lastActivitySystem: it.lastActivitySystem,
                createdOn: it.createdOn,
                countryCode: it.countryCode,
                country: it.country,
                location: it.location,
                selfAssessmentReason: it.selfAssessmentReason,
                encryptedId: it.encryptedId,
                team: it.team,
                usePreferredName: it.usePreferredName,
                other: it.other,
                department: it.department,
                businessUnit: it.businessUnit,
                division: it.division,
                territory: it.territory,
                costCentre: it.costCentre,
                supervisor: it.supervisor,
                validatedDate: it.validatedDate,
                isCandidate: it.isCandidate,
                isAnalyticsUser: it.isAnalyticsUser,
                leaveOn: it.leaveOn,
                isAssigned: it.isAssigned,
                isInvited: it.isInvited,
                invitedOn: it.invitedOn,
                accounts: it.accounts ? [...it.accounts] : null,
                teams: it.teams ? [...it.teams] : null,
                profilePictureUrl: it.profilePictureUrl,
                activityStatementInterval: it.activityStatementInterval,
                lastUpdatedOn: it.lastUpdatedOn,
                lastUpdatedVersion: it.lastUpdatedVersion,
                latestAssessmentId: it.latestAssessmentId,
                cartridgeId: it.cartridgeId,
                hasOutstandingAction: it.hasOutstandingAction,
                roles: it.roles ? [...it.roles] : null,
                strRoles: it.roles
                    ? it.roles
                          .map((it) => {
                              return it.role;
                          })
                          .join(", ")
                    : "User",
                groups: it.groups ? [...it.groups] : null,
                totalQuestions: it.totalQuestions,
                answeredQuestions: it.answeredQuestions,
                totalTimeTaken: it.totalTimeTaken,
                percentage: it.percentage,
                hasEndorseProfile: it.hasEndorseProfile,
                hasAction: it.hasAction,

                selfAssessedReportGenerated: it.selfAssessedReportGenerated,
                lastSelfAssessedReportGeneratedVersion: it.lastSelfAssessedReportGeneratedVersion,
                lastSelfAssessedReportGeneratedOn: it.lastSelfAssessedReportGeneratedOn,
                actionReportGenerated: it.actionReportGenerated,
                lastActionReportGeneratedVersion: it.lastActionReportGeneratedVersion,
                lastActionReportGeneratedOn: it.lastActionReportGeneratedOn,
                endorsedReportGenerated: it.endorsedReportGenerated,
                lastEndorsedReportGeneratedVersion: it.lastEndorsedReportGeneratedVersion,
                lastEndorsedReportGeneratedOn: it.lastEndorsedReportGeneratedOn,

                latestInvitationEmailReminderNumber: it.latestInvitationEmailReminderNumber,
                latestInvitationEmailReminderSentOn: it.latestInvitationEmailReminderSentOn,
                resetPasswordRequested: it.resetPasswordRequested,
                upRelationships: it.upRelationships,
                downRelationships: it.downRelationships,
                havePassword: it.havePassword,
                endorsedById: it.endorsedById,
                endorsedBy: it.endorsedBy ? it.endorsedBy : "",
                endorsementLatestStatus: it.endorsementLatestStatus ? it.endorsementLatestStatus : "",
                endorsementLastUpdatedById: it.endorsementLastUpdatedById,
                endorsementLastUpdatedBy: it.endorsementLastUpdatedBy,
                endorsementLastUpdated: it.endorsementLastUpdated,
                endorsementLastUpdatedVersion: it.endorsementLastUpdatedVersion,
                endorsementLastReviewedById: it.endorsementLastReviewedById,
                endorsementLastReviewedBy: it.endorsementLastReviewedBy,
                endorsementLastReviewed: it.endorsementLastReviewed,
                endorsementLastReviewedVersion: it.endorsementLastReviewedVersion,
                endorsementLastApprovedById: it.endorsementLastApprovedById,
                endorsementLastApprovedBy: it.endorsementLastApprovedBy,
                endorsementLastApproved: it.endorsementLastApproved,
                endorsementLastApprovedVersion: it.endorsementLastApprovedVersion,
                assessorId: it.assessorId,
                assessorName: it.assessorName ? it.assessorName : "",
            };
        });
    };

    initData = () => {
        const filterAssignedToMe = this.state.filterAssignedToMe;
        this.props.onAdminPerson.getPersonsIncEndorsements().then(() => {
            let persons = this.reformPersons(this.props.persons.filter((it) => !it.inactive));
            if (filterAssignedToMe) {
                persons = persons.filter((it) => !it.inactive && it.endorsedById == this.props.personId);
            }

            if (this.state.modeGenerateReports) {
                persons.forEach((it) => {
                    it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
                    it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
                    it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
                });
            }

            this.setState({
                persons: persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)),
                personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
                personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
                personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id),
            });
        });

        this.setState({ loading: this.props.loading });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleRowClickDummy = () => {};

    handleRowSkillProfileClick = (row) => {
        if (!this.state.loadingPrint) {
            const persons = this.state.persons;
            const updatedData = persons.find((it) => it.id == row.id);
            updatedData.toggleSkillProfileSelected = !updatedData.toggleSkillProfileSelected;

            this.setState({ persons: [...persons], personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id) });
        }
    };

    handleSelectSkillProfileAll = () => {
        const { persons, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        if (filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleSkillProfileSelected && it.percentage >= 1).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = false;
        });

        if (selectAll) {
            persons
                .filter((it) => it.percentage >= 1)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleSkillProfileSelected = true;
                    }
                });
        }

        this.setState({ persons: [...persons], personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id) });
    };

    handleRowEndorsedClick = (row) => {
        if (!this.state.loadingPrint) {
            const persons = this.state.persons;
            const updatedData = persons.find((it) => it.id == row.id);
            updatedData.toggleEndorsedSelected = !updatedData.toggleEndorsedSelected;

            this.setState({ persons: [...persons], personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id) });
        }
    };

    handleSelectEndorsedAll = () => {
        const { persons, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        if (filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleEndorsedSelected = false;
        });

        if (selectAll) {
            persons
                .filter((it) => it.hasEndorseProfile)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleEndorsedSelected = true;
                    }
                });
        }

        this.setState({ persons: [...persons], personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id) });
    };

    handleRowActionClick = (row) => {
        if (!this.state.loadingPrint) {
            const persons = this.state.persons;
            const updatedData = persons.find((it) => it.id == row.id);
            updatedData.toggleActionSelected = !updatedData.toggleActionSelected;

            this.setState({ persons: [...persons], personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id) });
        }
    };

    handleSelectActionAll = () => {
        const { persons, searchKeyword, searchProperties } = this.state;
        let selectAll = false;

        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        if (filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0) {
            selectAll = true;
        } else if (filtered.filter((it) => !it.toggleActionSelected && it.hasAction).length == 0) {
            selectAll = false;
        } else {
            selectAll = true;
        }

        persons.forEach((it) => {
            it.toggleActionSelected = false;
        });

        if (selectAll) {
            persons
                .filter((it) => it.hasAction)
                .forEach((it) => {
                    if (filtered.find((f) => f.id == it.id)) {
                        it.toggleActionSelected = true;
                    }
                });
        }

        this.setState({ persons: [...persons], personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id) });
    };

    handleClearSelection = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = false;
            it.toggleEndorsedSelected = false;
            it.toggleActionSelected = false;
        });

        this.setState({ persons: [...persons], personsSkillProfileSelected: [], personsEndorsedSelected: [], personsActionSelected: [] });
    };

    editProfile = (person) => {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("profileType", "Endorsed");
        localStorage.setItem("manageEndorsementOperation", "UPDATE");
        localStorage.setItem("manageProfileByAdminPopup", "TRUE");

        this.props.history.push(`/SfiaProfile/Manage?email=${person.email}`);
    };

    approveProfile = (person) => {
        localStorage.setItem("showApprovedProfileOnly", "FALSE");
        localStorage.setItem("profileType", "Endorsed");
        localStorage.setItem("manageEndorsementOperation", "APPROVE");
        localStorage.setItem("manageProfileByAdminPopup", "TRUE");

        this.props.history.push(`/SfiaProfile/Manage?email=${person.email}`);
    };

    handleGenerateReport = () => {
        const persons = this.state.persons;

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
            it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
            it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
        });

        this.setState({
            modeGenerateReports: true,
            persons: [...persons],
            personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
            personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
            personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id),
        });
    };

    handleConfirmGenerateReport = () => {
        const { personsSkillProfileSelected, personsEndorsedSelected, personsActionSelected } = this.state;
        let personIds = [];

        personIds.push(...personsSkillProfileSelected);

        personsEndorsedSelected.forEach((it) => {
            if (!personIds.find((x) => x == it)) {
                personIds.push(it);
            }
        });

        personsActionSelected.forEach((it) => {
            if (!personIds.find((x) => x == it)) {
                personIds.push(it);
            }
        });

        const totalReports = (personsSkillProfileSelected ? personsSkillProfileSelected.length : 0) + (personsEndorsedSelected ? personsEndorsedSelected.length : 0) + (personsActionSelected ? personsActionSelected.length : 0);

        if (totalReports > 100) {
            if (window.confirm("Due to the number of reports you have selected, the reports will be generated in the background, and you will receive an Email with a Download link when they are completed. Proceed?")) {
                this.props.onAdminPerson.generateProfileReportsToEmail({
                    reportRequests: personIds.map((personId) => {
                        return {
                            personId,
                            selfAssessedReport: personsSkillProfileSelected.find((it) => it == personId) ? true : false,
                            endorsedReport: personsEndorsedSelected.find((it) => it == personId) ? true : false,
                            actionReport: personsActionSelected.find((it) => it == personId) ? true : false,
                        };
                    }),
                });

                this.handleClearSelection();
                this.setState({ modeGenerateReports: false });
                this.generateAlert("success", "SkillsTX profile reports request sent.");
            }
        } else {
            this.setState({ loadingPrint: true });
            this.props.onAdminPerson
                .generateProfileReports({
                    reportRequests: personIds.map((personId) => {
                        return {
                            personId,
                            selfAssessedReport: personsSkillProfileSelected.find((it) => it == personId) ? true : false,
                            endorsedReport: personsEndorsedSelected.find((it) => it == personId) ? true : false,
                            actionReport: personsActionSelected.find((it) => it == personId) ? true : false,
                        };
                    }),
                })
                .then(() => {
                    if (!this.props.loadingPrint) {
                        if (this.props.printData) {
                            FileDownload(this.props.printData, `SkillsTx_Persons_Profiles.zip`);
                            this.handleClearSelection();
                        }
                    }

                    this.setState({ loadingPrint: this.props.loadingPrint, modeGenerateReports: false });
                });
        }
    };

    handleCancelGenerateReport = () => {
        this.handleClearSelection();
        this.setState({ modeGenerateReports: false });
    };

    handleToggleAssignedToMe = () => {
        const filterAssignedToMe = this.state.filterAssignedToMe;
        let persons = this.reformPersons(this.props.persons.filter((it) => !it.inactive));

        if (!filterAssignedToMe == true) {
            persons = persons.filter((it) => it.endorsedById == this.props.personId && !it.inactive);
        }

        persons.forEach((it) => {
            it.toggleSkillProfileSelected = !it.selfAssessedReportGenerated && it.percentage >= 1;
            it.toggleEndorsedSelected = !it.endorsedReportGenerated && it.hasEndorseProfile;
            it.toggleActionSelected = !it.actionReportGenerated && it.hasAction;
        });

        this.setState({
            filterAssignedToMe: !filterAssignedToMe,
            persons: [...persons.sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))],
            personsSkillProfileSelected: persons.filter((it) => it.toggleSkillProfileSelected).map((row) => row.id),
            personsEndorsedSelected: persons.filter((it) => it.toggleEndorsedSelected).map((row) => row.id),
            personsActionSelected: persons.filter((it) => it.toggleActionSelected).map((row) => row.id),
        });
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportEndorsements = () => {
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.props.onAdminPerson.exportEndorsementsDataExcel(timezoneOffset).then(() => {
            if (!this.props.loadingPrint && !this.props.personsError) {
                if (this.props.printData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.printData, `ExportEndorsements_${strToday}.xlsx`);
                }
            }
        });
    };

    onTableFilterChange = (e, searchType) => {
        let columnSearchKeywords = this.state.columnSearchKeywords;
        if (!columnSearchKeywords) {
            columnSearchKeywords = [];
        }

        if (columnSearchKeywords.find((it) => it.name == e.target.name)) {
            let columnSearchKeyword = columnSearchKeywords.find((it) => it.name == e.target.name);
            columnSearchKeyword.value = e.target.value;
        } else {
            columnSearchKeywords.push({
                name: e.target.name,
                value: e.target.value,
                searchType,
            });
        }

        this.setState({ columnSearchKeywords });
    };

    render() {
        const { personId } = this.props;
        const { persons, searchKeyword, searchProperties, modeGenerateReports, filterAssignedToMe, columnSearchKeywords } = this.state;
        const pageAssignments = this.props.pageAssignments;
        let pageAssignment = null;

        if (pageAssignments.find((it) => it.pageName == "Manage Endorsements and Reports")) {
            pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Endorsements and Reports");
        }

        const editProfile = this.editProfile;
        const approveProfile = this.approveProfile;
        const generateAlert = this.generateAlert;
        const handleSelectSkillProfileAll = this.handleSelectSkillProfileAll;
        const handleRowSkillProfileClick = this.handleRowSkillProfileClick;

        const handleSelectEndorsedAll = this.handleSelectEndorsedAll;
        const handleRowEndorsedClick = this.handleRowEndorsedClick;

        const handleSelectActionAll = this.handleSelectActionAll;
        const handleRowActionClick = this.handleRowActionClick;
        const onTableFilterChange = this.onTableFilterChange;

        const loadingPrint = this.state.loadingPrint;

        const selectableRows = false;
        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        const allowEdit = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Edit" && op.allow == true) ? true : false;
        const allowApprove = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Approve" && op.allow == true) ? true : false;
        const allowAssignEndorser = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Assign Endorser" && op.allow == true) ? true : false;
        const allowGenerateReports = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Generate Reports" && op.allow == true) ? true : false;
        const allowExport = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) ? true : false;

        const endorserSort = (rowA, rowB) => {
            let endorserA = "FALSE";
            let endorserB = "FALSE";

            if (rowA.endorsementLastUpdated && rowA.endorsedById && personId == rowA.endorsedById && rowA.endorsementLatestStatus != "Approved") {
                endorserA = "TRUE";
            }

            if (rowB.endorsementLastUpdated && rowB.endorsedById && personId == rowB.endorsedById && rowB.endorsementLatestStatus != "Approved") {
                endorserB = "TRUE";
            }

            if (endorserA > endorserB) {
                return -1;
            }

            if (endorserB > endorserA) {
                return 1;
            }

            return 0;
        };

        const lastUpdatedSort = (rowA, rowB) => {
            let lastUpdatedA = (rowA.endorsementLastUpdated ? rowA.endorsementLastUpdated.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowA.endorsementLastUpdatedVersion}` : "") + (rowA.endorsementLastUpdatedBy ? rowA.endorsementLastUpdatedBy : "");
            let lastUpdatedB = (rowB.endorsementLastUpdated ? rowB.endorsementLastUpdated.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowB.endorsementLastUpdatedVersion}` : "") + (rowB.endorsementLastUpdatedBy ? rowB.endorsementLastUpdatedBy : "");

            if (lastUpdatedA > lastUpdatedB) {
                return 1;
            }

            if (lastUpdatedB > lastUpdatedA) {
                return -1;
            }

            return 0;
        };

        const lastReviewedSort = (rowA, rowB) => {
            let lastReviewedA = (rowA.endorsementLastReviewed ? rowA.endorsementLastReviewed.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowA.endorsementLastReviewedVersion}` : "") + (rowA.endorsementLastReviewedBy ? rowA.endorsementLastReviewedBy : "");
            let lastReviewedB = (rowB.endorsementLastReviewed ? rowB.endorsementLastReviewed.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowB.endorsementLastReviewedVersion}` : "") + (rowB.endorsementLastReviewedBy ? rowB.endorsementLastReviewedBy : "");

            if (lastReviewedA > lastReviewedB) {
                return 1;
            }

            if (lastReviewedB > lastReviewedA) {
                return -1;
            }

            return 0;
        };

        const lastApprovedSort = (rowA, rowB) => {
            let lastApprovedA = (rowA.endorsementLastApproved ? rowA.endorsementLastApproved.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowA.endorsementLastApprovedVersion}` : "") + (rowA.endorsementLastApprovedBy ? rowA.endorsementLastApprovedBy : "");
            let lastApprovedB = (rowB.endorsementLastApproved ? rowB.endorsementLastApproved.toString().replaceAll("-", "").replaceAll(":", "").replace("T", "") + `V${rowB.endorsementLastApprovedVersion}` : "") + (rowB.endorsementLastApprovedBy ? rowB.endorsementLastApprovedBy : "");

            if (lastApprovedA > lastApprovedB) {
                return 1;
            }

            if (lastApprovedB > lastApprovedA) {
                return -1;
            }

            return 0;
        };

        const endorserPersons = this.state.endorserPersons;
        const assessorPersons = this.state.assessorPersons;

        const columns = [
            {
                id: "idSkillProfile",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerSkillProfiles" style={{ fontWeight: "bold" }}>
                            S
                        </span>
                        <UncontrolledTooltip target={"headerSkillProfiles"} placement="bottom">
                            Self-assessed
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllSkillProfile`}
                            icon={filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? faSquare : filtered.filter((it) => !it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleSkillProfileSelected && it.percentage >= 1).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectSkillProfileAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.percentage >= 1 && <Input type="checkbox" id={`chkRowSkillProfile${row.id}`} checked={row.toggleSkillProfileSelected} disabled={loadingPrint} onClick={(e) => handleRowSkillProfileClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "idEndorsedProfile",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerEndorsedProfiles" style={{ fontWeight: "bold" }}>
                            E
                        </span>
                        <UncontrolledTooltip target={"headerEndorsedProfiles"} placement="bottom">
                            Endorsed
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllEndorsedProfile`}
                            icon={filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? faSquare : filtered.filter((it) => !it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleEndorsedSelected && it.hasEndorseProfile).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectEndorsedAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.hasEndorseProfile && <Input type="checkbox" id={`chkRowEndorsed${row.id}`} checked={row.toggleEndorsedSelected} disabled={loadingPrint} onClick={(e) => handleRowEndorsedClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "idActionPlan",
                name: (
                    <div style={{ textAlign: "center" }}>
                        <span id="headerActionPlans" style={{ fontWeight: "bold" }}>
                            A
                        </span>
                        <UncontrolledTooltip target={"headerActionPlans"} placement="bottom">
                            Action Plan
                        </UncontrolledTooltip>
                        <br />
                        <FontAwesomeIcon
                            id={`headerChkSelectAllAction`}
                            icon={filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0 ? faSquare : filtered.filter((it) => !it.toggleActionSelected && it.hasAction).length == 0 ? faSquareCheck : faSquareMinus}
                            style={{ cursor: "pointer", color: filtered.filter((it) => it.toggleActionSelected && it.hasAction).length == 0 ? "#8f8f9d" : "#007bff" }}
                            size="lg"
                            onClick={() => !loadingPrint && handleSelectActionAll()}
                        />
                    </div>
                ),
                selector: (row) => row.id,
                sortable: false,
                width: "3%",
                center: true,
                format: function (row, index) {
                    return <div>{row.hasAction && <Input type="checkbox" id={`chkRowAction${row.id}`} checked={row.toggleActionSelected} disabled={loadingPrint} onClick={(e) => handleRowActionClick(row)} />}</div>;
                },
                omit: !modeGenerateReports,
            },
            {
                id: "id",
                name: "Action",
                selector: (row) => row.id,
                sortable: true,
                sortFunction: endorserSort,
                width: "6%",
                center: true,
                format: function (row, index) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {allowEdit && (
                                <React.Fragment>
                                    <FontAwesomeIcon id={`iconEditProfile${row.id}`} icon={faPenToSquare} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => editProfile(row)} />
                                    <UncontrolledTooltip target={`iconEditProfile${row.id}`} placement="bottom">
                                        Edit
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {allowApprove && row.endorsementLastUpdated && row.endorsedById && personId == row.endorsedById && row.endorsementLatestStatus != "Approved" && (
                                <React.Fragment>
                                    &nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon id={`iconApproveProfile${row.id}`} icon={faClipboardCheck} size="lg" style={{ cursor: "pointer", color: "#0056b3" }} onClick={() => approveProfile(row)} />
                                    <UncontrolledTooltip target={`iconApproveProfile${row.id}`} placement="bottom">
                                        Approve
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                        </div>
                    );
                },
                omit: !(allowEdit || allowApprove),
            },
            {
                id: "email",
                name: (
                    <div style={{ width: "90%" }}>
                        Person Name (email)
                        <br />
                        <input type="text" name="completeName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "completeName") ? columnSearchKeywords.find((it) => it.name == "completeName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.email,
                sortable: true,
                width: allowEdit || allowApprove ? (modeGenerateReports ? "26%" : "35%") : modeGenerateReports ? "32%" : "41%",
                center: false,
                format: function (row, index) {
                    return <span>{`${row.firstName} ${row.lastName} (${row.email})`}</span>;
                },
            },
            {
                id: "assessorName",
                name: (
                    <div style={{ width: "90%" }}>
                        Assessor Name
                        <br />
                        <input type="text" name="assessorName" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "assessorName") ? columnSearchKeywords.find((it) => it.name == "assessorName").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.assessorName,
                sortable: true,
                width: "10%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    return <PersonAssessorCell key={`LKAssessorCurrent${row.personId} `} persons={assessorPersons} person={row} assessorName={row.assessorName} assessorId={row.assessorId} allowEdit={true}></PersonAssessorCell>;
                },
            },
            {
                id: "endorsementLastUpdated",
                name: (
                    <div style={{ width: "90%" }}>
                        Loaded/Last Updated by and date
                        <br />
                        <input
                            type="text"
                            name="endorsementLastUpdatedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLastUpdatedBy") ? columnSearchKeywords.find((it) => it.name == "endorsementLastUpdatedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLastUpdated,
                sortable: true,
                sortFunction: lastUpdatedSort,
                width: "10%",
                center: false,
                format: function (row, index) {
                    if (row.endorsementLastUpdated) {
                        return (
                            <span>
                                {row.endorsementLastUpdatedBy && (
                                    <React.Fragment>
                                        {row.endorsementLastUpdatedBy}
                                        <br />
                                    </React.Fragment>
                                )}
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.endorsementLastUpdated))} ${row.endorsementLastUpdatedVersion ? `(v${row.endorsementLastUpdatedVersion})` : ""}`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
            {
                id: "endorsementLatestStatus",
                name: (
                    <div style={{ width: "90%" }}>
                        Endorsed Status
                        <br />
                        <input
                            type="text"
                            name="endorsementLatestStatus"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLatestStatus") ? columnSearchKeywords.find((it) => it.name == "endorsementLatestStatus").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLatestStatus,
                sortable: true,
                width: "9%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    return row.endorsementLatestStatus;
                },
            },
            {
                id: "endorsedBy",
                name: (
                    <div style={{ width: "90%" }}>
                        Endorser Name
                        <br />
                        <input type="text" name="endorsedBy" value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsedBy") ? columnSearchKeywords.find((it) => it.name == "endorsedBy").value : ""} onChange={(e) => onTableFilterChange(e)} style={{ width: "100%" }} />
                    </div>
                ),
                selector: (row) => row.endorsedBy,
                sortable: true,
                width: "10%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    return allowAssignEndorser ? <PersonEndorserCell key={`LKEndorserCurrent${row.personId} `} persons={endorserPersons} person={row} endorser={row.endorsedBy} endorserId={row.endorsedById} allowEdit={true}></PersonEndorserCell> : row.endorsedBy;
                },
            },
            {
                id: "endorsementLastReviewed",
                name: (
                    <div style={{ width: "90%" }}>
                        Reviewed by and date
                        <br />
                        <input
                            type="text"
                            name="endorsementLastReviewedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLastReviewedBy") ? columnSearchKeywords.find((it) => it.name == "endorsementLastReviewedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLastReviewed,
                sortable: true,
                sortFunction: lastReviewedSort,
                width: "10%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    if (row.endorsementLastReviewed) {
                        return (
                            <span>
                                {row.endorsementLastReviewedBy}
                                <br />
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.endorsementLastReviewed))} (v${row.endorsementLastReviewedVersion})`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
            {
                id: "endorsementLastApproved",
                name: (
                    <div style={{ width: "90%" }}>
                        Approved by and date
                        <br />
                        <input
                            type="text"
                            name="endorsementLastApprovedBy"
                            value={columnSearchKeywords && columnSearchKeywords.find((it) => it.name == "endorsementLastApprovedBy") ? columnSearchKeywords.find((it) => it.name == "endorsementLastApprovedBy").value : ""}
                            onChange={(e) => onTableFilterChange(e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ),
                selector: (row) => row.endorsementLastApproved,
                sortable: true,
                sortFunction: lastApprovedSort,
                width: "10%",
                center: false,
                wrap: true,
                format: function (row, index) {
                    if (row.endorsementLastApproved) {
                        return (
                            <span>
                                {row.endorsementLastApprovedBy}
                                <br />
                                {`${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(row.endorsementLastApproved))} (v${row.endorsementLastApprovedVersion})`}
                            </span>
                        );
                    } else {
                        return "";
                    }
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <div style={{ marginBottom: "10px" }}>
                            <Row>
                                <Col md="5" sm="6">
                                    <SkillsTxTableSearchBar
                                        searchKeyword={this.state.searchKeyword}
                                        onChange={(searchKeyword) => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword, personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableSearchBar>
                                </Col>
                                <Col md="7" sm="6">
                                    <SkillsTxTableClearSearch
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        color="primary"
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                        onClear={() => {
                                            this.handleClearSelection();
                                            this.setState({ searchKeyword: "", personsSkillProfileSelected: [] });
                                        }}
                                    ></SkillsTxTableClearSearch>
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleRefresh}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        <FontAwesomeIcon icon={faSync} /> Refresh
                                    </Button>
                                    {allowExport && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleExportEndorsements}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.props.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} /> Export
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        onClick={this.handleToggleAssignedToMe}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                            marginBottom: "10px",
                                        }}
                                        disabled={this.props.personsLoading || this.state.isUpdating}
                                    >
                                        {filterAssignedToMe ? "Show all" : "Assigned to me"}
                                    </Button>
                                    {allowGenerateReports && !modeGenerateReports && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                onClick={this.handleGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.props.personsLoading || this.state.isUpdating || modeGenerateReports || this.state.loadingPrint}
                                            >
                                                <FontAwesomeIcon icon={faDownload} /> Generate reports
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    {modeGenerateReports && (
                                        <React.Fragment>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnOKGenerateReport"
                                                color="primary"
                                                onClick={this.handleConfirmGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint || (this.state.personsSkillProfileSelected.length == 0 && this.state.personsEndorsedSelected.length == 0 && this.state.personsActionSelected.length == 0)}
                                            >
                                                {!this.state.loadingPrint ? "OK" : <Spinner type="grow" size="md" color="light" />}
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                id="btnCancelGenerateReport"
                                                color="primary"
                                                onClick={this.handleCancelGenerateReport}
                                                style={{
                                                    fontSize: "16px",
                                                    height: "40px",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={this.state.loadingPrint}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <SkillsTxTable
                            columns={columns}
                            data={this.state.persons}
                            pagination={true}
                            searchKeyword={searchKeyword}
                            searchProperties={searchProperties}
                            columnSearchKeywords={columnSearchKeywords}
                            selectableRows
                            selectableRowsHighlight={true}
                            handleRowClick={this.handleRowClickDummy}
                            progressPending={this.props.personsLoading}
                            headCellsStyle={{
                                style: {
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    border: "1px solid #dee2e6",
                                    whiteSpace: "normal",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    paddingRight: "8px",
                                    paddingLeft: "8px",
                                },
                            }}
                            cellsStyle={{
                                style: {
                                    padding: "5px 10px",
                                    fontSize: "11px",
                                    border: "1px solid #dee2e6",
                                },
                            }}
                        ></SkillsTxTable>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        personId: state.auth.personId,
        email: state.auth.email,
        loginWithTeams: state.auth.loginWithTeams,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,
        personsAdmin: state.adminPerson.personsAdmin,
        isUpdating: state.adminPerson.isUpdating,

        loadingPrint: state.adminPerson.loadingPrint,
        printData: state.adminPerson.printData,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageEndorsement));
